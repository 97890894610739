import { PropsWithChildren } from 'react';

import ReactModal from 'react-modal';
import styled from 'styled-components';

import { flexCenter, flexColumn } from 'styles/mixins';

interface ButtonProps {
  type?: 'close';
  name: string;
  action: () => void;
}

interface ButtonModalProps {
  buttons: ButtonProps[];
  isOpen: boolean;
}

function ButtonModal({ buttons, children, isOpen }: PropsWithChildren<ButtonModalProps>) {
  const close = buttons.find((button) => button.type === 'close');

  return (
    <ReactModal shouldCloseOnOverlayClick isOpen={isOpen} style={customStyles} onRequestClose={close.action}>
      <ModalContainer>
        <Header>{children}</Header>
        <Footer>
          {buttons.map((button, idx) => {
            const { action, name } = button;
            return (
              <Button key={`button-${idx}`} onClick={action}>
                {name}
              </Button>
            );
          })}
        </Footer>
      </ModalContainer>
    </ReactModal>
  );
}

export default ButtonModal;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(33, 34, 66, 0.6)',
    zIndex: 2,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
};

const ModalContainer = styled.div`
  width: 330px;
`;

const Header = styled.div`
  ${flexCenter}
  ${flexColumn}
  padding: 37px 0px;
`;

const Footer = styled.div`
  display: flex;
  ${flexCenter}
  padding: 13px 0px;
  border-top: 1px solid #ededed;
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  color: #036ad1;
  line-height: 22px;
`;

const Button = styled.div`
  display: flex;
  ${flexCenter}
  width: 100%;
  cursor: pointer;
`;
