import { ReactElement, useReducer } from 'react';
import reducer from './reducer';
import VisitContext, { ContextType, initialContext } from './context';
import { Category, initialState, SelectedPatient, PatientInfo, TimeTable } from './state';
import { axiosClient } from 'libs/axios';
import { arraySnakeToCamel, snakeToCamel, formatNumberToCode } from 'utils';

function VisitProvider({ children }: { children: ReactElement }): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkDoctorSchedule = async () => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const { data } = await axiosClient.get('/visit/time/check/');
      const payload = snakeToCamel(data);
      dispatch({ type: 'SET_CHECK_TIME_SUCCESS', payload });
    } catch (e) {
      dispatch({ type: 'SET_CHECK_TIME_FAIL', payload: { errorMsg: e.response.data.msg } });
    }
  };

  const deleteDoctorSchedule = async (timetableId: number) => {
    dispatch({ type: 'SET_LOADING' });
    try {
      await axiosClient.delete(`/visit/timetable/${timetableId}`);
      dispatch({ type: 'UPDATE_SCHEDULE_SUCCESS' });
    } catch (e) {
      dispatch({
        type: 'UPDATE_SCHEDULE_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const deletePatient = async () => {
    dispatch({ type: 'SET_LOADING' });
    const { patientId } = state.selectedPatient;

    try {
      await axiosClient.delete(`/user/patient/${patientId}/`);
      dispatch({ type: 'DELETE_PATIENT_SUCCESS' });
    } catch (e) {
      console.error('fail to delete patient', e);
      dispatch({ type: 'DELETE_PATIENT_FAIL' });
    }
  };

  const editPatient = async ({
    birth,
    firstName,
    gender,
    guardianPhone,
    lastName,
    patientId: ssn,
    ward,
  }: PatientInfo) => {
    dispatch({ type: 'SET_LOADING' });
    const { patientId } = state.selectedPatient;

    try {
      await axiosClient.patch(`/visit/patient/${patientId}`, {
        date_of_birth: birth,
        first_name: firstName,
        last_name: lastName,
        guardian_phone: formatNumberToCode(82, guardianPhone),
        gender: gender === '남' ? 'M' : 'F',
        ssn,
        ward,
      });

      dispatch({ type: 'REGISTER_PATIENT_SUCCESS' });
    } catch (e) {
      dispatch({
        type: 'REGISTER_PATIENT_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const getCompleteRoundList = async () => {
    try {
      dispatch({ type: 'SET_LOADING' });

      const { data } = await axiosClient.get('/visit/round/?status=3');
      const roundCount = data.count;
      const roundList = arraySnakeToCamel(data.results);

      dispatch({
        type: 'GET_COMPLETE_ROUND_LIST_SUCCESS',
        payload: { roundCount, roundList },
      });
    } catch (e) {
      console.error('get round list error: ', e);
    }
  };

  const getDoctorTimeTable = async (page: number) => {
    dispatch({ type: 'SET_LOADING' });
    const { data } = await axiosClient.get(`/visit/timetable/?page=${page}`);
    const timeTable = arraySnakeToCamel(data.results);
    dispatch({
      type: 'GET_DOCTOR_TIMETABLE_SUCCESS',
      payload: {
        timeTable,
        scheduleCount: data.count,
      },
    });
  };

  const getGuardianTurn = async () => {
    const { data } = await axiosClient.get(`/visit/round/${state.roundId}/turn/`);

    dispatch({ type: 'GET_GUARDIAN_TURN_SUCCESS', payload: { turn: data.turn } });
  };

  const getPatientDetail = async () => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const { patientId } = state.selectedPatient;
      const { data } = await axiosClient.get(`/visit/patient/${patientId}`);

      const patientDetail = snakeToCamel(data) as PatientInfo;
      dispatch({
        type: 'GET_PATIENT_DETAIL_SUCCESS',
        payload: {
          patientDetail,
        },
      });
    } catch (e) {
      dispatch({
        type: 'GET_PATIENT_DETAIL_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const getPatientList = async (page: number) => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const { data } = await axiosClient.get(`/visit/patient/?page=${page}`);
      dispatch({
        type: 'GET_PATIENT_LIST_SUCCESS',
        payload: {
          patientList: arraySnakeToCamel(data.results),
          patientCount: data.count,
        },
      });
    } catch (e) {
      dispatch({
        type: 'GET_PATIENT_LIST_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const getRoundList = async (status: Category) => {
    try {
      dispatch({ type: 'SET_LOADING' });

      const { data } = await axiosClient.get(`/visit/round/?status=${status === 'waiting' ? 1 : 2}`);
      const roundList = arraySnakeToCamel(data.results);

      dispatch({
        type: 'GET_ROUND_LIST_SUCCESS',
        payload: { roundList },
      });
    } catch (e) {
      console.error('get round list error: ', e);
    }
  };

  const getVisitHistoryList = async () => {
    dispatch({ type: 'SET_LOADING' });
    const { data } = await axiosClient.get('/visit/patient-visit/');
    const historyList = arraySnakeToCamel(data);

    dispatch({ type: 'GET_VISIT_HISTORY_LIST_SUCCESS', payload: historyList });
  };

  const getWaitingRoundList = async () => {
    try {
      dispatch({ type: 'SET_LOADING' });

      const { data } = await axiosClient.get('/visit/round/?status=1');
      const roundCount = data.count;
      const roundList = arraySnakeToCamel(data.results);
      dispatch({
        type: 'GET_WAITING_ROUND_LIST_SUCCESS',
        payload: { roundCount, roundList },
      });
    } catch (e) {
      console.error('get round list error: ', e);
    }
  };

  const registerPatient = async ({
    birth,
    firstName,
    gender,
    guardianPhone,
    lastName,
    patientId,
    ward,
  }: PatientInfo) => {
    dispatch({ type: 'SET_LOADING' });

    try {
      await axiosClient.post('/user/visit/', {
        date_of_birth: birth,
        first_name: firstName,
        last_name: lastName,
        guardian_phone: formatNumberToCode(82, guardianPhone),
        gender: gender === '남' ? 'M' : 'F',
        ssn: patientId,
        ward,
      });

      dispatch({ type: 'REGISTER_PATIENT_SUCCESS' });
    } catch (e) {
      dispatch({
        type: 'REGISTER_PATIENT_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const setCategory = (category: Category) => {
    dispatch({ type: 'SET_CATEGORY', payload: { category } });
  };

  const setSelectedPatient = (selectedPatient: SelectedPatient) => {
    const { patientId, patientName, ssn } = selectedPatient;
    dispatch({ type: 'SET_SELECTED_PATIENT', payload: { patientId, patientName, ssn } });
  };

  const setSelectedSchedule = (schedule: TimeTable) => {
    dispatch({ type: 'SET_SELECTED_SCHEDULE', payload: schedule });
  };

  const startVideoCall = async (patientId: number) => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const { data } = await axiosClient.post('/visit/round/', {
        patient_id: patientId,
      });

      dispatch({
        type: 'START_VIDEO_CALL_SUCCESS',
        payload: {
          roundId: data.round_id,
        },
      });
    } catch (e) {
      dispatch({
        type: 'START_VIDEO_CALL_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
      console.error('e: ', e.response);
    }
  };

  const updateDoctorSchedule = async (doctorSchedule: TimeTable) => {
    dispatch({ type: 'SET_LOADING' });
    try {
      await axiosClient.post('/visit/timetable/', doctorSchedule);
      dispatch({ type: 'UPDATE_SCHEDULE_SUCCESS' });
    } catch (e) {
      console.error('u[pdate schued error: ', e);
      dispatch({
        type: 'UPDATE_SCHEDULE_FAIL',
        payload: {
          errorMsg: e.response.data.msg,
        },
      });
    }
  };

  const updateHasGuardian = async (roundId: number, status: boolean) => {
    await axiosClient.patch(`/visit/round/${roundId}/`, { has_guardian: status });
  };

  const updateRoundStatus = async (roundId: number, status: number) => {
    await axiosClient.patch(`/visit/round/${roundId}/`, { status });
  };

  const initialize = () => {
    dispatch({ type: 'INITIALIZE' });
  };

  const resetInitialStatus = () => {
    dispatch({ type: 'RESET_INITAILIZE_STATUS' });
  };

  const getDoctorName = async (doctorId: string) => {
    try {
      const {
        data: { department, full_name },
      } = await axiosClient.get(`user/doctor/${doctorId}/`);

      dispatch({ type: 'GET_DOCTOR_NAME_SUCCESS', payload: { departmentName: department[0], doctorName: full_name } });
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line
  const callContext: ContextType = {
    ...initialContext,
    ...state,
    checkDoctorSchedule,
    deleteDoctorSchedule,
    deletePatient,
    editPatient,
    getCompleteRoundList,
    getDoctorTimeTable,
    getDoctorName,
    getGuardianTurn,
    getPatientDetail,
    getPatientList,
    getRoundList,
    getVisitHistoryList,
    getWaitingRoundList,
    initialize,
    resetInitialStatus,
    registerPatient,
    setCategory,
    setSelectedPatient,
    setSelectedSchedule,
    startVideoCall,
    updateDoctorSchedule,
    updateHasGuardian,
    updateRoundStatus,
  };

  return <VisitContext.Provider value={callContext}>{children}</VisitContext.Provider>;
}

export default VisitProvider;
