import React from 'react';
import { VisitTableHeader } from 'components/molecules/VisitTableHeader';
import { VisitTableRow } from 'components/molecules/VisitTableRow';

function VisitTable() {
  return (
    <>
      <VisitTableHeader />
      <VisitTableRow />
    </>
  );
}

export default VisitTable;
