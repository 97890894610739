import styled from 'styled-components';

import { ButtonCircle } from 'components/atoms/ButtonCircle';

interface AudioStyleProps {
  audioMuted: boolean;
}

export const CallButton = styled(ButtonCircle)`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px;

  @media only screen and (max-width: 420px) {
    background-size: 22px;
  }
`;

export const MuteButton = styled(CallButton)<AudioStyleProps>`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-audio-on.svg);

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const UnmuteButton = styled(CallButton)`
  background-color: var(--blue-200);
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
  &:hover {
    background-color: rgba(38, 133, 253, 0.5);
  }
`;

export const StopVideoButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-video-on.svg);
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const StartVideoButton = styled(CallButton)`
  background-color: var(--blue-200);
  background-image: url(/icons/ic-callkit-video-off-white.svg);
  &:hover {
    background-color: rgba(38, 133, 253, 0.5);
  }
`;

export const EndButton = styled(CallButton)`
  background-color: #e53157;
  background-image: url(/icons/ic-callkit-end.svg);
  &:hover {
    background-color: #a30e2d;
  }
`;

export const StartShareButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-screen-sharing-black.svg);
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const StopShareButton = styled(CallButton)`
  background-color: var(--blue-200);
  background-image: url(/icons/ic-callkit-screen-sharing-white.svg);
  &:hover {
    background-color: rgba(38, 133, 253, 0.5);
  }
`;

export const MenuButton = styled(CallButton)`
  background-size: 100%;
  background-color: var(--white);
  background-image: url(/icons/ic-dot-menu.svg);
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const RotateCameraButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-rotate-camera.png);
  background-size: 50% 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
