import styled from 'styled-components';

import { Dropdown } from 'components/organisms/Dropdown';
import { DropdownButton } from '../DropdownButton';
import { TimeDropdown } from '../TimeDropDown';

interface TimePickerProps {
  timeValue: string;
  periodValue: string;
  showPeriodDrop: boolean;
  showTimeDrop: boolean;
  onTimeClick: () => void;
  onPeriodClick: () => void;
  onTimeChange: (e: React.MouseEvent<HTMLUListElement>) => void;
  onPeriodChange: (e: React.MouseEvent<HTMLUListElement>) => void;
}

export default function TimePicker({
  timeValue,
  periodValue,
  showPeriodDrop,
  showTimeDrop,
  onTimeChange,
  onPeriodChange,
  onTimeClick,
  onPeriodClick,
}: TimePickerProps) {
  return (
    <Container>
      <DayPeriodWrap>
        <Dropdown
          open={showPeriodDrop}
          optionList={['AM', 'PM']}
          trigger={<DropdownButton placeholder="AM" value={periodValue} onClick={onPeriodClick} />}
          onClick={(e: React.MouseEvent<HTMLUListElement>) => onPeriodChange(e)}
        />
      </DayPeriodWrap>
      <TimeDropDownWrap>
        <TimeDropdown
          open={showTimeDrop}
          trigger={<DropdownButton placeholder="00:00" value={timeValue} onClick={onTimeClick} />}
          onClick={(e: React.MouseEvent<HTMLUListElement>) => onTimeChange(e)}
        />
      </TimeDropDownWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const DayPeriodWrap = styled.div`
  width: 100px;
  margin-right: 8px;
`;

const TimeDropDownWrap = styled.div`
  width: 100%;
`;
