import { produce } from 'immer';
import {
  Category,
  initialState,
  PatientInfo,
  RegisteredPatient,
  RoundList,
  SelectedPatient,
  State,
  TimeTable,
  VisitHistoryList,
  Ward,
} from './state';

export type Action =
  | { type: 'INITIALIZE' }
  | { type: 'RESET_INITAILIZE_STATUS' }
  | { type: 'DELETE_PATIENT_FAIL' }
  | { type: 'DELETE_PATIENT_SUCCESS' }
  | { type: 'GET_COMPLETE_ROUND_LIST_SUCCESS'; payload: { roundCount: number; roundList: RoundList[] } }
  | { type: 'GET_DOCTOR_TIMETABLE_SUCCESS'; payload: { timeTable: TimeTable[]; scheduleCount: number } }
  | { type: 'GET_GUARDIAN_TURN_SUCCESS'; payload: { turn: number } }
  | { type: 'GET_PATIENT_DETAIL_SUCCESS'; payload: { patientDetail: PatientInfo } }
  | { type: 'GET_PATIENT_DETAIL_FAIL'; payload: { errorMsg: string } }
  | { type: 'GET_PATIENT_LIST_FAIL'; payload: { errorMsg: string } }
  | { type: 'GET_PATIENT_LIST_SUCCESS'; payload: { patientList: RegisteredPatient[]; patientCount: number } }
  | { type: 'GET_ROUND_LIST_SUCCESS'; payload: { roundList: RoundList[] } }
  | { type: 'GET_VISIT_HISTORY_LIST_SUCCESS'; payload: VisitHistoryList[] }
  | { type: 'GET_WAITING_ROUND_LIST_SUCCESS'; payload: { roundCount: number; roundList: RoundList[] } }
  | { type: 'GET_WARD_LIST_SUCCESS'; payload: { wardList: Ward[] } }
  | { type: 'REGISTER_PATIENT_FAIL'; payload: { errorMsg: string } }
  | { type: 'REGISTER_PATIENT_SUCCESS' }
  | { type: 'SET_CATEGORY'; payload: { category: Category } }
  | { type: 'SET_CHECK_TIME_FAIL'; payload: { errorMsg: string } }
  | {
      type: 'SET_CHECK_TIME_SUCCESS';
      payload: { canEnter: boolean; departmentName: string; doctorName: string; startTime: string };
    }
  | { type: 'SET_LOADING' }
  | { type: 'SET_SELECTED_PATIENT'; payload: SelectedPatient }
  | { type: 'SET_SELECTED_SCHEDULE'; payload: TimeTable }
  | { type: 'START_VIDEO_CALL_FAIL'; payload: { errorMsg: string } }
  | { type: 'START_VIDEO_CALL_SUCCESS'; payload: { roundId: number } }
  | { type: 'UPDATE_SCHEDULE_FAIL'; payload: { errorMsg: string } }
  | { type: 'UPDATE_SCHEDULE_SUCCESS' }
  | { type: 'GET_DOCTOR_NAME_SUCCESS'; payload: { departmentName: string; doctorName: string } };

export default function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case 'INITIALIZE':
      return initialState;

    case 'RESET_INITAILIZE_STATUS':
      return produce(prevState, (draft) => {
        draft.updateScheduleSuccess = false;
        draft.deletePatientSuccess = false;
        draft.getGuardianTurnSuccess = false;
        draft.getPatientDetailSuccess = false;
        draft.errorMsg = '';
      });

    case 'DELETE_PATIENT_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.error = true;
      });

    case 'DELETE_PATIENT_SUCCESS':
      return produce(prevState, (draft) => {
        draft.selectedPatient = { patientId: 0, patientName: '', ssn: 0 };
        draft.loading = false;
        draft.deletePatientSuccess = true;
      });

    case 'GET_COMPLETE_ROUND_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.completeRoundCount = action.payload.roundCount;
        draft.completeRoundList = action.payload.roundList;
      });

    case 'GET_DOCTOR_TIMETABLE_SUCCESS':
      return produce(prevState, (draft) => {
        draft.doctorTimeTable = action.payload.timeTable;
        draft.scheduleCount = action.payload.scheduleCount;
        draft.loading = false;
      });

    case 'GET_GUARDIAN_TURN_SUCCESS':
      return produce(prevState, (draft) => {
        draft.turn = action.payload.turn;
        draft.getGuardianTurnSuccess = true;
      });

    case 'GET_PATIENT_DETAIL_SUCCESS':
      return produce(prevState, (draft) => {
        draft.patientDetail = action.payload.patientDetail;
        draft.loading = false;
        draft.getPatientDetailSuccess = true;
      });

    case 'GET_PATIENT_DETAIL_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'GET_PATIENT_LIST_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'GET_PATIENT_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.patientList = action.payload.patientList;
        draft.patientCount = action.payload.patientCount;
        draft.loading = false;
      });

    case 'GET_ROUND_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.roundList = action.payload.roundList;
        draft.loading = false;
      });

    case 'GET_VISIT_HISTORY_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.visitHistoryList = action.payload;
        draft.loading = false;
      });

    case 'GET_WAITING_ROUND_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.waitingRoundCount = action.payload.roundCount;
        draft.waitingRoundList = action.payload.roundList;
      });

    case 'GET_WARD_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.wardList = action.payload.wardList;
        draft.loading = false;
      });

    case 'REGISTER_PATIENT_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'REGISTER_PATIENT_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.registerPatientSuccess = true;
      });

    case 'SET_CATEGORY':
      return produce(prevState, (draft) => {
        draft.category = action.payload.category;
      });

    case 'SET_CHECK_TIME_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'SET_CHECK_TIME_SUCCESS':
      return produce(prevState, (draft) => {
        const { canEnter, departmentName, doctorName, startTime } = action.payload;
        draft.canEnter = canEnter;
        draft.departmentName = departmentName;
        draft.doctorName = doctorName;
        draft.startTime = startTime;
        draft.loading = false;
      });

    case 'SET_LOADING':
      return produce(prevState, (draft) => {
        draft.loading = true;
        draft.error = false;
      });

    case 'SET_SELECTED_PATIENT':
      return produce(prevState, (draft) => {
        const { ssn, patientId, patientName } = action.payload;
        const isEqual = draft.selectedPatient.ssn === ssn;
        draft.selectedPatient = isEqual ? { patientId: 0, patientName: '', ssn: 0 } : { patientId, patientName, ssn };
      });

    case 'SET_SELECTED_SCHEDULE':
      return produce(prevState, (draft) => {
        draft.selectedSchedule = action.payload;
      });

    case 'START_VIDEO_CALL_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'START_VIDEO_CALL_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.roundId = action.payload.roundId;
      });

    case 'UPDATE_SCHEDULE_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.errorMsg = action.payload.errorMsg;
      });

    case 'UPDATE_SCHEDULE_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.updateScheduleSuccess = true;
      });

    case 'GET_DOCTOR_NAME_SUCCESS':
      return produce(prevState, (draft) => {
        draft.departmentName = action.payload.departmentName;
        draft.doctorName = action.payload.doctorName;
      });

    default:
      return prevState;
  }
}
