import styled from 'styled-components';

import MediaContent from './MediaContent';
import { MobileUtilityButtons } from '../MobileUtilityButtons';

import * as mixins from 'styles/mixins';

type MobileGroupCallProps = {
  handleEndBtnClick: Function;
  // handlePatientJoin: (allow: boolean) => void;
  // joinMessage: string;
  // handleShareBtnClick: () => void;
};

function MobileGroupCall({
  handleEndBtnClick,
}: // handlePatientJoin,
// joinMessage,
// handleShareBtnClick,
MobileGroupCallProps) {
  return (
    <Wrapper>
      <MediaContent />
      <Footer>
        <FooterRadius />
        <MobileUtilityButtons onEndBtnClick={handleEndBtnClick} />
      </Footer>
    </Wrapper>
  );
}

export default MobileGroupCall;

const Wrapper = styled.div`
  ${mixins.flexColumn}
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--navy-900);

  @media (orientation: landscape) {
    background-color: #424242;
  }
`;

const Footer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 14px;
  background-color: #424242;
  z-index: 1;
`;

const FooterRadius = styled.div`
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #424242;
  border-bottom: 1px solid #424242;
`;
