const setSession = (key, value, expirationInMin) => {
  const expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  const newValue = {
    value,
    expirationDate: expirationDate.toISOString(),
  };
  window.sessionStorage.setItem(key, JSON.stringify(newValue));
};

export default setSession;
