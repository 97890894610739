import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { useTextInput } from 'components/atoms/Input';
import { InputLabel } from 'components/atoms/InputLabel';
import { ButtonModal } from 'components/organisms/ButtonModal';
import { PhoneVerification } from '../PhoneVerification';

import { useAuth } from 'libs/AuthContext';
import { media } from 'utils';

import * as fonts from 'styles/fonts';
import * as mixins from 'styles/mixins';

function FindUsernameForm() {
  const history = useHistory();
  const {
    findUsername,
    findInfo: { phoneNumber, phoneVerified, username },
  } = useAuth();

  const [showButtonModal, setShowButtonModal] = useState(false);

  const [name, nameInput] = useTextInput({
    id: 'nameInput',
    placeholder: '이름을 입력해주세요',
  });

  const findUsernameBtnEnabled = useMemo(
    () => !!name && !!phoneNumber && phoneVerified,
    [name, phoneNumber, phoneVerified],
  );

  const closeButtonModal = () => {
    setShowButtonModal(false);

    if (username) return history.push('/login');
  };

  const handleGoBack = () => {
    history.push('/login');
  };

  const handleFindUsernameClick = () => {
    setShowButtonModal(true);
    findUsername(name, phoneNumber);
  };

  return (
    <>
      <ButtonModal
        buttons={[{ type: 'close', name: '확인', action: () => closeButtonModal() }]}
        isOpen={showButtonModal}
      >
        <ModalText>
          {username
            ? `고객님께서 등록하신 아이디입니다 
            아이디 : ${username}`
            : '등록된 사용자 정보가 없습니다.'}
        </ModalText>
      </ButtonModal>
      <FormContainer>
        <div>
          <Header>
            <BackArrowIcon onClick={() => handleGoBack()} />
            <MainText>아이디 찾기</MainText>
          </Header>
          <InputWrap error={false}>
            <InputLabel htmlFor="nameInput">이름</InputLabel>
            {nameInput}
          </InputWrap>
          <PhoneVerification />
        </div>
        <FindUsernameBtn
          primary
          disabled={!findUsernameBtnEnabled}
          size="mid"
          onClick={() => handleFindUsernameClick()}
        >
          아이디 찾기
        </FindUsernameBtn>
      </FormContainer>
    </>
  );
}

export default FindUsernameForm;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: var(--white);

  ${media.main} {
    max-width: 675px;
    max-height: 1112px;
  }

  input {
    flex: 1;
    &:focus {
      font-size: 14px;
    }
  }
`;

const Header = styled.header`
  ${mixins.flexCenter}
  position: relative;
  margin-bottom: 52px;
`;

const BackArrowIcon = styled.div`
  position: absolute;
  left: 0px;
  width: 32px;
  height: 32px;
  background-image: url('/icons/ic-back-arrow.svg');
  cursor: pointer;
`;

const MainText = styled.h1`
  font-family: NotoSansMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--gray-600);
  margin: 0;
`;

const InputWrap = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 8px;
    `}

  input {
    flex: 1;
  }
`;

const Disabled = css`
  color: var(--gray-400);
  background-color: var(--gray-80);
  border: 1px solid var(--gray-150);
`;

const FindUsernameBtn = styled(Button)<{ disabled: boolean }>`
  ${fonts.normal};
  ${fonts.demi};
  color: var(--white);
  font-size: 20px;
  width: 100%;
  height: 60px;
  ${({ disabled }) => (disabled ? Disabled : '')}
`;

const ModalText = styled.h1`
  color: #777777;
  font-family: NotoSansRegular;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  white-space: pre-wrap;
`;
