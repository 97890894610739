import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import { Button } from 'components/atoms/Button';
import { ButtonModal } from 'components/organisms/ButtonModal';
import { useTextInput } from 'components/atoms/Input';
import { InputLabel } from 'components/atoms/InputLabel';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { DropdownButton } from 'components/molecules/DropdownButton';
import { Dropdown } from 'components/organisms/Dropdown';
import { ErrorModal } from 'components/organisms/ErrorModal';

import { useVisit } from 'libs/VisitContext';
import { formatDateOfBirth, validatePhone } from 'utils';
import { flexRow } from 'styles/mixins';

export default function PatientInfoForm() {
  const {
    deletePatient,
    deletePatientSuccess,
    editPatient,
    error,
    errorMsg,
    initialize,
    patientDetail,
    registerPatient,
    registerPatientSuccess,
    selectedPatient,
  } = useVisit();
  const history = useHistory();

  const [birth, setBirth] = useState(patientDetail.birth);
  const [birthError, setBirthError] = useState(false);
  const [gender, setGender] = useState(patientDetail.gender);
  const [guardianPhone, setGuardianPhone] = useState(patientDetail.guardianPhone);
  const [guardianPhoneError, setGuardianPhoneError] = useState(false);
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);
  const [showWardDropdown, setShowWardDropdown] = useState(false);
  const [ward, setWard] = useState(patientDetail.ward);
  const [showDeleteModal, setShowDeleteModel] = useState(false);

  const [firstName, firstNameInput] = useTextInput({
    id: 'firstnameInput',
    placeholder: '이름을 입력해주세요.',
    initValue: patientDetail.firstName,
  });

  const [lastName, lastNameInput] = useTextInput({
    id: 'lastnameInput',
    placeholder: '성을 입력해주세요.',
    initValue: patientDetail.lastName,
  });

  const [patientId, patientIdInput] = useTextInput({
    id: 'patientIdInput',
    placeholder: '환자번호를 입력해주세요.',
    initValue: patientDetail.patientId ? patientDetail.patientId?.toString() : '',
  });

  const closeButtonModal = () => {
    setShowDeleteModel(false);
  };

  const handleBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatDateOfBirth(e.target.value);
    setBirth(formatted);
  };

  const handleDeleteBtnClick = () => {
    setShowDeleteModel(true);
  };

  const handleDeletePatient = () => {
    deletePatient();
    setShowDeleteModel(false);
  };

  const handleGenderDropdown = () => {
    setShowGenderDropdown(!showGenderDropdown);
  };

  const handleWardDropdown = () => {
    setShowWardDropdown(!showWardDropdown);
  };

  const handleGenderSelect = (e: React.MouseEvent<HTMLUListElement>) => {
    const element = e.target as HTMLLIElement;
    setGender(element.innerText);
    setShowGenderDropdown(false);
  };

  const handleGuardianPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuardianPhone(e.target.value);
  };

  const handleRegisterBtnClick = () => {
    if (selectedPatient.patientId) {
      editPatient({ birth, firstName, gender, guardianPhone, lastName, patientId, ward });
      return;
    }
    registerPatient({ birth, firstName, gender, guardianPhone, lastName, patientId, ward });
  };

  const handleWardSelect = (e: React.MouseEvent<HTMLUListElement>) => {
    const element = e.target as HTMLLIElement;
    setWard(element.innerText);
    setShowWardDropdown(false);
  };

  const registerEneabled = useMemo(
    () =>
      !!birth &&
      !!firstName &&
      !!lastName &&
      !!guardianPhone &&
      !!gender &&
      !!ward &&
      !!patientId &&
      !birthError &&
      !guardianPhoneError,
    [birth, firstName, gender, guardianPhone, lastName, patientId, ward, birthError, guardianPhoneError],
  );

  const resetInput = () => {
    initialize();
    window.location.reload();
  };

  useEffect(() => {
    setBirthError(!!birth && dayjs(birth, 'YYYY-MM-DD').format('YYYY-MM-DD') !== birth);
  }, [birth]);

  useEffect(() => {
    const result = validatePhone(guardianPhone);
    setGuardianPhoneError(!!guardianPhone && !result);
  }, [guardianPhone]);

  useEffect(() => {
    if (!registerPatientSuccess) return;
    alert('성공했습니다');
    window.location.reload();
    // initialize();
  }, [registerPatientSuccess]);

  useEffect(() => {
    if (!deletePatientSuccess) return;
    initialize();
    history.push('/patient-list');
  }, [deletePatientSuccess]);

  useEffect(() => {
    if (!error) return;
    alert('환자 삭제 실패. 다시 한 번 시도해주세요');
  }, [error]);

  return (
    <Container>
      <ErrorModal close={resetInput} errorMsg={errorMsg} isOpen={!!errorMsg} />
      <InputWrap>
        <InputLabel htmlFor="patientName">환자이름</InputLabel>
        <NameWrap>
          <>
            {lastNameInput}
            {firstNameInput}
          </>
        </NameWrap>
      </InputWrap>
      <InputWrap>
        <InputLabel htmlFor="patientId">환자번호</InputLabel>
        {patientIdInput}
      </InputWrap>
      {/* <InputLabel htmlFor="email">이메일 주소</InputLabel>
      {emailInput} */}
      <InputWrap>
        <InputLabel error={birthError} htmlFor="birth">
          생년월일
        </InputLabel>
        <InputWithError
          error={birthError}
          name="birth"
          placeholder="생년월일을 입력해주세요."
          value={birth}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBirthChange(e)}
        />
        {birthError && <ErrorMessage>생년월일 형식을 확인해주세요.</ErrorMessage>}
      </InputWrap>
      <InputWrap>
        <InputLabel htmlFor="gender">성별</InputLabel>
        <Dropdown
          open={showGenderDropdown}
          optionList={['남', '여']}
          trigger={<DropdownButton placeholder="성별을 선택해주세요." value={gender} onClick={handleGenderDropdown} />}
          onClick={(e: React.MouseEvent<HTMLUListElement>) => handleGenderSelect(e)}
        />
      </InputWrap>
      <InputWrap>
        <InputLabel htmlFor="ward">입원병동</InputLabel>
        <Dropdown
          open={showWardDropdown}
          optionList={['CCU', 'NCU', 'MICU', 'SICU']}
          trigger={<DropdownButton placeholder="입원병동을 선택해주세요." value={ward} onClick={handleWardDropdown} />}
          onClick={(e: React.MouseEvent<HTMLUListElement>) => handleWardSelect(e)}
        />
      </InputWrap>
      <InputWrap>
        <InputLabel htmlFor="guardianPhone">보호자 전화번호</InputLabel>
        <InputWithError
          error={guardianPhoneError}
          name="guardianPhone"
          placeholder="- 제외하고 입력해주세요."
          value={guardianPhone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGuardianPhoneChange(e)}
        />
        {guardianPhoneError && <ErrorMessage>전화번호 형식을 확인해주세요.</ErrorMessage>}
      </InputWrap>
      <BtnWrap>
        {!!selectedPatient.patientId && (
          <DeleteBtn disabled={!selectedPatient.patientId} onClick={() => handleDeleteBtnClick()}>
            환자 삭제
          </DeleteBtn>
        )}
        <RegisterBtn disabled={!registerEneabled} onClick={() => handleRegisterBtnClick()}>
          {selectedPatient.patientId ? '정보 수정' : '환자 등록'}
        </RegisterBtn>
        <ButtonModal
          buttons={[
            { type: 'close', name: '취소', action: () => closeButtonModal() },
            { name: '삭제', action: () => handleDeletePatient() },
          ]}
          isOpen={showDeleteModal}
        >
          <ModalSubText>환자정보를 삭제하시겠습니까?</ModalSubText>
          <ModalMainText>{`${selectedPatient.patientName} (${selectedPatient.ssn})`}</ModalMainText>
        </ButtonModal>
      </BtnWrap>
    </Container>
  );
}

const Container = styled.div`
  background-color: white;
  padding: 24px 25px 40px;
  overflow-y: auto;
  border-radius: 4px;

  > button {
    margin-bottom: 24px;
  }
`;

const InputWrap = styled.div`
  margin: 8px 0 24px;

  ul {
    width: 100%;
  }
`;

const NameWrap = styled.div`
  ${flexRow}

  input:first-of-type {
    margin-right: 16px;
  }
`;

const Disabled = css`
  color: var(--white);
  background-color: var(--gray-400);
`;

const Enabled = css`
  color: var(--white);
  background-color: #036ad1;
`;

const RegisterBtn = styled(Button)<{ disabled: boolean }>`
  width: 100%;
  margin-top: 48px;
  padding: 15px 0 14px;
  font-size: 18px;
  font-weight: 500;
  font-family: NotoSansMedium;
  ${({ disabled }) => (disabled ? Disabled : Enabled)}
`;

const InputWithError = styled.input<{ error: boolean }>`
  width: 100%;
  padding: 13px 8px;
  background-color: #f4f4f4;
  border: none;
  outline: none;
  color: var(--gray-600);
  font-size: 15px;

  &:focus {
    font-size: 16px;
  }

  ::placeholder {
    color: var(--gray-600);
    opacity: 0.5;
    padding-left: -80px;
  }

  ${({ error }) =>
    error &&
    css`
      border-bottom-color: #e13237;
    `}
`;

const ModalMainText = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: 700;
  color: #4d86bf;
`;

const ModalSubText = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: 400;
  color: var(--gray-600);
`;

const DeleteBtn = styled(RegisterBtn)`
  margin-right: 8px;
  ${Disabled}

  &:hover {
    ${Enabled}
  }
`;

const BtnWrap = styled.div`
  display: flex;
`;
