import styled from 'styled-components';

const ButtonCircle = styled.div`
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 50%;
  background-color: var(--white);

  @media only screen and (max-width: 599px) {
    width: 28px;
    height: 28px;
  }
`;

export default ButtonCircle;
