import styled from 'styled-components';
import { flexCenter } from 'styles/mixins';

function VisitTableHeader() {
  return (
    <TableHeader>
      <div>대기시간</div>
      <div>
        <div>
          환자이름(나이/성별)
          <br />
          입원병동
        </div>
      </div>
      <div />
    </TableHeader>
  );
}

export default VisitTableHeader;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 47px;
  padding: 14.5px 0;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #eaeaea;

  div {
    ${flexCenter};
    height: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #909090;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:nth-child(2) {
    flex: 1.23;
    text-align: center;
  }

  div:nth-child(3) {
    flex: 1.39;
  }
`;
