export const formatDateOfBirth = (value: string) => {
  let replaced = value.replace(/\s/g, '');

  const date = replaced.replace(/-/g, '');
  if (date.length > 5 && date.length < 7) {
    replaced = `${date.slice(0, 4)}-${date.slice(4)}`;
  } else if (date.length >= 7) {
    replaced = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}`;
  }

  return replaced;
};

export const formatNumberToCode = (countryCode: number, phoneNumber: string) => {
  const phone = countryCode === 82 && `${phoneNumber[0]}` === '0' ? `${phoneNumber}`.slice(1) : phoneNumber;

  return `${countryCode}-${phone.replace(/-/g, '')}`;
};

export const formatCodeToNumber = (phone: string) => {
  if (!phone.startsWith('82-')) {
    return phone;
  }
  const replaced = `${phone.replace('82-', '0')}`;
  const first = replaced.slice(0, 3);
  const second = replaced.slice(3, 7);
  const third = replaced.slice(7, 12);
  return `${first}-${second}-${third}`;
};

export const formatPages = (count: number, limit: number) => {
  const pages = [];
  const math = Math.ceil(count / limit);
  for (let i = 1; i <= math; i++) {
    pages.push(i);
  }
  return pages;
};

export const formatTime12to24 = (time12h: string) => {
  const [time, dayPeriod] = time12h.split(' ');
  const splitTime = time.split(':');

  let hours = splitTime[0];
  const minutes = splitTime[1];

  if (hours === '12') {
    hours = '00';
  }

  if (dayPeriod === 'PM') {
    hours = `${parseInt(hours) + 12}`;
  }

  return `${hours}:${minutes}`;
};

export const formatTimeAMPM = (time: string) => {
  const splitTime = time.split(':');
  let hours = splitTime[0];
  const minutes = splitTime[1];

  const dayPeriod = parseInt(hours) >= 12 ? 'PM' : 'AM';

  hours = dayPeriod === 'PM' ? `0${parseInt(hours) - 12}` : hours;

  return `${hours}:${minutes} ${dayPeriod}`;
};

export const formatTimeDay = (day: number) => {
  const week = ['월', '화', '수', '목', '금'];

  const nowDay = week[day];

  return nowDay;
};
