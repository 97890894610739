import { Switch, useRouteMatch } from 'react-router-dom';

import { DoctorRoute } from 'components/atoms/DoctorRoute';
import { SchedulePage } from 'components/pages/SchedulePage';

function ScheduleScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <DoctorRoute exact component={SchedulePage} path={path} />
    </Switch>
  );
}

export default ScheduleScreen;
