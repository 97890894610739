/** Types */

export type Category = 'waiting' | 'complete';

export type TimeTable = {
  id: number;
  day: number;
  doctor: number;
  endTime: string;
  startTime: string;
};

export type Ward = {
  id: number;
  floor: number;
  name: number;
};

export type PatientInfo = {
  birth: string;
  firstName: string;
  gender: string;
  guardianPhone: string;
  lastName: string;
  patientId: number;
  ward: string;
};

export type RegisteredPatient = {
  id: number;
  guardianName: string;
  guardianPhone: string;
  patientAge: number;
  patientGender: string;
  patientName: string;
  ssn: number;
  ward: string;
};

export type State = {
  canEnter: boolean;
  category: Category;
  completeRoundCount: number;
  completeRoundList: RoundList[];
  deletePatientSuccess: boolean;
  departmentName: string;
  doctorName: string;
  doctorTimeTable: TimeTable[];
  error: boolean;
  errorMsg: string;
  getPatientDetailSuccess: boolean;
  getGuardianTurnSuccess: boolean;
  loading: boolean;
  patientCount: number;
  patientDetail: PatientInfo;
  patientList: RegisteredPatient[];
  registerPatientSuccess: boolean;
  roundId: number;
  roundList: RoundList[];
  selectedPatient: SelectedPatient;
  selectedSchedule: TimeTable;
  startTime: string;
  scheduleCount: number;
  turn: number;
  updateScheduleSuccess: boolean;
  visitHistoryList: VisitHistoryList[];
  waitingRoundCount: number;
  waitingRoundList: RoundList[];
  wardList: Ward[];
};

export type SelectedPatient = {
  patientId: number;
  patientName: string;
  ssn: number;
};

export type RoundList = {
  id: number;
  hasGuardian: boolean;
  patientAge: string;
  patientGender: string;
  patientName: string;
  patientWard: string;
  status: number;
  updatedAt: string;
};

export type VisitHistoryList = {
  doctorName: string;
  doctorPhoto: string;
  departmentName: string;
  guardianName: string;
  guardianAge: string;
  guardianPhone: string;
  hospitalName: string;
  patientName: string;
  patientAge: string;
  patientPhone: string;
  requestReason: string;
  record: string;
  status: string;
  visitDate: string;
};

/** State */
export const initialState: State = {
  canEnter: false,
  category: 'waiting',
  completeRoundCount: 0,
  completeRoundList: [],
  deletePatientSuccess: false,
  departmentName: '',
  doctorName: '',
  doctorTimeTable: [],
  error: false,
  errorMsg: '',
  getPatientDetailSuccess: false,
  getGuardianTurnSuccess: false,
  loading: false,
  patientCount: 0,
  patientDetail: {
    birth: '',
    firstName: '',
    gender: '',
    guardianPhone: '',
    lastName: '',
    patientId: 0,
    ward: '',
  },
  patientList: [],
  registerPatientSuccess: false,
  roundId: 0,
  roundList: [],
  selectedPatient: { patientId: 0, patientName: '', ssn: 0 },
  selectedSchedule: {
    id: 0,
    day: 0,
    doctor: 0,
    endTime: '',
    startTime: '',
  },
  startTime: '',
  scheduleCount: 0,
  turn: 0,
  updateScheduleSuccess: false,
  visitHistoryList: [
    {
      doctorName: '',
      doctorPhoto: '',
      departmentName: '',
      guardianName: '',
      guardianAge: '',
      guardianPhone: '',
      hospitalName: '',
      patientName: '',
      patientAge: '',
      patientPhone: '',
      requestReason: '',
      record: '',
      status: '',
      visitDate: '',
    },
  ],
  waitingRoundCount: 0,
  waitingRoundList: [],
  wardList: [],
};
