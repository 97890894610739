import { Switch, useRouteMatch } from 'react-router-dom';
import { GuardianRoute } from 'components/atoms/GuardianRoute';
import { FamilyManagement } from 'components/pages/FamilyManagement';

function FamilyManagementScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <GuardianRoute exact component={FamilyManagement} path={path} />
    </Switch>
  );
}

export default FamilyManagementScreen;
