import styled from 'styled-components';

// import { Logo } from 'components/atoms/Logo';
import { Spinner } from 'components/atoms/Spinner';

import { flexCenter, flexColumn } from 'styles/mixins';

export default function Intermisson() {
  return (
    <Container>
      <Logo alt="hospital-logo" src="/icons/ic-catholic.jpeg" />
      <Spinner />
    </Container>
  );
}

const Logo = styled.img`
  width: 150px;
  height: 120px;
`;

const Container = styled.div`
  ${flexCenter}
  ${flexColumn}
  margin-top: 80px;
`;
