import { useHistory, useRouteMatch, useLocation, Route, RouteProps } from 'react-router-dom';
import { Intermisson } from 'components/organisms/Intermission';
import { useAuth } from 'libs/AuthContext';

function GuardianRoute({ component: Component, render = () => null, ...props }: RouteProps) {
  const { isGuardian, loading } = useAuth();
  const { url } = useRouteMatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  return (
    <Route
      {...props}
      render={(renderProps) => {
        if (loading) return <Intermisson />;
        if (!isGuardian) {
          query.set('referrer', url);
          history.push(`/login?${query.toString()}`);
          return null;
        }
        if (Component) return <Component {...renderProps} />;
        return render(renderProps);
      }}
    />
  );
}

export default GuardianRoute;
