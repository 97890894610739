import { ReactElement, useReducer } from 'react';
import reducer from './reducer';
import CallContext, { ContextType, initialContext } from './context';
import { initialState } from './state';
import { axiosClient } from 'libs/axios';
import { getParameter } from 'utils';
import { useAuth } from 'libs/AuthContext';

function CallProvider({ children }: { children: ReactElement }): JSX.Element {
  const { patientLogin } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = () => {
    dispatch({ type: 'INITIALIZE' });
  };

  const addChannelId = (channelId: string) => {
    dispatch({
      type: 'ADD_CHANNEL_ID',
      payload: channelId,
    });
  };

  const checkPatientName = async (patient: string, id: string) => {
    try {
      await axiosClient.post('/appointment/guardian/', {
        appointment_id: id,
        patient,
      });
      patientLogin(patient);
    } catch (e) {
      updateRegisteredPatient(false);
      console.error(e);
    }
  };

  const deleteJoinedUser = (uid: string) => {
    dispatch({
      type: 'DELETE_JOINED_USER',
      payload: uid,
    });
  };

  const getFlippedBackgroundImage = async (formData: FormData) => {
    const res = await axiosClient.post('/user/background-image/', formData);
    return res.data.file_path;
  };

  const getRecordingInfo = async (channelId: string) => {
    try {
      const res = await axiosClient.post('/appointment/agora/record/acquire/', {
        cname: channelId,
      });

      dispatch({
        type: 'GET_RECORDING_INFO',
        payload: res.data.resource_id,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const setJoinMessage = (message: string) => {
    dispatch({
      type: 'SET_JOIN_MESSAGE',
      payload: message,
    });
  };

  const startRecording = async (channelId: string, resourceId: string) => {
    try {
      const res = await axiosClient.post('/appointment/agora/record/start/', {
        cname: channelId,
        resource_id: resourceId,
      });

      dispatch({
        type: 'START_RECORDING',
        payload: res.data.sid,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const stopRecording = async () => {
    const { resourceId, sid } = state;
    const roomId = getParameter('roomId');

    try {
      const res = await axiosClient.post('/appointment/agora/record/stop/', {
        cname: roomId,
        resource_id: resourceId,
        sid,
      });

      if (res.data.msg === 'RECORDING_STOP_SUCCESS') {
        dispatch({
          type: 'STOP_RECORDING',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateJoinedUser = (uid: string) => {
    dispatch({
      type: 'UPDATE_JOINED_USER',
      payload: uid,
    });
  };

  const updateSharing = (isSharing: boolean) => {
    dispatch({
      type: 'UPDATE_SCREEN_SHARING',
      payload: isSharing,
    });
  };

  const updateRegisteredPatient = (payload: boolean) => {
    dispatch({
      type: 'UPDATE_REGISTERED_PATIENT',
      payload,
    });
  };

  // eslint-disable-next-line
  const callContext: ContextType = {
    ...initialContext,
    ...state,
    addChannelId,
    checkPatientName,
    deleteJoinedUser,
    getFlippedBackgroundImage,
    getRecordingInfo,
    initialize,
    setJoinMessage,
    startRecording,
    stopRecording,
    updateJoinedUser,
    updateSharing,
    updateRegisteredPatient,
  };

  return <CallContext.Provider value={callContext}>{children}</CallContext.Provider>;
}

export default CallProvider;
