import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import * as mixins from 'styles/mixins';

/** Types */
const primary = css`
  color: var(--white);
  background-color: var(--voidoc-blue);
`;

/** Sizes */
const mid = css`
  width: 80px;
  height: 40px;
`;

const big = css`
  width: 180px;
  height: 50px;
`;

const sizes = { mid, big };

/** Prop Types */
interface PropTypes extends HTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  size?: 'mid' | 'big';
}

const ButtonStyle = styled.button<PropTypes>`
  ${mixins.flexCenter}
  cursor: pointer;
  min-height: 40px;
  border: none;
  padding: 0;
  outline: none;
  border-radius: 4px;
  text-decoration: none;
  ${(props) =>
    props.primary
      ? primary
      : css`
          background-color: var(--white);
        `};
  ${(props) => (props.size ? sizes[props.size] : '')};
`;

function Button({ children, ...props }: PropTypes) {
  return (
    <ButtonStyle type="button" {...props}>
      {children}
    </ButtonStyle>
  );
}

export default Button;
