import { Button } from 'components/atoms/Button';
import styled, { css } from 'styled-components';

interface DropdownButtonProps {
  placeholder: string;
  value: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function DropdownButton({ placeholder, value, onClick }: DropdownButtonProps) {
  return (
    <StyledButton onClick={onClick}>
      <Text value={value}>{value || placeholder}</Text>
      <TriangleIcon value={value} />
    </StyledButton>
  );
}

export default DropdownButton;

const Opacity = css`
  opacity: 0.5;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 15px 13px 8px;
  text-align: left;
  border-radius: 0;
  background-color: #f4f4f4;
  font-size: 15px;
  color: var(--gray-600);
  cursor: pointer;
`;

const Text = styled.span<{ value: string }>`
  ${({ value }) => (value ? '' : Opacity)};
`;

const TriangleIcon = styled.div<{ value: string }>`
  width: 0px;
  height: 0px;
  border-top: 7px solid #777777;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;

  ${({ value }) => (value ? '' : Opacity)};
`;
