import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { MediaPlayer } from 'components/atoms/MediaPlayer';
import { ScreenOverlay } from 'components/molecules/ScreenOverlay';

import { useVisit } from 'libs/VisitContext';
import { useAuth } from 'libs/AuthContext';
import { useAgora } from 'libs/AgoraContext';

import { font } from 'styles/fonts';
import { flex } from 'styles/flex';
import * as mixins from 'styles/mixins';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

interface MobileFrontCameraViewPropsType {
  count: number;
  rtcUsers: IAgoraRTCRemoteUser[];
}

function MobileFrontCameraView({ count, rtcUsers }: MobileFrontCameraViewPropsType) {
  const { audioMuted, localAudioTrack, localVideoTrack, videoMuted } = useAgora();
  const { foreignerAppointment, isDoctor, isForeigner, isGuardian, user: localUser } = useAuth();
  const { departmentName: visitDeptName, doctorName: visitDoctorName, getDoctorName } = useVisit();

  const [localUserName, setLocalUserName] = useState('');
  const [remoteUserName, setRemoteUserName] = useState('');
  const { departmentName: foreignerDeptName, doctorName: foreignerDoctorName } = foreignerAppointment;

  const getRemoteUserName = () => {
    switch (true) {
      case isDoctor:
        return '보호자';
      case isForeigner:
        return `${foreignerDeptName} ${foreignerDoctorName}`;
      case isGuardian:
        return `${visitDeptName} ${visitDoctorName}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isDoctor || !rtcUsers.length) return;
    const doctorId = rtcUsers[0].uid;
    getDoctorName(`${doctorId}`);
  }, [isDoctor, rtcUsers]);

  useEffect(() => {
    setRemoteUserName(getRemoteUserName());
  }, [visitDeptName]);

  useEffect(() => {
    setLocalUserName(isDoctor || isForeigner ? localUser.fullname : '보호자');
  }, []);

  return (
    <Wrapper>
      <ParticipantView isLocal count={count}>
        <ScreenOverlay hasBorder={false} isActive={videoMuted} />
        <MediaPlayer audioTrack={localAudioTrack} id="myVideo" videoTrack={localVideoTrack} />
        {(localUserName || audioMuted) && (
          <ParticipantInfo>
            {localUserName}
            {audioMuted && <ParticipantMutedIcon />}
          </ParticipantInfo>
        )}
      </ParticipantView>
      {!rtcUsers.length && (
        <ParticipantView count={2} isLocal={false}>
          <ScreenOverlay isActive hasBorder={false} />
        </ParticipantView>
      )}
      {rtcUsers?.map((user) => {
        const { uid: rtcId, audioTrack, videoTrack, hasAudio, hasVideo } = user;
        return (
          <ParticipantView key={rtcId} count={count} isLocal={false}>
            <ScreenOverlay hasBorder={false} isActive={!hasVideo} />
            <MediaPlayer audioTrack={audioTrack} id={`${rtcId}`} videoTrack={videoTrack} />
            {(remoteUserName.trim() || !hasAudio) && (
              <ParticipantInfo>
                {remoteUserName}
                {!hasAudio && <ParticipantMutedIcon />}
              </ParticipantInfo>
            )}
          </ParticipantView>
        );
      })}
    </Wrapper>
  );
}

export default MobileFrontCameraView;

interface ParticipantViewTypes {
  count: number;
  isLocal?: boolean;
}

const Wrapper = styled.div`
  ${flex('flex-start', 'center', 'column')};
  width: 100%;
  flex: 8;
  overflow-x: scroll;

  @media (orientation: landscape) {
    flex-direction: row;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ParticipantView = styled.div<ParticipantViewTypes>`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50%;

  @media (orientation: landscape) {
    height: 100%;
  }

  video {
    ${mixins.fitVideo}

    ${({ isLocal }) => `
      transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -webkit-transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -moz-transform:${isLocal ? 'rotateY(180deg)' : ''};
  `}
  }
`;

const ParticipantInfo = styled.div`
  ${flex('center', 'center')};
  position: absolute;
  left: 16px;
  bottom: 32px;
  height: 35px;
  gap: 5px;
  padding: 4px 15px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  ${font('NotoSansRegular', 16, 400, 22)};
  color: var(--white);
  z-index: 5;

  @media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: 13px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 13px;
  }
`;

const ParticipantMutedIcon = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
`;
