import { useState } from 'react';
import styled from 'styled-components';

import MediaContent from './MediaContent';
import { RoomInfo } from 'components/organisms/RoomCreated/RoomCreated';
import { UtilityButtons } from '../UtilityButtons';

import { getParameter } from 'utils';
import * as mixins from 'styles/mixins';

type GroupCallProps = {
  handleEndBtnClick: Function;
  handleSelectMenu: () => void;
  handleShareBtnClick: () => void;
  showSelectMenu: boolean;
};

function GroupCall({ handleEndBtnClick, handleSelectMenu, handleShareBtnClick, showSelectMenu }: GroupCallProps) {
  const [showRoomInfo, setShowRoomInfo] = useState(false);

  return (
    <Wrapper>
      <Main>
        {/* <Header>
          <ChevronRight />
          {false && (
            <AllowModal>
              <p>
                {joinMessage}
                {' 님이 입장을 요청했습니다.'}
              </p>
              <AllowDenyBtnWrapper>
                <DenyBtn onClick={() => handlePatientJoin(false)}>거절</DenyBtn>
                <AllowBtn onClick={() => handlePatientJoin(true)}>요청승인</AllowBtn>
              </AllowDenyBtnWrapper>
            </AllowModal>
          )}
        </Header> */}
        <MediaContent />
        <Footer>
          <UtilityButtonsWrapper>
            <UtilityButtons
              showSelectMenu={showSelectMenu}
              onEndBtnClick={handleEndBtnClick}
              onMenuBtnClick={handleSelectMenu}
              onShareBtnClick={handleShareBtnClick}
            />
          </UtilityButtonsWrapper>
        </Footer>

        <RoomInfo close={() => setShowRoomInfo(false)} isOpen={showRoomInfo} roomId={getParameter('roomId')} />
      </Main>
    </Wrapper>
  );
}

export default GroupCall;

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 3em);
`;

const Main = styled.div`
  ${mixins.flexColumn}
  align-items: center;
  width: 100%;
  height: 100%;
`;

// const Header = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
//   height: 218px;
//   padding: 40px 40px 0;

//   @media only screen and (max-width: 999px) {
//     height: 170px;
//   }

//   @media only screen and (max-width: 599px) {
//     display: none;
//     height: 130px;
//   }
// `;

// const AllowModal = styled.div`
//   ${mixins.flexColumn}
//   justify-content: space-between;
//   width: 598px;
//   height: 178px;
//   padding: 40px 40px 32px;
//   border-radius: 5px;
//   background-color: white;
//   font-size: 18px;

//   p {
//     margin: 0;
//     color: var(--gray-600);
//   }

//   @media only screen and (max-width: 999px) {
//     width: 360px;
//     height: 100%;
//     padding: 20px 20px 14px;
//     font-size: 14px;
//   }

//   @media only screen and (max-width: 599px) {
//     width: 200px;
//     height: 100%;
//     padding: 12px 12px 10px;
//     font-size: 12px;
//   }
// `;

// const AllowDenyBtnWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   height: 40px;
//   font-size: 16px;

//   @media only screen and (max-width: 999px) {
//     height: 30px;
//     font-size: 12px;
//   }

//   @media only screen and (max-width: 599px) {
//     height: 24px;
//     font-size: 10px;
//   }
// `;

// const DenyBtn = styled.div`
//   ${mixins.flexCenter}
//   width: 142px;
//   height: 100%;
//   margin-right: 8px;
//   color: var(--gray-400);
//   background: var(--gray-100);
//   border: 1px solid var(--gray-200);
//   border-radius: 3px;
//   cursor: pointer;
// `;

// const AllowBtn = styled(DenyBtn)`
//   width: 202px;
//   color: var(--green-350);
//   background: var(--green-80);
//   border: 1px solid var(--green-100);
// `;

// const ChevronRight = styled.div`
//   width: 20px;
//   height: 20px;
//   background-image: url(/icons/ic-chevron-right.svg);
//   background-repeat: no-repeat;
//   background-position: center;
//   margin-left: 6px;
// `;

const Footer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`;

const UtilityButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  bottom: 6vh;
`;

// @media only screen and (max-width: 999px) {
//   height: 170px;
// }

// @media only screen and (max-width: 599px) {
//   width: 219px;
//   height: 57px;
// }
