import styled, { css } from 'styled-components';

import { PatientInfoForm } from 'components/organisms/PatientInfoForm';
import { FullScreen } from 'components/templates/Screen';

import { useDetectKeyboard, useMobile } from 'hooks';
import { mediaMax } from 'utils';
import { useVisit } from 'libs/VisitContext';

export default function PatientInfo() {
  const { selectedPatient } = useVisit();
  const isMobile = useMobile();
  const isKeyboardOpen = useDetectKeyboard();

  return (
    <Container isKeyboardOpen={isKeyboardOpen} isMobile={isMobile}>
      <PageTitle>{selectedPatient.patientId ? '환자 정보 수정' : '환자등록'}</PageTitle>
      <PatientInfoForm />
    </Container>
  );
}

const Container = styled(FullScreen)<{ isMobile: boolean; isKeyboardOpen: boolean }>`
  font-family: NotoSansRegular;
  padding: 31px 25px 80px;
  background-color: var(--gray-20);
  overflow-y: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(var(--vh, 1vh) * 100);
    `}

  ${({ isKeyboardOpen }) =>
    isKeyboardOpen &&
    css`
      height: 101vh;
    `}
      
  ${mediaMax.mobileBig} {
    padding: 31px 0px 80px;
  }
`;

const PageTitle = styled.h1`
  margin: 0 0 22px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--gray-700);

  ${mediaMax.mobileBig} {
    padding-left: 25px;
  }
`;
