import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { MediaPlayer } from 'components/atoms/MediaPlayer';
import { ScreenOverlay } from 'components/molecules/ScreenOverlay';

import { useMobile, useVolumeControl } from 'hooks';
import { useAgora } from 'libs/AgoraContext';

type ScreenSharingViewTypes = {
  isMobile: boolean;
};

function ShareMediaContent() {
  const isMobile = useMobile();
  const { remoteUsers } = useAgora();
  const remoteVideoRef = useRef<Array<string>>([]);
  const [sharedUser, setSharedUser] = useState<IAgoraRTCRemoteUser | null>(null);
  useVolumeControl(remoteUsers, remoteVideoRef);

  useEffect(() => {
    const sharingClient = remoteUsers.find((user) => String(user.uid) === '111111');
    setSharedUser(sharingClient);
  }, [remoteUsers]);

  return (
    <ScreenSharingView isMobile={isMobile}>
      {sharedUser ? (
        <MediaPlayer audioTrack={sharedUser.audioTrack} id={`${sharedUser.uid}`} videoTrack={sharedUser.videoTrack} />
      ) : (
        <ScreenOverlay isActive={!sharedUser} />
      )}
    </ScreenSharingView>
  );
}
export default ShareMediaContent;

const ScreenSharingView = styled.div<ScreenSharingViewTypes>`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 2%;
  padding: 0 2px;
  background-color: var(--navy-800);
  border-radius: 10px;

  animation: 1s blink forwards;
  @keyframes blink {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
