import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import { InputWithCopyButton, InputWithCopyIcon } from 'components/molecules/Info';
import { Modal } from 'components/templates/Modal';
import { normal } from 'styles/fonts';
import { useAuth } from 'libs/AuthContext';
import { useCall } from 'libs/CallContext';

function RoomCreated(props: ReactModal.Props & { close: () => void; roomId: string }) {
  const {
    user: { doctorId },
  } = useAuth();

  const { channelId } = useCall();
  const { roomId } = props;
  return (
    <Modal
      content={
        <>
          <Text>Share the room URL for your patients to enter this room</Text>
          <InputWithCopyButton
            text={`${window.location.origin}/group-call/?roomId=${roomId}&channel=${channelId}&host=${doctorId}`}
            title="Room ID"
          />
        </>
      }
      footer={{}}
      title="Room created!"
      {...props}
    />
  );
}

export function RoomInfo(props: ReactModal.Props & { close: () => void; roomId: string }) {
  const {
    user: { doctorId },
  } = useAuth();

  const { channelId } = useCall();
  const { roomId, close } = props;

  return (
    <Modal
      content={
        <InputWithCopyIcon
          text={`${window.location.origin}/group-call/?roomId=${roomId}&channel=${channelId}&host=${doctorId}`}
          title="Room URL"
        />
      }
      footer={{
        confirm: {
          label: 'CLOSE',
          onClick: () => {
            close();
          },
        },
      }}
      title="Room information"
      {...props}
    />
  );
}

export default RoomCreated;

const Text = styled.div`
  ${normal};
  letter-spacing: -0.1px;
  color: var(--navy-900);
  margin-bottom: 30px;
`;
