import { produce } from 'immer';

import { initialState, State } from './state';

export type Action =
  | { type: 'INITIALIZE' }
  | { type: 'ADD_CHANNEL_ID'; payload: string }
  | { type: 'AUDIO_UPLOAD_SUCCESS' }
  | { type: 'AUDIO_UPLOAD_FAIL' }
  | { type: 'DELETE_JOINED_USER'; payload: string }
  | { type: 'GET_RECORDING_INFO'; payload: string }
  | { type: 'START_RECORDING'; payload: string }
  | { type: 'STOP_RECORDING' }
  | { type: 'SET_JOIN_MESSAGE'; payload: string }
  | { type: 'UPDATE_JOINED_USER'; payload: string }
  | { type: 'UPDATE_REGISTERED_PATIENT'; payload: boolean }
  | { type: 'UPDATE_SCREEN_SHARING'; payload: boolean };

export default function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case 'INITIALIZE':
      return initialState;

    case 'ADD_CHANNEL_ID':
      return produce(prevState, (draft) => {
        draft.channelId = action.payload;
      });

    case 'DELETE_JOINED_USER':
      return produce(prevState, (draft) => {
        draft.joinedUsers = prevState.joinedUsers.filter((item) => item !== action.payload);
      });

    case 'GET_RECORDING_INFO':
      return produce(prevState, (draft) => {
        draft.resourceId = action.payload;
      });

    case 'SET_JOIN_MESSAGE':
      return produce(prevState, (draft) => {
        draft.joinMessage = action.payload;
      });

    case 'START_RECORDING':
      return produce(prevState, (draft) => {
        draft.sid = action.payload;
        draft.audioUploaded = false;
      });

    case 'STOP_RECORDING':
      return produce(prevState, (draft) => {
        draft.sid = '';
        draft.audioUploaded = true;
      });

    case 'UPDATE_JOINED_USER':
      return produce(prevState, (draft) => {
        const checkExistedUser = prevState.joinedUsers.find((item) => item === action.payload);
        if (!checkExistedUser) {
          draft.joinedUsers = [...prevState.joinedUsers, action.payload];
        }
      });

    case 'UPDATE_SCREEN_SHARING':
      return produce(prevState, (draft) => {
        draft.isSharing = action.payload;
      });

    case 'UPDATE_REGISTERED_PATIENT':
      return produce(prevState, (draft) => {
        draft.registeredPatient = action.payload;
      });

    default:
      return prevState;
  }
}
