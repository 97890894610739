import styled from 'styled-components';
import { PatientTableHeader } from 'components/molecules/PatientTableHeader';
import { PatientTableRow } from 'components/molecules/PatientTableRow';
import { mediaMax } from 'utils';

export default function PatientListTable() {
  return (
    <Container>
      <PatientTableHeader />
      <PatientTableRow />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 35px;

  ${mediaMax.mobileBig} {
    margin-bottom: 20px;
  }
`;
