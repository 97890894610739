import DoctorRoute from 'components/atoms/DoctorRoute/DoctorRoute';
import { Switch, useRouteMatch } from 'react-router-dom';
import { DoctorRoomMain } from 'components/pages/DoctorRoomMain';

function DoctorRoomScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <DoctorRoute exact component={DoctorRoomMain} path={path} />
    </Switch>
  );
}

export default DoctorRoomScreen;
