import { useRef } from 'react';
import styled from 'styled-components';
import { demi, normal } from 'styles/fonts';
import { flexCenter, flexRow } from 'styles/mixins';

export default function KakaoLoginBtn() {
  const kakaoAuthRef = useRef(
    // eslint-disable-next-line max-len
    `${process.env.REACT_APP_KAKAO_AUTH_URI}/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`,
  );
  return (
    <Container href={kakaoAuthRef.current}>
      <Wrapper>
        <KakaoLoginIcon src="/icons/ic-kakao.png" />
        <span>카카오 로그인</span>
      </Wrapper>
    </Container>
  );
}

const Wrapper = styled.div`
  ${flexRow}
  ${flexCenter}
  background-color: #fee500;
  border-radius: 4px;
  color: #191919;
  height: 56px;
  margin-bottom: 8px;
  ${normal};
  ${demi};
  font-size: 18px;
  &:hover {
    background-color: rgba(254, 229, 0, 0.5);
  }
`;

const Container = styled.a`
  width: 100%;
`;

const KakaoLoginIcon = styled.img`
  width: 16.8px;
  height: 15.52px;
  margin-right: 16px;
`;
