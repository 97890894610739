import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from 'components/atoms/Logo';
import { Screen } from 'components/templates/Screen';
import { Spinner } from 'components/atoms/Spinner';

import { useAuth } from 'libs/AuthContext';
import { getParameter, media } from 'utils';
import * as mixins from 'styles/mixins';

function KakaoLoginPage() {
  const { error, isGuardian, kakaoLogin } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const kakaoAuthToken = getParameter('code');
    kakaoLogin(kakaoAuthToken);
  }, []);

  useEffect(() => {
    if (!error) return;

    alert('로그인 실패');
    history.push('/login');
  }, [error]);

  useEffect(() => {
    if (!isGuardian) return;

    history.push('/guardian');
  }, [isGuardian]);

  return (
    <Wrapper>
      <Content>
        <Logo size="big" />
        <Spinner />
        계정을 확인하는 중입니다
      </Content>
    </Wrapper>
  );
}

export default KakaoLoginPage;

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  color: var(--label-blue);
  background-color: var(--white);

  ${media.main} {
    background-color: rgb(246, 248, 252);
  }
`;

const Content = styled.div`
  ${mixins.flexCenter};
  flex-direction: column;
  height: 100%;

  > div {
    margin-bottom: 40px;
  }

  ${media.main} {
    height: auto;
    margin-top: 134px;
    margin-bottom: auto;
  }
`;
