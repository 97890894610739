import styled from 'styled-components';

import { RearScreen } from 'components/molecules/RearScreen';
import { FrontScreen } from 'components/molecules/FrontScreen';

import { useAgora } from 'libs/AgoraContext';

import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

interface MobileFrontCameraViewPropsType {
  rtcUsers: IAgoraRTCRemoteUser[];
}

function MobileRearCameraView({ rtcUsers }: MobileFrontCameraViewPropsType) {
  const { remoteFacingMode, facingMode } = useAgora();

  return (
    <Wrapper>
      {facingMode === 'environment' && (
        <>
          <RearScreen />
          <FrontScreen rtcUsers={rtcUsers} />
        </>
      )}
      {remoteFacingMode === 'environment' && (
        <>
          <RearScreen rtcUsers={rtcUsers} />
          <FrontScreen />
        </>
      )}
    </Wrapper>
  );
}

export default MobileRearCameraView;

const Wrapper = styled.div`
  position: relative;
  flex: 8;
  height: 88%;
  width: 100%;
`;
