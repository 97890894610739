import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MediaContent from 'components/organisms/GroupCallWithShare/MediaContent';
import ShareMediaContent from './ShareMediaContent';
import { RoomInfo } from 'components/organisms/RoomCreated/RoomCreated';
import { UtilityButtons } from '../UtilityButtons';

import { useAgora } from 'libs/AgoraContext';
import { useCall } from 'libs/CallContext';
import { useMobile } from 'hooks';
import { getParameter } from 'utils';
import * as mixins from 'styles/mixins';

type GroupCallProps = {
  handleEndBtnClick: () => void;
  handlePatientJoin: (allow: boolean) => void;
  handleSelectMenu: () => void;
  handleShareBtnClick: () => void;
  showSelectMenu: boolean;
};

function GroupCallWithShare({
  handleEndBtnClick,
  handlePatientJoin,
  handleSelectMenu,
  handleShareBtnClick,
  showSelectMenu,
}: GroupCallProps) {
  const { localScreenTrack, stopShareScreen } = useAgora();
  const { audioUploaded, joinMessage } = useCall();
  const isMobile = useMobile();
  const [showRoomInfo, setShowRoomInfo] = useState(false);

  useEffect(() => {
    if (audioUploaded) {
      handleEndBtnClick();
    }
    // eslint-disable-next-line
  }, [audioUploaded]);

  useEffect(() => {
    window.onbeforeunload = async () => {
      handleEndBtnClick();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!localScreenTrack) return;

    localScreenTrack.on('track-ended', () => {
      stopShareScreen();
    });

    // eslint-disable-next-line
  }, [localScreenTrack]);

  return (
    <Wrapper>
      <Main>
        {!isMobile && (
          <Header>
            <ChevronRight />
            {false && (
              <AllowModal>
                <p>
                  {joinMessage}
                  {' 님이 입장을 요청했습니다.'}
                </p>
                <AllowDenyBtnWrap>
                  <DenyBtn onClick={() => handlePatientJoin(false)}>거절</DenyBtn>
                  <AllowBtn onClick={() => handlePatientJoin(true)}>요청승인</AllowBtn>
                </AllowDenyBtnWrap>
              </AllowModal>
            )}
            {/* {joinMessage && (
              <AllowModal>
                <p>{joinMessage} 님이 입장을 요청했습니다.</p>
                <AllowDenyBtnWrap>
                  <DenyBtn onClick={() => handlePatientJoin(false)}>
                    거절
                  </DenyBtn>
                  <AllowBtn onClick={() => handlePatientJoin(true)}>
                    요청승인
                  </AllowBtn>
                </AllowDenyBtnWrap>
              </AllowModal>
            )} */}
          </Header>
        )}
        <ShareWrapper>
          <ShareMediaContent />
          <Footer>
            <UtilityButtonsWrap>
              <UtilityButtons
                showSelectMenu={showSelectMenu}
                onEndBtnClick={handleEndBtnClick}
                onMenuBtnClick={handleSelectMenu}
                onShareBtnClick={handleShareBtnClick}
              />
            </UtilityButtonsWrap>
          </Footer>
        </ShareWrapper>
        <MediaContent />
        <RoomInfo close={() => setShowRoomInfo(false)} isOpen={showRoomInfo} roomId={getParameter('roomId')} />
      </Main>
    </Wrapper>
  );
}

export default GroupCallWithShare;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--navy-900);
`;

const Main = styled.div`
  ${mixins.flexRow}
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 40px;
`;

const ShareWrapper = styled.div`
  ${mixins.flexColumn}
  align-items:center;
  width: 100%;
  height: 100%;
  margin-right: 1%;
`;

const Header = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 218px;
  padding: 40px 40px 0;
  z-index: 1;

  @media only screen and (max-width: 999px) {
    height: 170px;
  }

  @media only screen and (max-width: 599px) {
    height: 130px;
  }
`;

const AllowModal = styled.div`
  ${mixins.flexColumn}
  justify-content: space-between;
  width: 598px;
  height: 178px;
  padding: 40px 40px 32px;
  border-radius: 5px;
  background-color: white;
  font-size: 18px;

  p {
    margin: 0;
    color: var(--gray-600);
  }

  @media only screen and (max-width: 999px) {
    width: 360px;
    height: 100%;
    padding: 20px 20px 14px;
    font-size: 14px;
  }

  @media only screen and (max-width: 599px) {
    width: 200px;
    height: 100%;
    padding: 12px 12px 10px;
    font-size: 12px;
  }
`;

const AllowDenyBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  font-size: 16px;

  @media only screen and (max-width: 999px) {
    height: 30px;
    font-size: 12px;
  }

  @media only screen and (max-width: 599px) {
    height: 24px;
    font-size: 10px;
  }
`;

const DenyBtn = styled.div`
  ${mixins.flexCenter}
  width: 142px;
  height: 100%;
  margin-right: 8px;
  color: var(--gray-400);
  background: var(--gray-100);
  border: 1px solid var(--gray-200);
  border-radius: 3px;
  cursor: pointer;
`;

const AllowBtn = styled(DenyBtn)`
  width: 202px;
  color: var(--green-350);
  background: var(--green-80);
  border: 1px solid var(--green-100);
`;

const ChevronRight = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(/icons/ic-chevron-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 6px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const UtilityButtonsWrap = styled.div`
  display: flex;
  position: relative;
  margin: 20px 0 10px;
`;
