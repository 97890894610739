import { MEDIA_SIZES } from 'utils/variables';

export { snakeToCamel } from './arraySnakeToCamel';
export { default as arraySnakeToCamel } from './arraySnakeToCamel';
export { default as camelToSnake } from './camelToSnake';
export { default as extractCDATA } from './extractCDATA';
export { default as generateRandomString } from './generateRandomString';
export { default as getParameter } from './getParameter';
export { default as getScreenHeight } from './getScreenHeight';
export { default as getSession } from './getSession';
export { default as getUrlParameter } from './getUrlParameter';
export { default as setSession } from './setSession';
export { default as RtmClient } from './rtmClient';
export { default as xmlParser } from './xmlParser';
export {
  formatCodeToNumber,
  formatDateOfBirth,
  formatNumberToCode,
  formatTime12to24,
  formatTimeAMPM,
  formatTimeDay,
} from './format';

export { validatePassword, validatePhone, validateUsername } from './validate';

type Labels = keyof typeof MEDIA_SIZES;

export const isBrowser = typeof window !== 'undefined';
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const media = (Object.keys(MEDIA_SIZES) as Labels[]).reduce((acc, label) => {
  acc[label] = `
      @media (min-width: ${MEDIA_SIZES[label] / 16}em)`;
  return acc;
}, {} as Record<Labels, string>);

export const mediaMax = (Object.keys(MEDIA_SIZES) as Labels[]).reduce((acc, label) => {
  acc[label] = `
      @media (max-width: ${(MEDIA_SIZES[label] - 1) / 16}em)`;
  return acc;
}, {} as Record<Labels, string>);
