import { createContext } from 'react';
import { initialState, State } from './state';

/** Context */
export type ContextType = State & {
  addChannelId: (channelId: string) => void;
  checkPatientName: (patient: string, id: string) => Promise<void>;
  deleteJoinedUser: (uid: string) => void;
  getFlippedBackgroundImage: (formData: FormData) => Promise<string>;
  getRecordingInfo: (channelId: string) => void;
  initialize: () => void;
  setJoinMessage: (message: string) => void;
  startRecording: (channelId: string, resourceId: string) => void;
  stopRecording: () => void;
  updateJoinedUser: (uid: string) => void;
  updateSharing: (isSharing: boolean) => void;
  updateRegisteredPatient: (state: boolean) => void;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <CallContext>.');
};

export const initialContext: ContextType = {
  ...initialState,
  // call methods

  addChannelId: stub,
  checkPatientName: stub,
  deleteJoinedUser: stub,
  getFlippedBackgroundImage: stub,
  getRecordingInfo: stub,
  initialize: stub,
  setJoinMessage: stub,
  startRecording: stub,
  stopRecording: stub,
  updateJoinedUser: stub,
  updateSharing: stub,
  updateRegisteredPatient: stub,
};

const CallContext = createContext<ContextType>(initialContext);

export default CallContext;
