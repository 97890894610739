import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

// import { Logo } from 'components/atoms/Logo';
// import { Spinner } from 'components/atoms/Spinner';
import { FullScreen } from 'components/templates/Screen';

import { CMCAppointment, useAuth } from 'libs/AuthContext';
import * as mixins from 'styles/mixins';
import { getUrlParameter } from 'utils';

interface CMCDoctorProps extends RouteComponentProps {}

function CMCDoctorPage({ location }: CMCDoctorProps) {
  const {
    appointmentList,
    getAppointmentList,
    // getCMCDoctorInfo,
    getPatientStatus,
    isCMCDoctor,
    patientStatus,
  } = useAuth();

  const doctorIdRef = useRef(0);
  const departmentIdRef = useRef(0);
  const formatToday = () => {
    const dayArray = ['일', '월', '화', '수', '목', '금', '토'];
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const day = today.getDay();
    return `${year}-${month}-${date} (${dayArray[day]})`;
  };

  const startTreatment = (roomId: number) => {
    window.open(
      // eslint-disable-next-line max-len
      `/group-call/?roomId=appointment_${roomId}&orddrid=${doctorIdRef.current}&orddeptcd=${departmentIdRef.current}&referrer=guardian`,
      '',
      '_blank',
    );
  };

  useEffect(() => {
    // const cmcDoctorId = getUrlParameter('orddrid');
    // if (!cmcDoctorId) return;
    // getCMCDoctorInfo(parseInt(cmcDoctorId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cmcDoctorId = getUrlParameter('orddrid');
    const cmcDeptId = getUrlParameter('orddeptcd');
    doctorIdRef.current = parseInt(cmcDoctorId);
    departmentIdRef.current = parseInt(cmcDeptId);

    if (!cmcDoctorId) return;
    getPatientStatus(parseInt(cmcDoctorId));
    const statusInterval = setInterval(() => {
      getPatientStatus(parseInt(cmcDoctorId));
    }, 5000);

    return () => {
      clearInterval(statusInterval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (!isCMCDoctor) return;
    const query = new URLSearchParams(location.search);
    getAppointmentList(query.toString());

    // eslint-disable-next-line
  }, [isCMCDoctor]);

  return (
    <FullScreen>
      <Content>
        <Header>오늘예약</Header>
        <Today>{formatToday()}</Today>
        <AppointmentTable>
          <thead>
            <tr>
              <th>접속상태</th>
              <th>시간</th>
              <th>환자(나이/성별)</th>
              <th>진료과목</th>
              <th>의사</th>
              <th>국가</th>
              <th>상태</th>
              <th>&#39;</th>
            </tr>
          </thead>
          <tbody>
            {appointmentList.map((appointment: CMCAppointment, index: number) => {
              const { id, age, country, departmentName, doctorName, gender, patientName, status, time } = appointment;
              return (
                <TableRow key={`appointment-${index}`}>
                  <td>
                    <PatientStatus isActive={patientStatus ? patientStatus[id] : false} />
                  </td>
                  <td>{time}</td>
                  <td>{`${patientName}(${age}/${gender})`}</td>
                  <td>{departmentName}</td>
                  <td>{doctorName}</td>
                  <td>{country}</td>
                  <td>{status}</td>
                  <td>
                    <EnterButton onClick={() => startTreatment(id)}>대기실 입장</EnterButton>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </AppointmentTable>
      </Content>
    </FullScreen>
  );
}

export default CMCDoctorPage;

// const LogoContainer = styled.div`
//   ${mixins.flexColumn}
//   ${mixins.flexCenter}
//   height: 100vh;
// `;

const Content = styled.div`
  ${mixins.flexColumn}
  padding: 72px;
  width: 100%;
  background-color: #f4f4f4;
  height: 100vh;
`;

const Header = styled.h1`
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  color: var(--grayscale-07);
`;

const Today = styled(Header.withComponent('h3'))`
  font-size: 13px;
  line-height: 19px;
  color: var(--grayscale-05);
  margin: 8px 0 0;
`;

const AppointmentTable = styled.table`
  margin-top: 32px;
  border-collapse: collapse;
  border-spacing: 0 10px;

  tbody:before {
    content: '@';
    display: table-row;
    line-height: 10px;
    text-indent: -99999px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 8px;
    td:last-of-type {
    }
  }

  thead tr {
    th {
      font-family: NotoSansMedium;
      font-size: 13px;
      color: #909090;
      background-color: #eeeeee;
      padding: 11.5px 0 11.5px;
    }
    th:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    th:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  tbody tr {
    td {
      font-family: NotoSansRegular;
      text-align: center;
      font-size: 15px;
      line-height: 32px;
    }
    td:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    td:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }
`;

const EnterButton = styled.button`
  border-radius: 3px;
  background-color: var(--main-blue-01);
  border: 1px solid var(--main-blue-02);
  color: var(--blue-100);
  padding: 6px 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TableRow = styled.tr`
  background-color: white;
  ${mixins.flexRow}
  align-items: center;
`;

const PatientStatus = styled.div<{ isActive: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 50%;
  background-color: ${(props) => (props.isActive ? 'var(--blue-200)' : 'var(--gray-50)')};
`;
