const getSession = (key) => {
  const stringValue = window.sessionStorage.getItem(key);
  if (stringValue !== null) {
    const value = JSON.parse(stringValue);
    const expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.value;
    }
    window.sessionStorage.removeItem(key);
  }
  return null;
};

export default getSession;
