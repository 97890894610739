import { useEffect, useRef, useState } from 'react';

import AgoraRTC, { IAgoraRTCRemoteUser, ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';
import { useAuth } from 'libs/AuthContext';
import { generateRandomString } from 'utils';

AgoraRTC.setLogLevel(4);
type Connection = 'checking' | 'success' | 'fail';

const useNetworkCheck = () => {
  const { rtcToken, getRTCToken, resetRTCToken } = useAuth();

  const downlinkRef = useRef(
    AgoraRTC.createClient({
      codec: 'vp8',
      mode: 'rtc',
    }),
  );

  const uplinkRef = useRef(
    AgoraRTC.createClient({
      codec: 'vp8',
      mode: 'rtc',
    }),
  );

  const cameraTrackRef = useRef<ILocalVideoTrack | undefined>(undefined);
  const channelNameRef = useRef('');
  const connectionRef = useRef<Connection>('checking');
  const microphoneTrackRef = useRef<ILocalAudioTrack | undefined>(undefined);

  const [hasConnection, setHasConnection] = useState(false);

  const handleConnection = ({ uplinkNetworkQuality, downlinkNetworkQuality }) => {
    if (uplinkNetworkQuality > 3 || downlinkNetworkQuality > 3) {
      connectionRef.current = 'fail';
    }
  };

  const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    try {
      await downlinkRef.current.subscribe(user, mediaType);
    } catch (e) {
      console.error(e);
    }
  };

  const closeConnection = async () => {
    microphoneTrackRef.current.stop();
    microphoneTrackRef.current.close();
    cameraTrackRef.current.stop();
    cameraTrackRef.current.close();
    await uplinkRef.current.leave();
    await downlinkRef.current.leave();
    setHasConnection(true);
  };

  useEffect(() => {
    const createLocalTracks = async () => {
      const audioConfig = {
        AEC: true,
        ANS: true,
      };
      const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig);

      microphoneTrackRef.current = microphoneTrack;
      cameraTrackRef.current = cameraTrack;
      const channelName = generateRandomString(10);
      channelNameRef.current = channelName;
      getRTCToken(channelName);
    };
    createLocalTracks();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!rtcToken || hasConnection) return;

    const testConnection = async () => {
      await uplinkRef.current.join(
        process.env.REACT_APP_AGORA_APP_ID,
        channelNameRef.current,
        rtcToken || null,
        'uplink',
      );

      await downlinkRef.current.join(
        process.env.REACT_APP_AGORA_APP_ID,
        channelNameRef.current,
        rtcToken || null,
        'downlink',
      );
      resetRTCToken();
      uplinkRef.current.on('network-quality', handleConnection);
      downlinkRef.current.on('network-quality', handleConnection);
      downlinkRef.current.on('user-published', handleUserPublished);

      setTimeout(() => {
        if (connectionRef.current !== 'checking') {
          alert('POOR_CONNECTION');
        }

        closeConnection();
      }, 5000);

      await uplinkRef.current.publish([microphoneTrackRef.current, cameraTrackRef.current]);
    };

    testConnection();
    // eslint-disable-next-line
  }, [rtcToken]);

  return { hasConnection };
};

export default useNetworkCheck;
