import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ButtonModal } from 'components/organisms/ButtonModal';

import { formatTimeAMPM, formatTimeDay } from 'utils/format';
import { useVisit } from 'libs/VisitContext';

import { flexCenter } from 'styles/mixins';
import { TimeTable } from 'libs/VisitContext/state';

function ScheduleTableRow() {
  const {
    doctorTimeTable,
    updateScheduleSuccess,
    deleteDoctorSchedule,
    setSelectedSchedule,
    selectedSchedule,
    initialize,
  } = useVisit();
  const [displayList, setDisplayList] = useState<TimeTable[]>(doctorTimeTable);
  const [showDeleteModal, setShowDeletedModal] = useState(false);

  const { startTime: seletedStartTime, endTime: selectedEndTime, day: selectedDay, id } = selectedSchedule;

  const handleDeletedBtnClick = (schedule: TimeTable) => {
    setShowDeletedModal(true);
    setSelectedSchedule(schedule);
  };

  useEffect(() => {
    setDisplayList(doctorTimeTable);
  }, [doctorTimeTable]);

  useEffect(() => {
    if (updateScheduleSuccess) {
      setShowDeletedModal(false);
      initialize();
    }
  }, [updateScheduleSuccess]);

  return (
    <>
      <ButtonModal
        buttons={[
          { type: 'close', name: '취소', action: () => setShowDeletedModal(false) },
          { name: '삭제', action: () => deleteDoctorSchedule(id) },
        ]}
        isOpen={showDeleteModal}
      >
        <ModalMainText>
          {`${formatTimeDay(selectedDay)}요일 ${formatTimeAMPM(seletedStartTime)} ~ ${formatTimeAMPM(selectedEndTime)}`}
        </ModalMainText>
        <ModalSubText>일정을 삭제하시겠습니까?</ModalSubText>
      </ButtonModal>

      {displayList.map((schedule: TimeTable, idx) => {
        const { startTime, day, endTime } = schedule;
        return (
          <TableRow key={`time-${idx}`}>
            <div>{formatTimeDay(day)}</div>
            <div>{`${formatTimeAMPM(startTime)} ~ ${formatTimeAMPM(endTime)}`}</div>
            <div>
              <EnterBtn onClick={() => handleDeletedBtnClick(schedule)}>삭제</EnterBtn>
            </div>
          </TableRow>
        );
      })}
    </>
  );
}

export default ScheduleTableRow;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 13px 0;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #fff;

  div {
    ${flexCenter};
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-line;
    color: #3e3e3e;
    text-align: center;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:nth-child(2) {
    flex: 1.7;
  }

  div:nth-child(3) {
    flex: 1.3;
  }
`;

const EnterBtn = styled.button`
  height: 30px;
  min-height: 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;
  width: 58px;
  border: 1px solid #6bc7d3;
  background-color: #d4f3f7;
  color: #42a8b5;
`;

const ModalMainText = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: 700;
  color: #4d86bf;
`;

const ModalSubText = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: 400;
  color: var(--gray-600);
`;
