import ReactModal from 'react-modal';
import styled from 'styled-components';

import { Modal } from 'components/templates/Modal';
import { normal } from 'styles/fonts';

function ErrorModal(props: ReactModal.Props & { close: () => void; errorMsg: string }) {
  const { errorMsg } = props;
  return <Modal content={<Text>{errorMsg}</Text>} footer={{}} title="요청 실패" {...props} />;
}

export default ErrorModal;

const Text = styled.div`
  ${normal};
  letter-spacing: -0.1px;
  color: var(--navy-900);
  margin-bottom: 30px;
`;
