import styled from 'styled-components';

import { MediaPlayer } from 'components/atoms/MediaPlayer';
import { ScreenOverlay } from 'components/molecules/ScreenOverlay';

import { useVisit } from 'libs/VisitContext';
import { useAuth } from 'libs/AuthContext';
import { useAgora } from 'libs/AgoraContext';

import { normal } from 'styles/fonts';
import * as mixins from 'styles/mixins';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

interface RearScreenPropsType {
  rtcUsers?: IAgoraRTCRemoteUser[];
}

function RearScreen({ rtcUsers }: RearScreenPropsType) {
  const { audioMuted, localAudioTrack, localVideoTrack, videoMuted } = useAgora();
  const { isDoctor, user: doctor } = useAuth();
  const { departmentName, doctorName } = useVisit();

  if (!rtcUsers) {
    return (
      <RearScreenView>
        <ScreenOverlay hasBorder={false} isActive={videoMuted} />
        <MediaPlayer audioTrack={localAudioTrack} id="rearVideo" videoTrack={localVideoTrack} />
        <RearScreenInfo>
          {isDoctor ? `${doctor.fullname}` : '보호자'}
          {audioMuted && <MutedIcon />}
        </RearScreenInfo>
      </RearScreenView>
    );
  }

  const [{ audioTrack, videoTrack, hasAudio, hasVideo }] = rtcUsers;
  return (
    <RearScreenView>
      {!rtcUsers.length ? (
        <>
          <ScreenOverlay hasBorder={false} isActive={false} />
          <RearScreenInfo>{isDoctor ? '보호자' : `${departmentName} ${doctorName}`}</RearScreenInfo>
        </>
      ) : (
        <>
          <ScreenOverlay hasBorder={false} isActive={!hasVideo} />
          <MediaPlayer audioTrack={audioTrack} id="rearVideo" videoTrack={videoTrack} />
          <RearScreenInfo>
            {isDoctor ? '보호자' : `${departmentName} ${doctorName}`}
            {!hasAudio && <MutedIcon />}
          </RearScreenInfo>
        </>
      )}
    </RearScreenView>
  );
}

export default RearScreen;

interface ScreenViewTypes {
  isLocal?: boolean;
}

const RearScreenView = styled.div<ScreenViewTypes>`
  position: relative;
  width: 100%;
  height: 100%;

  video {
    ${mixins.fitVideo}
  }
`;

const RearScreenInfo = styled.div`
  ${mixins.flexCenter}
  position: absolute;
  left: 16px;
  bottom: 32px;
  padding: 4px 22px;
  border-radius: 8px;
  font-size: 13px;
  z-index: 5;
  background-color: #1f1f1f;
  opacity: 0.5;
  ${normal};
  color: var(--white);
`;

const MutedIcon = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
`;
