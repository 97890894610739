import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { PatientTable } from 'components/organisms/PatientTable';
import { FullScreen } from 'components/templates/Screen';

import { useMobile, usePagination } from 'hooks';
import { useVisit } from 'libs/VisitContext';

const DATA_PER_PAGE = 6;
const DISPLAYED_LIST_LIMIT = 3;

export default function PatientList() {
  const isMobile = useMobile();
  const history = useHistory();

  const {
    patientCount: count,
    error,
    selectedPatient,
    getPatientDetail,
    getPatientDetailSuccess,
    getPatientList: getList,
    resetInitialStatus,
  } = useVisit();

  const { pagination } = usePagination({
    count,
    dataPerPage: DATA_PER_PAGE,
    displayedListLimit: DISPLAYED_LIST_LIMIT,
    getList,
  });

  const handleEditBtnClick = () => {
    getPatientDetail();
  };

  useEffect(() => {
    if (!getPatientDetailSuccess) return;
    resetInitialStatus();
    history.push('/patient-info');
  }, [getPatientDetailSuccess]);

  useEffect(() => {
    if (!error) return;
    alert('환자 삭제 실패. 다시 한 번 시도해주세요');
  }, [error]);

  return (
    <Container isMobile={isMobile}>
      <Wrapper>
        <TitleWrap>
          <PageTitle>환자관리</PageTitle>
          <EditBtn disabled={!selectedPatient.ssn} onClick={() => handleEditBtnClick()}>
            환자 정보 수정
          </EditBtn>
        </TitleWrap>
        <PatientTable />
        {count > DATA_PER_PAGE && <PaginationSection>{pagination}</PaginationSection>}
      </Wrapper>
    </Container>
  );
}

const Container = styled(FullScreen)<{ isMobile: boolean }>`
  background-color: var(--gray-20);
  padding: 31px 25px 80px;
  font-family: NotoSansRegular;
  overflow-y: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(var(--vh, 1vh) * 100 - 50px);
    `}
`;

const Wrapper = styled.div`
  flex: 1;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h1`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--gray-700);
  margin: 0 0 22px 0;
`;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const EditBtn = styled(Button)<{ disabled: boolean }>`
  height: 30px;
  min-height: 30px;
  padding: 0 13px;
  background-color: var(--gray-400);
  border-radius: 2px;
  font-size: 15px;
  font-weight: 400;
  color: var(--white);
  cursor: default;

  ${({ disabled }) =>
    !disabled &&
    css`
      background-color: #d9ecff;
      border: 1px solid #7cbdff;
      border-radius: 3px;
      color: #036ad1;
      cursor: pointer;
    `}
`;
