import { createContext } from 'react';
import { Category, initialState, PatientInfo, SelectedPatient, State, TimeTable } from './state';

/** Context */
export type ContextType = State & {
  checkDoctorSchedule: () => Promise<void>;
  deleteDoctorSchedule: (timeTableId: number) => Promise<void>;
  deletePatient: () => Promise<void>;
  editPatient: (patientInfo: PatientInfo) => Promise<void>;
  getCompleteRoundList: () => Promise<void>;
  getDoctorTimeTable: (page: number) => Promise<void>;
  getGuardianTurn: () => Promise<void>;
  getPatientDetail: () => Promise<void>;
  getPatientList: (page: number) => Promise<void>;
  getRoundList: (status: Category) => Promise<void>;
  getVisitHistoryList: () => Promise<void>;
  getWaitingRoundList: () => Promise<void>;
  initialize: () => void;
  resetInitialStatus: () => void;
  registerPatient: (patientInfo: PatientInfo) => Promise<void>;
  setCategory: (category: Category) => void;
  setSelectedPatient: (selectedPatient: SelectedPatient) => void;
  setSelectedSchedule: (schedule: TimeTable) => void;
  startVideoCall: (patientId: number) => Promise<void>;
  updateDoctorSchedule: (doctorSchedule: TimeTable) => Promise<void>;
  updateHasGuardian: (roundId: number, status: boolean) => Promise<void>;
  updateRoundStatus: (roundId: number, status: number) => Promise<void>;
  getDoctorName: (doctorId: string) => Promise<void>;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <VisitContext>.');
};

export const initialContext: ContextType = {
  ...initialState,
  // call methods
  checkDoctorSchedule: stub,
  deleteDoctorSchedule: stub,
  deletePatient: stub,
  editPatient: stub,
  getCompleteRoundList: stub,
  getDoctorTimeTable: stub,
  getGuardianTurn: stub,
  getPatientDetail: stub,
  getPatientList: stub,
  getRoundList: stub,
  getVisitHistoryList: stub,
  getWaitingRoundList: stub,
  initialize: stub,
  resetInitialStatus: stub,
  registerPatient: stub,
  setCategory: stub,
  setSelectedPatient: stub,
  startVideoCall: stub,
  setSelectedSchedule: stub,
  updateDoctorSchedule: stub,
  updateHasGuardian: stub,
  updateRoundStatus: stub,
  getDoctorName: stub,
};

const VisitContext = createContext<ContextType>(initialContext);

export default VisitContext;
