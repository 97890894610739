import { useRef, useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ScreenOverlay } from 'components/molecules/ScreenOverlay';
import { MediaPlayer } from 'components/atoms/MediaPlayer';

import { useVolumeControl, useMobile } from 'hooks';
import { useAgora } from 'libs/AgoraContext';
import { useAuth } from 'libs/AuthContext';
import { useVisit } from 'libs/VisitContext';

import * as mixins from 'styles/mixins';
import { font } from 'styles/fonts';
import { flex } from 'styles/flex';
import { mediaMax } from 'utils';

type ParticipantViewTypes = {
  count: number;
  isLocal?: boolean;
  isMobile: boolean;
};

function MediaContent() {
  const isMobile = useMobile();
  const { audioMuted, localAudioTrack, localVideoTrack, remoteUsers, videoMuted } = useAgora();
  const {
    foreignerAppointment,
    isCMCDoctor,
    isDoctor,
    isForeigner,
    isGuardian,
    patientName,
    user: localUser,
  } = useAuth();
  const { departmentName: visitDeptName, doctorName: visitDoctorName, getDoctorName } = useVisit();

  const remoteVideoRef = useRef<Array<string>>([]);
  useVolumeControl(remoteUsers, remoteVideoRef);

  const [localUserName, setLocalUserName] = useState('');
  const [remoteUserName, setRemoteUserName] = useState('');

  const rtcUsers = useMemo(() => remoteUsers.filter((user) => String(user.uid) !== '111111'), [remoteUsers]);
  const count = useMemo(() => rtcUsers.length + 1, [rtcUsers]);
  const { departmentName: foreignerDeptName, doctorName: foreignerDoctorName } = foreignerAppointment;

  const getRemoteUserName = () => {
    switch (true) {
      case isCMCDoctor:
        return patientName;
      case isDoctor:
        return patientName ?? '환자';
      case isForeigner:
        return `${foreignerDeptName} ${foreignerDoctorName}`;
      case isGuardian:
        return `${visitDeptName} ${visitDoctorName}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isDoctor || !rtcUsers.length) return;
    const doctorId = rtcUsers[0].uid;
    getDoctorName(`${doctorId}`);
  }, [isDoctor, rtcUsers]);

  useEffect(() => {
    setRemoteUserName(getRemoteUserName());
  }, [visitDeptName]);

  useEffect(() => {
    setLocalUserName(isDoctor || isForeigner ? localUser.fullname : '보호자');
  }, []);

  return (
    <Wrapper count={count} isMobile={isMobile}>
      <ParticipantView isLocal count={count} isMobile={isMobile}>
        <ScreenOverlay isActive={videoMuted} />
        <MediaPlayer audioTrack={localAudioTrack} id="myVideo" videoTrack={localVideoTrack} />
        {(localUserName || audioMuted) && (
          <ParticipantInfo>
            {localUserName}
            {audioMuted && <ParticipantMutedIcon />}
          </ParticipantInfo>
        )}
      </ParticipantView>

      {rtcUsers.map((user) => {
        const { uid, audioTrack, videoTrack, hasAudio, hasVideo } = user;

        return (
          <ParticipantView key={uid} count={count} isLocal={false} isMobile={isMobile}>
            <ScreenOverlay isActive={!hasVideo} />
            <MediaPlayer audioTrack={audioTrack} id={`${uid}`} videoTrack={videoTrack} />
            {(remoteUserName.trim() || !hasAudio) && (
              <ParticipantInfo>
                {remoteUserName}
                {!hasAudio && <ParticipantMutedIcon />}
              </ParticipantInfo>
            )}
          </ParticipantView>
        );
      })}
    </Wrapper>
  );
}
export default MediaContent;

const Wrapper = styled.div<{ isMobile: boolean; count: number }>`
  ${flex('center', 'center')}
  flex-wrap: wrap;
  width: 100vw;
  height: 100%;

  column-gap: ${({ count }) => (count > 3 ? '1.042' : '2.084')}vw;
  ${mediaMax.tablet} {
    ${mixins.flexColumn}
    row-gap: 1.042vw;
  }
`;
// row-gap: ${({ count }) => (count > 3 ? '1.042vw' : '2.084')}vw;
const ParticipantView = styled.div<ParticipantViewTypes>`
  margin: 0;
  position: relative;
  ${({ count }) =>
    count <= 3 &&
    css`
      width: 30vw;
      height: 28vw;
    `}

  ${({ count }) =>
    count > 3 &&
    count <= 6 &&
    css`
      width: 20vw;
      height: 15vw;
    `}

  ${mediaMax.tablet} {
    width: 100vw;
    height: 20vh;
  }

  video {
    ${mixins.fitVideo}
    border-radius: 10px;

    ${({ isLocal }) => `
      transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -webkit-transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -moz-transform:${isLocal ? 'rotateY(180deg)' : ''};
  `}
  }
`;

const ParticipantInfo = styled.div`
  ${flex('center', 'center')};
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 40px;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 12px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  ${font('NotoSansRegular', 15, 400, 22)};
  color: var(--white);
  z-index: 2;
`;

const ParticipantMutedIcon = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
`;
