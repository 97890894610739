import { useRef, useEffect } from 'react';
import { ILocalVideoTrack, IRemoteVideoTrack, ILocalAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng';
import { useAgora } from 'libs/AgoraContext';

export interface VideoPlayerProps {
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  id: string;
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
}

function MediaPlayer({ audioTrack, id, videoTrack }: VideoPlayerProps) {
  const container = useRef<HTMLVideoElement>(null);
  const { virtualBackgroundMode } = useAgora();

  useEffect(() => {
    if (!container.current) return;
    if (!videoTrack) return;
    const mediaStream = new MediaStream();
    if (audioTrack) {
      const audioStreamTrack = audioTrack?.getMediaStreamTrack();
      mediaStream.addTrack(audioStreamTrack);
    }

    const videoStreamTrack = videoTrack.getMediaStreamTrack();
    mediaStream.addTrack(videoStreamTrack);
    container.current.srcObject = mediaStream;

    return () => {
      videoTrack?.stop();
    }; // ! 제외
  }, [container, id, audioTrack, videoTrack, virtualBackgroundMode]);

  useEffect(() => {
    if (id !== 'myVideo') {
      audioTrack?.play();
    }
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack, id]);

  return <video autoPlay playsInline id={id} muted={id === 'myVideo'} ref={container} />;
}

export default MediaPlayer;
