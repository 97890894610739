import { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

interface InputStyleProps {
  error?: boolean;
}

const Input = styled.input<InputStyleProps>`
  width: 100%;
  padding: 13px 8px;
  background-color: #f4f4f4;
  border: none;
  outline: none;
  color: var(--gray-600);
  font-size: 15px;

  &:focus {
    font-size: 16px;
  }

  ::placeholder {
    color: var(--gray-600);
    opacity: 0.5;
    padding-left: -80px;
  }
`;

interface useInputType extends HTMLAttributes<HTMLInputElement> {
  initValue?: any;
  placeholder: string;
  type?: 'password' | 'text';
  error?: boolean;
}

export const useInput = ({ initValue, placeholder, type = 'text', error, ...props }: useInputType) => {
  const [value, setValue] = useState(initValue || '');
  let input;
  if (Input) {
    input = (
      <Input
        autoCapitalize="none"
        error={error}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
    );
  } else {
    input = (
      <input
        autoCapitalize="none"
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
    );
  }
  return [value, input, setValue];
};
export const useTextInput = (options: Parameters<typeof useInput>[0]) => useInput({ type: 'text', ...options });

export default Input;
