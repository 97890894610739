import { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { VisitTable } from 'components/organisms/VisitTable';
import { FullScreen } from 'components/templates/Screen';

import { useVisit } from 'libs/VisitContext';
import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

function VisitMobile() {
  const { category, completeRoundCount, getCompleteRoundList, getWaitingRoundList, setCategory, waitingRoundCount } =
    useVisit();

  const today = useMemo(() => new Date().toJSON().slice(0, 10).replace(/-/g, '-'), []);

  useEffect(() => {
    getWaitingRoundList();

    const waitingInterval = setInterval(() => {
      getWaitingRoundList();
    }, 5000);

    return () => clearInterval(waitingInterval);
  }, []);

  useEffect(() => {
    getCompleteRoundList();
  }, []);

  return (
    <Wrapper>
      <Today>{today}</Today>
      <ListCategory>
        <CategoryItem isSelected={category === 'waiting'} onClick={() => setCategory('waiting')}>
          {`면회대기(${waitingRoundCount})`}
        </CategoryItem>
        <CategoryItem isSelected={category === 'complete'} onClick={() => setCategory('complete')}>
          {`면회완료(${completeRoundCount})`}
        </CategoryItem>
      </ListCategory>
      <VisitTable />
    </Wrapper>
  );
}

export default VisitMobile;

interface CategoryItemProps {
  isSelected: boolean;
}

const Wrapper = styled(FullScreen)`
  ${flex('', '', 'column')};
  padding: 31px 25px 0;
  background-color: var(--gray-20);
`;

const Today = styled.h1`
  margin: 0 0 22px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--gray-700);
`;

const ListCategory = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  margin-bottom: 30px;
`;

const CategoryItem = styled.div<CategoryItemProps>`
  ${font('NotoSansRegular', 16, 400, 24)};
  ${flex('center', 'center')};
  flex: 1;
  color: #bdbdbd;
  border-bottom: 1px solid var(--gray-50);
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: var(--blue-200);
      border-bottom: 3px solid var(--blue-200);
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    `}
`;
