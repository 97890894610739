import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng';
import { VirtualBackgroundMode } from './types';

export type FacingMode = 'user' | 'environment';

/** Types */
export type State = {
  agoraRTCClient: IAgoraRTCClient;
  agoraScreenClient: IAgoraRTCClient;
  agoraJoinState: boolean;
  audioMuted: boolean;
  connectionStatus: boolean;
  facingMode: FacingMode;
  localAudioTrack: ILocalAudioTrack | null;
  localScreenTrack: ILocalVideoTrack | null;
  localVideoTrack: ICameraVideoTrack | null;
  remoteUsers: IAgoraRTCRemoteUser[];
  remoteFacingMode: FacingMode | '';
  speaker: string;
  socketJoinState: boolean;
  videoMuted: boolean;
  videoQuality: string;
  virtualBackgroundMode: VirtualBackgroundMode;
};

/** State */
export const initialState: State = {
  agoraRTCClient: AgoraRTC.createClient({
    codec: 'vp8',
    mode: 'rtc',
  }),
  agoraScreenClient: AgoraRTC.createClient({
    codec: 'vp8',
    mode: 'rtc',
  }),
  agoraJoinState: false,
  audioMuted: false,
  connectionStatus: false,
  facingMode: 'user',
  localAudioTrack: null,
  localScreenTrack: null,
  localVideoTrack: null,
  remoteUsers: [],
  remoteFacingMode: '',
  speaker: '',
  socketJoinState: false,
  videoMuted: false,
  videoQuality: '1080p',
  virtualBackgroundMode: '',
};
