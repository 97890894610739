import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { Button } from 'components/atoms/Button';
import { useTextInput } from 'components/atoms/Input';
import { InputLabel } from 'components/atoms/InputLabel';
import { Intermisson } from '../Intermission';

import { useAuth } from 'libs/AuthContext';
import { media, validatePassword, validateUsername } from 'utils';
import * as fonts from 'styles/fonts';
import * as mixins from 'styles/mixins';

type PasswordType = 'password' | 'text';

function SignupForm() {
  const history = useHistory();
  const { error, guardianSignupErrorMsg, guardianVerifySuccess, guardianSignup, resetInitailizeStatus } = useAuth();
  const [passwordFormatError, setPasswordFormatError] = useState(false);
  const [usernameFormatError, setUsernameFormatError] = useState(false);
  const [passwordType, setPasswordType] = useState<PasswordType>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<PasswordType>('password');

  const [username, usernameInput] = useTextInput({
    id: 'usernameInput',
    placeholder: '아이디를 입력해주세요',
  });

  const [password, passwordInput] = useTextInput({
    id: 'passwordInput',
    placeholder: '영문 숫자 조합 8자 이상 입력해주세요',
    type: passwordType,
  });

  const [confirmPassword, confirmPasswordInput] = useTextInput({
    id: 'confirmPasswordInput',
    placeholder: '비밀번호를 다시 입력해주세요',
    type: confirmPasswordType,
  });

  const confirmPasswordError = useMemo(
    () => confirmPassword && password !== confirmPassword,
    [password, confirmPassword],
  );

  const iOS = useMemo(
    () =>
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
    [],
  );

  const signupBtnEnabled = useMemo(
    () =>
      !!username &&
      !!password &&
      !!confirmPassword &&
      password === confirmPassword &&
      !usernameFormatError &&
      !guardianSignupErrorMsg &&
      !passwordFormatError &&
      !confirmPasswordError,
    [username, password, confirmPassword],
  );

  const handleConfirmPasswordType = () => {
    setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
  };

  const handlePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const handleGoBack = () => {
    history.push('/login');
  };

  const handleSignupBtnClick = () => {
    guardianSignup(username, password);
  };

  useEffect(() => {
    if (guardianSignupErrorMsg) {
      resetInitailizeStatus();
    }
    const result = validateUsername(username);
    setUsernameFormatError(username && !result);
  }, [username]);

  useEffect(() => {
    const result = validatePassword(password);
    setPasswordFormatError(password && !result);
  }, [password]);

  useEffect(() => {
    if (!error) return;
    alert('회원가입 실패. 다시 한 번 시도해주세요');
  }, [error]);

  return (
    <FormContainer>
      {!guardianVerifySuccess ? (
        <Intermisson />
      ) : (
        <>
          <div>
            <Header>
              <BackArrowIcon onClick={() => handleGoBack()} />
              <MainText>보호자 회원가입</MainText>
            </Header>
            <InputWrapper error={usernameFormatError || !!guardianSignupErrorMsg}>
              <InputLabel htmlFor="usernameInput">아이디</InputLabel>
              {usernameInput}
            </InputWrapper>
            {usernameFormatError && <ErrorMessage>영어 소문자 또는 숫자를 입력해주세요</ErrorMessage>}
            {guardianSignupErrorMsg === 'EXISTING_USERNAME' && <ErrorMessage>존재하는 아이디입니다</ErrorMessage>}
            <InputWrapper error={passwordFormatError}>
              <InputLabel htmlFor="passwordInput">비밀번호</InputLabel>
              <PwToogleBtnWrapper>
                <PwToogleBtn type={passwordType} onClick={() => handlePasswordType()} />
                {passwordInput}
              </PwToogleBtnWrapper>
            </InputWrapper>
            {passwordFormatError && <ErrorMessage>영문+숫자 조합 8자리 이상으로 설정해주세요</ErrorMessage>}
            <InputWrapper error={confirmPasswordError}>
              <InputLabel htmlFor="confirmPasswordInput">비밀번호 확인</InputLabel>
              <PwToogleBtnWrapper>
                <PwToogleBtn type={confirmPasswordType} onClick={() => handleConfirmPasswordType()} />
                {confirmPasswordInput}
              </PwToogleBtnWrapper>
            </InputWrapper>
            {confirmPasswordError && <ErrorMessage>비밀번호가 일치하지 않습니다</ErrorMessage>}
          </div>
          <SignupBtn primary disabled={!signupBtnEnabled} iOS={iOS} size="mid" onClick={() => handleSignupBtnClick()}>
            회원가입
          </SignupBtn>
        </>
      )}
    </FormContainer>
  );
}

export default SignupForm;

const FormContainer = styled.form`
  ${mixins.flexColumn}
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: var(--white);

  ${media.main} {
    max-width: 675px;
    max-height: 1112px;
  }
`;

const Header = styled.div`
  ${mixins.flexCenter}
  position: relative;
  margin-bottom: 52px;
`;

const BackArrowIcon = styled.div`
  position: absolute;
  left: 0px;
  width: 32px;
  height: 32px;
  background-image: url('/icons/ic-back-arrow.svg');
  cursor: pointer;
`;

const MainText = styled.div`
  font-family: NotoSansMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #777777;
`;

const InputWrapper = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 8px;
    `}
`;

const PwToogleBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const PwToogleBtn = styled.div<{ type: string }>`
  position: absolute;
  right: 8px;
  width: 32px;
  height: 32px;
  background-image: url('/icons/ic-password-show.svg');
  background-repeat: no-repeat;
  background-position: center center;

  ${({ type }) =>
    type === 'text' &&
    css`
      background-image: url('/icons/ic-password-hide.svg');
    `}
`;

const Disabled = css`
  color: var(--gray-400);
  background-color: var(--gray-80);
  border: 1px solid var(--gray-150);
`;

const iOSMargin = css`
  margin-bottom: 80px;
`;

const AndroidMargin = css`
  margin-bottom: 24px;
`;

const SignupBtn = styled(Button)<{ disabled: boolean; iOS: boolean }>`
  ${fonts.normal};
  ${fonts.demi};
  color: var(--white);
  font-size: 20px;
  width: 100%;
  height: 60px;
  ${({ iOS }) => (iOS ? iOSMargin : AndroidMargin)}
  ${({ disabled }) => (disabled ? Disabled : '')}
`;
