import { useRef, useState } from 'react';

import ReactModal from 'react-modal';
import styled from 'styled-components';

import { InputLabel } from 'components/atoms/InputLabel';
import { DropdownButton } from 'components/molecules/DropdownButton';
import { TimePicker } from 'components/molecules/TimePicker';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { Dropdown } from '../Dropdown';

import { useVisit } from 'libs/VisitContext';
import { formatTime12to24 } from 'utils/format';

import * as fonts from 'styles/fonts';

interface ScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEnter: ({ day, start_time, end_time }: { day: number; start_time: string; end_time: string }) => void;
}

type IDropdown = '' | 'startTime' | 'endTime' | 'startPeriod' | 'endPeriod' | 'day';

interface IInitialTime {
  day: number;
  startPeriod: string;
  endPeriod: string;
  startTime: string;
  startMinute: string;
  endTime: string;
  endMinute: string;
  openedDropdown: IDropdown;
}

const initialTimeData: IInitialTime = {
  day: null,
  startPeriod: '',
  endPeriod: '',
  startTime: '',
  startMinute: '',
  endTime: '',
  endMinute: '',
  openedDropdown: '',
};

function ScheduleModal({ isOpen, onClose, onEnter }: ScheduleModalProps) {
  const { errorMsg } = useVisit();

  const dayRef = useRef(['월', '화', '수', '목', '금']);

  const [timeData, setTimeData] = useState(initialTimeData);

  const { endPeriod, endMinute, endTime, openedDropdown, day, startPeriod, startMinute, startTime } = timeData;

  const handleDropdownBtnClick = (opened: IDropdown) => {
    setTimeData((prev) => ({
      ...prev,
      openedDropdown: prev.openedDropdown === opened ? '' : opened,
    }));
  };

  const handleDaySelect = (e: React.MouseEvent<HTMLUListElement>) => {
    const { id } = e.target as HTMLLIElement;

    setTimeData((prev) => ({
      ...prev,
      day: parseInt(id.substring(5)),
      openedDropdown: '',
    }));
  };

  const handlePeriodSelect = (e: React.MouseEvent<HTMLUListElement>, keyName: string) => {
    const { innerText } = e.target as HTMLLIElement;

    if (innerText.length > 2) return;

    setTimeData((prev) => ({
      ...prev,
      openedDropdown: '',
      [keyName]: innerText,
    }));
  };

  const handleTimeSelect = (e: React.MouseEvent<HTMLUListElement>, name: string) => {
    const { id, innerText } = e.target as HTMLLIElement;

    if (innerText.length > 2) return;

    const formatTime = parseInt(innerText) < 10 ? `0${innerText}` : innerText;
    const keyName = id.includes('time') ? `${name}Time` : `${name}Minute`;

    setTimeData((prev) => ({
      ...prev,
      [keyName]: formatTime,
    }));
  };

  return (
    <ReactModal shouldCloseOnOverlayClick isOpen={isOpen} style={customStyles} onRequestClose={() => onClose()}>
      <FormContainer>
        <Title>일정 추가</Title>
        <InputLabel htmlFor="dayInput">요일</InputLabel>
        <Dropdown
          open={openedDropdown === 'day'}
          optionList={dayRef.current}
          trigger={
            <DropdownButton
              placeholder="요일을 선택해주세요."
              value={dayRef.current[day]}
              onClick={() => handleDropdownBtnClick('day')}
            />
          }
          onClick={(e: React.MouseEvent<HTMLUListElement>) => handleDaySelect(e)}
        />
        <InputLabel htmlFor="startTimeInput">시작시간</InputLabel>
        <TimePicker
          periodValue={startPeriod}
          showPeriodDrop={openedDropdown === 'startPeriod'}
          showTimeDrop={openedDropdown === 'startTime'}
          timeValue={(startTime || startMinute) && `${startTime} : ${startMinute}`}
          onPeriodChange={(e) => handlePeriodSelect(e, 'startPeriod')}
          onPeriodClick={() => handleDropdownBtnClick('startPeriod')}
          onTimeChange={(e) => handleTimeSelect(e, 'start')}
          onTimeClick={() => handleDropdownBtnClick('startTime')}
        />
        <InputLabel htmlFor="endTimeInput">종료시간</InputLabel>
        <TimePicker
          periodValue={endPeriod}
          showPeriodDrop={openedDropdown === 'endPeriod'}
          showTimeDrop={openedDropdown === 'endTime'}
          timeValue={(endTime || endMinute) && `${endTime} : ${endMinute}`}
          onPeriodChange={(e) => handlePeriodSelect(e, 'endPeriod')}
          onPeriodClick={() => handleDropdownBtnClick('endPeriod')}
          onTimeChange={(e) => handleTimeSelect(e, 'end')}
          onTimeClick={() => handleDropdownBtnClick('endTime')}
        />
        {errorMsg && <ErrorMessage>등록된 시간을 확인해주세요.</ErrorMessage>}
      </FormContainer>
      <EnterBtn
        onClick={() => {
          onEnter({
            day,
            start_time: formatTime12to24(`${startTime}:${startMinute} ${startPeriod}`),
            end_time: formatTime12to24(`${endTime}:${endMinute} ${endPeriod}`),
          });
        }}
      >
        일정 추가
      </EnterBtn>
    </ReactModal>
  );
}

export default ScheduleModal;

const FormContainer = styled.div`
  position: relative;
  width: 330px;
  height: 380px;
  box-sizing: border-box;
  padding: 20px;
  background-color: var(--white);
  border-radius: 4px;
  label {
    margin-top: 12px;
  }
  ul {
    width: 100%;
    height: 120px;
    overflow: scroll;
    margin-top: 5px;
  }
  div:nth-child(1) {
    ul {
      overflow: hidden;
      padding: 20px 0;
      li {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const Title = styled.label`
  ${fonts.midBig};
`;

const EnterBtn = styled.button`
  ${fonts.normal};
  ${fonts.demi};
  color: #036ad1;
  width: 100%;
  height: 48px;
  border: none;
  background-color: var(--white);
  border-top: 1px solid #ededed;
`;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
};
