import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';

interface ImageLabelButtonTypes extends HTMLAttributes<HTMLButtonElement> {
  src: string;
  alt: string;
}

function ImageLabelButton({ src, alt, children, ...props }: ImageLabelButtonTypes) {
  return (
    <ImageLabelBtn {...props}>
      <Icon alt={alt} src={src} />
      <ImageLabelBtnWrap>{children}</ImageLabelBtnWrap>
    </ImageLabelBtn>
  );
}

export default ImageLabelButton;

const ImageLabelBtn = styled(Button)`
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

const ImageLabelBtnWrap = styled.div`
  flex-direction: column;
`;

const Icon = styled.img`
  width: 31px;
  height: 37px;
  margin: 1px 17px 0 0;
`;
