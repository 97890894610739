import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { useTextInput } from 'components/atoms/Input';
import { InputLabel } from 'components/atoms/InputLabel';

import { useAuth } from 'libs/AuthContext';
import * as fonts from 'styles/fonts';
import { media } from 'utils';
import { useKeyPress } from 'hooks';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
// import { KakaoLoginBtn } from 'components/atoms/KakaoLoginBtn';

function LoginForm() {
  const {
    initialize,
    foreignerAppointment,
    isDoctor,
    isForeigner,
    isGuardian,
    getForeignerAppointment,
    login,
    loginMsg,
    user,
    patchPatientStatus,
  } = useAuth();
  const enterPressed = useKeyPress(13);
  const location = useLocation();
  const history = useHistory();

  const [username, usernameInput] = useTextInput({
    id: 'usernameInput',
    placeholder: '아이디를 입력해주세요',
    initValue: localStorage.getItem('username') || '',
  });

  const [password, passwordInput] = useTextInput({
    id: 'passwordInput',
    placeholder: '비밀번호를 입력해주세요',
    type: 'password',
  });

  const handleLoginBtnClick = () => {
    login({ username, password });
  };

  useEffect(() => {
    if (!isDoctor) return;
    if ([86, 111, 127].includes(user.doctorId)) {
      history.push('/doctor');
      return;
    }
    history.push('/visit');
    // eslint-disable-next-line
  }, [isDoctor]);

  useEffect(() => {
    if (!isGuardian) return;
    window.location.replace('/guardian');
    // eslint-disable-next-line
  }, [isGuardian]);

  useEffect(() => {
    if (!isForeigner) return;
    getForeignerAppointment();
  }, [isForeigner]);

  useEffect(() => {
    if (!!username && !!password) {
      login({ username, password });
    }
  }, [enterPressed]);

  useEffect(() => {
    const { id: appointmentId, doctorId } = foreignerAppointment;

    if (!appointmentId) return;
    patchPatientStatus(appointmentId, doctorId, true);
    history.push(`/group-call/?roomId=appointment_${appointmentId}&referrer=login`, '');
  }, [foreignerAppointment]);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <FormContainer>
      <InputLabel error={!!loginMsg} htmlFor="usernameInput">
        아이디
      </InputLabel>
      {usernameInput}
      <PwInputWrapper>
        <InputLabel error={!!loginMsg} htmlFor="passwordInput">
          비밀번호
        </InputLabel>
        {passwordInput}
      </PwInputWrapper>
      {loginMsg && <ErrorMessage>{loginMsg}</ErrorMessage>}
      <LoginBtnWrapper error={!!loginMsg}>
        <LoginBtn primary size="mid" onClick={() => handleLoginBtnClick()}>
          로그인
        </LoginBtn>
        {/* <KakaoLoginBtn /> */}
      </LoginBtnWrapper>
      {!location.pathname.includes('/ihcc') && (
        <SubBtnWrapper>
          <FindPasswordBtn href="/find-password">비밀번호 찾기</FindPasswordBtn>
          <FindUsernameBtn href="/find-username">아이디 찾기</FindUsernameBtn>
          <SignupBtn href="/self_authentication/access">보호자 회원가입</SignupBtn>
        </SubBtnWrapper>
      )}
    </FormContainer>
  );
}

export default LoginForm;

const FormContainer = styled.form`
  width: 100%;
  box-sizing: border-box;
  background-color: var(--white);

  ${media.main} {
    max-width: 375px;
  }
`;

const PwInputWrapper = styled.div`
  margin-top: 24px;
`;

const LoginBtnWrapper = styled.div<{ error: boolean }>`
  margin-top: 40px;

  ${({ error }) =>
    error &&
    css`
      margin-top: 0px;
    `}
`;

const LoginBtn = styled(Button)`
  ${fonts.normal};
  ${fonts.demi};
  font-size: 20px;
  width: 100%;
  height: 56px;
  background-color: #036ad1;
  color: var(--white);

  &:hover {
c  }
`;

const SubBtnWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const FindPasswordBtn = styled.a`
  width: 100%;
  line-height: 15px;
  border-right: 1px solid var(--gray-400);
  color: var(--gray-400);
  cursor: pointer;
  &:hover {
    color: #4d86bf;
  }
`;

const FindUsernameBtn = styled(FindPasswordBtn)`
  text-align: center;
`;

const SignupBtn = styled(FindPasswordBtn)`
  text-align: right;
  border-right: 0;
`;
