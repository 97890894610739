import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
// import dayjs from 'dayjs';

import { Spinner } from 'components/atoms/Spinner';
import { ImageLabelButton } from 'components/molecules/ImageLabelButton';
import { ErrorModal } from 'components/organisms/ErrorModal';
import { PatientIdModal } from 'components/organisms/PatientIdModal';
import { Screen } from 'components/templates/Screen';

import { useAuth } from 'libs/AuthContext';
import { useVisit } from 'libs/VisitContext';
import { flexCenter } from 'styles/mixins';

function FamilyManagement() {
  const { logout, logoutSuccess } = useAuth();
  const history = useHistory();
  const {
    canEnter,
    errorMsg,
    loading,
    roundId,
    // startTime,
    initialize,
    getGuardianTurn,
    checkDoctorSchedule,
    startVideoCall,
  } = useVisit();
  const [showPatientIdModal, setShowPatientIdModal] = useState(false);
  const [enterMsg, setEnterMsg] = useState('');

  const handleCloseBtnClick = () => {
    setEnterMsg('');
  };

  const handleEnterBtnClick = (patientId: number) => {
    if (loading) return;
    startVideoCall(patientId);
    togglePatientModal();
  };

  const handleChangePasswordBtnClick = () => {
    history.push('/change-password');
  };

  const handlePatientBtnClick = () => {
    checkDoctorSchedule();

    if (canEnter) {
      togglePatientModal();
    }

    // if (!startTime) {
    //   setEnterMsg('오늘은 면회할 수 없습니다');
    //   return;
    // }

    // const today = dayjs().format('YYYY-MM-DD');
    // const canEnterAt = dayjs(`${today} ${startTime}`).subtract(10, 'minutes').format('HH:mm');
    // setEnterMsg(`${canEnterAt}부터 입장할 수 있습니다`);
  };

  const togglePatientModal = () => {
    setShowPatientIdModal(!showPatientIdModal);
  };

  useEffect(() => {
    if (!roundId) return;
    getGuardianTurn();
    window.location.replace(
      `group-call/?roomId=${
        process.env.REACT_APP_AGORA_PREFIX
      }_round_${roundId}&referrer=${window.location.pathname.substring(1)}`,
    );
  }, [roundId]);

  useEffect(() => {
    if (!logoutSuccess) return;
    history.push('/login');
  }, [logoutSuccess]);

  useEffect(() => {
    if (!canEnter) return;
    togglePatientModal();
  }, [canEnter]);

  return (
    <Wrapper>
      <EunpyeongLogo alt="eunpyeong-logo" src="/icons/eun-logo.png" />
      {showPatientIdModal && (
        <PatientIdModal isOpen={showPatientIdModal} onClose={togglePatientModal} onEnter={handleEnterBtnClick} />
      )}
      {loading && (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      )}
      <ErrorModal close={handleCloseBtnClick} errorMsg={enterMsg} isOpen={!!enterMsg} />
      <ErrorModal close={initialize} errorMsg={errorMsg} isOpen={!!errorMsg} />
      <Content>
        <Header>
          <MainText>은평성모병원 비대면 면회</MainText>
          <SubText>이제 언제 어디서든 가족을 만나보세요</SubText>
        </Header>
        <ImageLabelButton alt="대기실 입장" src="/icons/ic-visit-room.svg" onClick={() => handlePatientBtnClick()}>
          <Label>면회실 입장</Label>
          <SubLabel>면회시작 10분 전 부터 면회실로 입장할 수 있습니다.</SubLabel>
        </ImageLabelButton>
        {/* <ImageLabelButton src="/icons/ic-apply-visit.svg" alt="면회 신청">
          <Label>면회 신청</Label>
        </ImageLabelButton> */}
        <ImageLabelButton alt="면회 내역 조회" src="/icons/ic-check-visit.svg">
          <Label>면회 내역 조회</Label>
        </ImageLabelButton>
        <ImageLabelButton
          alt="구성원 관리"
          src="/icons/ic-visit-management.svg"
          onClick={() => handleChangePasswordBtnClick()}
        >
          <Label>비밀번호 변경</Label>
        </ImageLabelButton>
        <ImageLabelButton alt="로그아웃" src="/icons/ic-logout.svg" onClick={() => logout()}>
          <Label>로그아웃</Label>
        </ImageLabelButton>
      </Content>
    </Wrapper>
  );
}

export default FamilyManagement;

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  padding: 51px 20px;
`;

const EunpyeongLogo = styled.img`
  margin-bottom: 24px;
`;

const Content = styled.div`
  flex-direction: column;
`;

const Header = styled.div`
  padding-bottom: 33px;
  margin-bottom: 55.65px;
  border-bottom: 1px solid #eeeeee;
`;

const MainText = styled.div`
  font-family: NotoSansMedium;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #717171;
`;

const SubText = styled.div`
  font-family: NotoSansMedium;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: var(--gray-400);
`;

const Label = styled.div`
  font-family: NotoSansMedium;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #717171;
`;

const SubLabel = styled.div`
  font-family: NotoSansMedium;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: var(--gray-400);
`;

const SpinnerWrap = styled.div`
  ${flexCenter}
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
