import { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  label?: string;
  labelTriggerList?: string[];
  open: boolean;
  optionIcon?: ReactElement;
  optionList: string[];
  trigger: ReactElement;
  onClick: React.MouseEventHandler<HTMLUListElement>;
}

interface ListProps extends HTMLAttributes<HTMLUListElement> {
  open: boolean;
  onClick: React.MouseEventHandler<HTMLUListElement>;
}
interface ItemProps extends HTMLAttributes<HTMLLIElement> {
  id: string;
  isLabelTrigger?: boolean;
  label?: string;
  optionIcon?: ReactElement;
}

export default function Dropdown({
  label,
  labelTriggerList,
  open,
  optionIcon,
  optionList,
  trigger,
  onClick,
}: DropdownProps) {
  return (
    <Container>
      <Dropdown.Trigger as={trigger} />
      <Dropdown.List open={open} onClick={onClick}>
        {optionList.map((option, idx) => {
          const isLabelTrigger = labelTriggerList?.includes(option);
          return (
            <Dropdown.Item
              key={`drop-${idx}`}
              id={`drop-${idx}`}
              isLabelTrigger={isLabelTrigger}
              label={label}
              optionIcon={optionIcon}
            >
              {option}
            </Dropdown.Item>
          );
        })}
      </Dropdown.List>
    </Container>
  );
}

function Trigger({ as }) {
  return as;
}

function List({ children, open, onClick }: ListProps) {
  if (!open) return null;
  return <Ul onClick={onClick}>{children}</Ul>;
}

function Item({ children, id, isLabelTrigger, label, optionIcon = null }: ItemProps) {
  if (isLabelTrigger) {
    return (
      <label htmlFor={label}>
        <Li id={id}>
          {children}
          {optionIcon}
        </Li>
      </label>
    );
  }
  return (
    <Li id={id}>
      {children}
      {optionIcon}
    </Li>
  );
}

Dropdown.Trigger = Trigger;
Dropdown.List = List;
Dropdown.Item = Item;

const Container = styled.div`
  position: relative;
`;

const Ul = styled.ul`
  position: absolute;
  margin: 0;
  margin-top: 15px;
  padding: 20px;
  background-color: white;
  border: 1px solid var(--gray-10);
  border-radius: 5px;
  list-style: none;
  z-index: 1;
`;

const Li = styled.li`
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    color: var(--blue-100);
  }
`;
