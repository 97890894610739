import { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

interface TimeDropdownProps {
  open: boolean;
  optionIcon?: ReactElement;
  trigger: ReactElement;
  onClick: React.MouseEventHandler<HTMLUListElement>;
}

interface ListProps extends HTMLAttributes<HTMLUListElement> {
  open: boolean;
  onClick: React.MouseEventHandler<HTMLUListElement>;
}
interface ItemProps extends HTMLAttributes<HTMLLIElement> {
  id: string;
  optionIcon?: ReactElement;
}

export default function TimeDropdown({ open, optionIcon, trigger, onClick }: TimeDropdownProps) {
  return (
    <Container>
      <TimeDropdown.Trigger as={trigger} />
      <TimeDropdown.List open={open} onClick={onClick}>
        <TimeListWrap>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((option, idx) => (
            <TimeDropdown.Item key={`timeDrop-${idx}`} id={`timeDrop-${idx}`} optionIcon={optionIcon}>
              {option}
            </TimeDropdown.Item>
          ))}
        </TimeListWrap>
        <Line />
        <MinuteListWrap>
          {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((option, idx) => (
            <TimeDropdown.Item key={`minuteDrop-${idx}`} id={`minuteDrop-${idx}`} optionIcon={optionIcon}>
              {option}
            </TimeDropdown.Item>
          ))}
        </MinuteListWrap>
      </TimeDropdown.List>
    </Container>
  );
}

function Trigger({ as }) {
  return as;
}

function List({ children, open, onClick }: ListProps) {
  if (!open) return null;
  return <Ul onClick={onClick}>{children}</Ul>;
}

function Item({ children, id, optionIcon = null }: ItemProps) {
  return (
    <Li id={id}>
      {children}
      {optionIcon}
    </Li>
  );
}

TimeDropdown.Trigger = Trigger;
TimeDropdown.List = List;
TimeDropdown.Item = Item;

const Container = styled.div`
  position: relative;
`;

const Ul = styled.ul`
  position: absolute;
  margin: 0;
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
  background-color: white;
  border: 1px solid var(--gray-10);
  border-radius: 5px;
  list-style: none;
  z-index: 1;
`;

const Li = styled.li`
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  &:hover {
    color: var(--blue-100);
  }
`;

const Line = styled.div`
  border-right: 1px solid var(--gray-10);
  height: 500px;
`;

const TimeListWrap = styled.div`
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MinuteListWrap = styled(TimeListWrap)``;
