import React from 'react';
import ReactDOM from 'react-dom';
import './styles/globals.css';
import { AuthProvider } from 'libs/AuthContext';
import { CallProvider } from 'libs/CallContext';
import { AgoraProvider } from 'libs/AgoraContext';
import App from './App';
import { VisitProvider } from 'libs/VisitContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CallProvider>
        <AgoraProvider>
          <VisitProvider>
            <App />
          </VisitProvider>
        </AgoraProvider>
      </CallProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
