import { createContext } from 'react';
import { initialState, State } from './state';

/** Context */
export type ContextType = State & {
  checkConnectionStatus: (doctorId: number) => Promise<void>;
  initialize: () => void;
  joinAgoraRTC: (channel: string, token: string, uid: string | number) => Promise<void>;
  joinAgoraScreen: (channel: string, token: string) => Promise<void>;
  leaveAgoraRTC: () => Promise<void>;
  leaveAgoraScreen: () => Promise<void>;
  removeBackground: () => void;
  rotateCamera: () => Promise<void>;
  setBackgroundBlurring: () => Promise<void>;
  setBackgroundImage: (filePath: string) => Promise<void>;
  startShareScreen: (channel: string, token: string) => Promise<void>;
  stopShareScreen: () => Promise<void>;
  toggleAudio: () => void;
  toggleVideo: () => void;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AgoraContext>.');
};

export const initialContext: ContextType = {
  ...initialState,

  checkConnectionStatus: stub,
  initialize: stub,
  joinAgoraRTC: stub,
  joinAgoraScreen: stub,
  leaveAgoraRTC: stub,
  leaveAgoraScreen: stub,
  removeBackground: stub,
  rotateCamera: stub,
  setBackgroundBlurring: stub,
  setBackgroundImage: stub,
  startShareScreen: stub,
  stopShareScreen: stub,
  toggleAudio: stub,
  toggleVideo: stub,
};

const AgoraContext = createContext<ContextType>(initialContext);

export default AgoraContext;
