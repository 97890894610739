import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { FullScreen } from 'components/templates/Screen';
import { ScheduleTable } from 'components/organisms/ScheduleTable';
import { ScheduleModal } from 'components/organisms/ScheduleModal';

import { useMobile, usePagination } from 'hooks';
import { useVisit } from 'libs/VisitContext';

function SchedulePage() {
  const isMobile = useMobile();

  const {
    scheduleCount: count,
    getDoctorTimeTable: getList,
    updateDoctorSchedule,
    updateScheduleSuccess,
    initialize,
  } = useVisit();

  const { pagination } = usePagination({
    count,
    dataPerPage: 6,
    displayedListLimit: 3,
    getList,
  });

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const handleScheduleBtnClick = (doctorSchedule) => {
    updateDoctorSchedule(doctorSchedule);
  };

  useEffect(() => {
    if (updateScheduleSuccess) {
      setShowScheduleModal(false);
      initialize();
    }
  }, [updateScheduleSuccess]);

  return (
    <>
      <ScheduleModal
        isOpen={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
        onEnter={handleScheduleBtnClick}
      />
      <Container isMobile={isMobile}>
        <PageTitleWrap>
          <PageTitle>일정관리</PageTitle>
          <ScheduleAddBtn onClick={() => setShowScheduleModal(true)}>일정 추가</ScheduleAddBtn>
        </PageTitleWrap>
        <ScheduleTable />
        {count > 6 && <PaginationSection>{pagination}</PaginationSection>}
      </Container>
    </>
  );
}

export default SchedulePage;

const Container = styled(FullScreen)<{ isMobile: boolean }>`
  font-family: NotoSansRegular;
  padding: 31px 25px 80px;
  background-color: var(--gray-20);
  overflow-y: auto;
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(var(--vh, 1vh) * 100);
    `}
`;

const PageTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ScheduleAddBtn = styled.button`
  height: 30px;
  min-height: 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;
  width: 88px;
  border: 1px solid #7cbdff;
  background-color: #d9ecff;
  color: #3a72aa;
`;

const PageTitle = styled.h1`
  margin: 0 0 22px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--gray-700);
`;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
`;
