import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'libs/AuthContext';
import { axiosClient } from 'libs/axios';
import { formatDateOfBirth, formatNumberToCode, getParameter, getSession } from 'utils';

function SelfAuthenticationResult() {
  const history = useHistory();
  const { error, checkDuplicatePhone, guardianVerify, isUniquePhone } = useAuth();

  useEffect(() => {
    const getNiceApiResult = async () => {
      const enc_data = getParameter('enc_data');
      const uuid = getSession('uuid');

      if (!uuid) {
        alert('본인 인증 실패');
        history.push('/self_authentication/access');
        return;
      }

      try {
        await axiosClient.options('/user/niceapi/certification/');
        const { status, data } = await axiosClient.post('/user/niceapi/certification/', {
          enc_data,
          device_id: uuid,
        });

        if (status === 200) {
          const { name, gender, birthdate, phone } = data;
          const formattedPhone = formatNumberToCode(82, phone);
          const authInfo = {
            firstName: name,
            gender,
            dateOfBirth: formatDateOfBirth(birthdate),
            phone: formattedPhone,
          };
          guardianVerify(authInfo);
          checkDuplicatePhone(formattedPhone);
        } else {
          alert('본인 인증 실패');
          history.push('/self_authentication/access');
        }
      } catch (e) {
        alert('본인 인증 실패');
        history.push('/self_authentication/access');
        console.error('authenticationResult error: ', e);
      }
    };

    getNiceApiResult();
  }, []);

  useEffect(() => {
    if (isUniquePhone) {
      history.push('/signup');
    }

    if (error) {
      alert('카카오로 가입한 이력이 있습니다');
      history.push('/login');
    }
  }, [error, isUniquePhone]);

  return <div></div>;
}

export default SelfAuthenticationResult;
