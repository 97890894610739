/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
import { ReactElement, useCallback, useEffect, useReducer } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import AuthContext, { ContextType, initialContext } from './context';
import reducer from './reducer';
import { CMCAppointment, GuardianAuthInfo, initialState } from './state';
import { LoginInfo } from './types';
import { axiosClient } from 'libs/axios';
import { arraySnakeToCamel, getUrlParameter } from 'utils';

dayjs.extend(utc);
dayjs.extend(timezone);

function AuthProvider({ children }: { children: ReactElement }): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = () => {
    dispatch({ type: 'INITIALIZE' });
  };

  const checkDuplicatePhone = async (phone: string) => {
    try {
      await axiosClient('user/account/check-phone/', {
        method: 'post',
        data: { phone },
      });
      dispatch({ type: 'SET_UNIQUE_PHONE' });
    } catch (e) {
      dispatch({ type: 'DUPLICATE_PHONE_ERROR' });
    }
  };

  const getVerificationCode = async (phoneNumber: string) => {
    dispatch({ type: 'SET_LOADING' });
    try {
      await axiosClient.post('user/kakao_verification_code/', {
        phone: phoneNumber,
        use_kakao: false,
      });

      dispatch({ type: 'GET_VERIFICATION_CODE_SUCCESS' });
    } catch (e) {
      console.error('error: ', e);
    }
  };

  const verificationPhone = async (code: string, phoneNumber: string) => {
    try {
      await axiosClient.post('user/verify_code/', {
        phone: phoneNumber,
        verification_code: code,
      });

      dispatch({ type: 'PHONE_VERIFY_SUCCESS', payload: phoneNumber });
    } catch (e) {
      dispatch({ type: 'PHONE_VERIFY_FAIL' });
      console.error('error: ', e);
    }
  };

  const findUsername = async (name: string, phoneNumber: string) => {
    try {
      const { data } = await axiosClient.post('user/find_username/', {
        first_name: name,
        last_name: '',
        phone: phoneNumber,
      });

      dispatch({ type: 'FIND_USER_NAME_SUCCESS', payload: data.username });
    } catch (e) {
      console.error('error: ', e);
    }
  };

  const findPassword = async (name: string, username: string, phoneNumber: string) => {
    try {
      await axiosClient.post('user/find_password/', {
        username,
        phone: phoneNumber,
        first_name: name,
        last_name: '',
      });

      dispatch({ type: 'FIND_PASSWORD_SUCCESS' });
    } catch (e) {
      const { data } = e.response;

      dispatch({ type: 'FIND_PASSWORD_FAIL', payload: data.msg });
      console.error('error: ', e);
    }
  };

  const getAgoraToken = async () => {
    try {
      const response = await axiosClient.get('/appointment/token');
      const { agora_token: agoraToken, uid } = response.data;

      dispatch({
        type: 'GET_AGORA_TOKEN',
        payload: { agoraToken, uid },
      });
    } catch (e) {
      console.error('error: ', e);
    }
  };

  const getAppointmentList = async (query: string) => {
    try {
      const { data } = await axiosClient(`/appointment/cmc/emr/?${query}`);
      const appointmentList: CMCAppointment[] = data.appointment_list
        .map((appointment) => ({
          age: appointment.age,
          date: appointment.orddd,
          gender: appointment.sex,
          id: appointment.ordkeynum,
          patientName: appointment.hngnm,
          pid: appointment.pid,
          time: `${appointment.ordtm.slice(0, 2)}:${appointment.ordtm.slice(2, 4)}`,
          country: appointment.country,
          doctorName: appointment.orddrnm,
          departmentName: appointment.orddeptnm,
          status: appointment.elbulbodstatnm,
          statusId: appointment.elbulbodstat,
        }))
        .sort((a: CMCAppointment, b: CMCAppointment) => a.time.localeCompare(b.time));

      dispatch({
        type: 'GET_APPOINTMENT_LIST_SUCCESS',
        payload: { appointmentList },
      });
    } catch (e) {
      console.error('error fetching appointmet list: ', e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCMCDoctorInfo = async (doctorId: number) => {
    try {
      // await axiosClient(`user/doctor/${doctorId}/cmc/`);
      // dispatch({
      //   type: 'GET_CMC_DOCTOR_INFO_SUCCESS',
      //   payload: {
      //     doctorName: `$`
      //   }
      // });
    } catch (e) {
      console.error('getting cmc doctor error: ', e);
    }
  };

  const getCMCTargetAppointment = async (doctorId: number, departmentId: number) => {
    try {
      const today = dayjs().tz('Asia/Seoul').format('YYYYMMDD');
      const { data } = await axiosClient(
        `appointment/cmc/emr/?orddrid=${doctorId}&orddeptcd=${departmentId}&fromdd=${today}&todd=${today}`,
      );

      const roomId = getUrlParameter('roomId');
      const lastIndex = roomId.lastIndexOf('_');
      const roundId = roomId.slice(lastIndex + 1);

      const target = data.appointment_list.find(
        (appointment) => appointment.orddd === today.toString() && appointment.ordkeynum === roundId,
      );

      if (target) {
        dispatch({
          type: 'GET_CMC_DOCTOR_INFO_SUCCESS',
          payload: {
            doctorName: `${target.orddeptnm} ${target.orddrnm}`,
            patientName: target.hngnm,
          },
        });
      }
    } catch (e) {
      console.error('Finding target error: ', e);
    }
  };

  const getForeignerAppointment = async () => {
    try {
      const today = dayjs().tz('Asia/Seoul').format('YYYYMMDD');

      const { data } = await axiosClient(`appointment/cmc/emr/?startDate=${today}&endDate=${today}`);

      const target = data.appointment_list.find((appointment) => appointment.orddd === today.toString());

      if (target) {
        const appointment = {
          id: target.ordkeynum,
          departmentName: target.deptengnm,
          doctorName: target.userengnm,
          doctorId: target.orddrid,
        };
        dispatch({
          type: 'GET_FOREIGNER_APPOINTMENT_SUCCESS',
          payload: { appointment },
        });
        return;
      }
      alert('You do not have an appointment today');
    } catch (e) {
      alert('You do not have an appointment today');
      console.error('errrorrrrrr: ', e);
    }
  };

  const getPatientStatus = async (doctorId: number) => {
    try {
      const { data } = await axiosClient.post('appointment/patient/', {
        doctor_id: doctorId,
      });

      dispatch({
        type: 'GET_PATIENT_STATUS_SUCCESS',
        payload: data.patient_status,
      });
    } catch (e) {
      console.error('getting cmc doctor error: ', e);
    }
  };

  const getPatientVisitList = async () => {
    try {
      const response = await axiosClient.get('appointment/today/waiting/');

      dispatch({
        type: 'GET_PATIENT_VISIT_LIST_SUCCESS',
        payload: arraySnakeToCamel(response.data.results),
      });
    } catch (e) {
      console.error('getting patient visit list error: ', e);
    }
  };

  const getRTCToken = async (roomId: string) => {
    try {
      const response = await axiosClient.post('/appointment/rtc_token/', {
        room_id: roomId,
      });
      const rtcToken = response.data.rtc_token;
      dispatch({
        type: 'GET_RTC_TOKEN_SUCCESS',
        payload: rtcToken,
      });
    } catch (e) {
      console.error('error: ', e);
    }
  };

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axiosClient.get('/user/user_info/');
      const { user_info } = response.data;

      setTimeout(() => {
        if (user_info.is_foreigner) {
          dispatch({
            type: 'FOREIGNER_LOGIN_SUCCESS',
            payload: {
              fullname: user_info.fullname,
              username: user_info.username,
            },
          });
          return;
        }
        if (!user_info.is_doctor) {
          dispatch({
            type: 'GUARDIAN_LOGIN_SUCCESS',
          });
          return;
        }

        dispatch({
          type: 'DOCTOR_LOGIN_SUCCESS',
          payload: {
            fullname: user_info.fullname,
            doctorId: user_info.doctor_id,
            username: user_info.username,
          },
        });
      }, 1000);
    } catch (e) {
      localStorage.removeItem('token');
      dispatch({ type: 'LOGOUT' });
    }
  }, []);

  const guardianSignup = async (username: string, password: string) => {
    const { firstName, gender, dateOfBirth, phone } = state.guardianAuthInfo;

    const guardianSignupInfo = {
      first_name: firstName,
      gender,
      date_of_birth: dateOfBirth,
      phone,
      is_guardian: true,
      username,
      email: `${username}@voidoc.io`,
      password,
      company: 3,
      country: 5,
    };

    dispatch({ type: 'GUARDIAN_SIGNUP' });

    try {
      await axiosClient.post('/user/account/', guardianSignupInfo);
      dispatch({ type: 'GUARDIAN_SIGNUP_SUCCESS' });
      alert('성공적으로 등록되었습니다.');
    } catch (e) {
      const { data } = e.response;
      dispatch({
        type: 'GUARDIAN_SIGNUP_FAIL',
        payload: data.msg,
      });
      console.error('signup error: ', e);
    }
  };

  const guardianVerify = (guardianAuthInfo: GuardianAuthInfo) => {
    const { firstName, gender, dateOfBirth, phone } = guardianAuthInfo;
    dispatch({
      type: 'GUARDIAN_VERIFY_SUCCESS',
      payload: {
        firstName,
        gender,
        dateOfBirth,
        phone,
      },
    });
  };

  const kakaoLogin = async (kakaoToken: string) => {
    try {
      const kakaoResponse = await axiosClient.post('/user/kakao/login/', {
        kakao_token: kakaoToken,
      });

      const { token, user_info } = kakaoResponse.data;
      localStorage.setItem('token', token);

      if (!user_info.is_doctor) {
        dispatch({
          type: 'GUARDIAN_LOGIN_SUCCESS',
        });
        return;
      }

      dispatch({
        type: 'DOCTOR_LOGIN_SUCCESS',
        payload: {
          doctorId: user_info.doctor_id,
          fullname: user_info.fullname,
          username: user_info.username,
        },
      });
    } catch (e) {
      localStorage.removeItem('token');
      dispatch({ type: 'LOGIN_FAIL', payload: '' });
      console.error('kakao login e: ', e);
    }
  };

  const login = useCallback(async (loginInfo: LoginInfo) => {
    dispatch({ type: 'LOGIN', payload: loginInfo });
    try {
      const loginResponse = await axiosClient.post('/user/login/', loginInfo);
      const { token, user_info } = loginResponse.data;
      localStorage.setItem('token', token);

      if (user_info.is_foreigner) {
        dispatch({
          type: 'FOREIGNER_LOGIN_SUCCESS',
          payload: {
            fullname: user_info.fullname,
            username: user_info.username,
          },
        });
        return;
      }

      if (!user_info.is_doctor) {
        dispatch({ type: 'GUARDIAN_LOGIN_SUCCESS' });
        return;
      }

      dispatch({
        type: 'DOCTOR_LOGIN_SUCCESS',
        payload: {
          doctorId: user_info.doctor_id,
          fullname: user_info.fullname,
          username: user_info.username,
        },
      });
      localStorage.setItem('username', loginInfo.username);
    } catch (e) {
      localStorage.removeItem('token');
      const { data } = e.response;
      dispatch({ type: 'LOGIN_FAIL', payload: data.msg });
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await axiosClient.post('/user/logout/');
      localStorage.clear();
      dispatch({
        type: 'LOGOUT',
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const patientLogin = useCallback(async (username: string) => {
    dispatch({
      type: 'PATIENT_LOGIN_SUCCESS',
      payload: {
        fullname: username,
        username,
      },
    });
  }, []);

  const patchChangePassword = async (password: string, changePassword: string) => {
    try {
      const { data } = await axiosClient.put('user/change_password/', {
        old_password: password,
        new_password: changePassword,
      });

      localStorage.setItem('token', data.token);
      dispatch({ type: 'CHANGE_PASSWORD_SUCCESS' });
    } catch (e) {
      dispatch({ type: 'CHANGE_PASSWORD_FAIL' });
      console.error('error: ', e);
    }
  };

  const patchPatientStatus = async (appointmentId: number, doctorId: number, hasPatientEntered: boolean) => {
    try {
      await axiosClient.patch('appointment/patient', {
        appointment_id: appointmentId,
        doctor_id: doctorId,
        has_patient_entered: hasPatientEntered,
      });
    } catch (e) {
      console.error('patch error: ', e);
    }
  };

  const resetRTCToken = async () => {
    dispatch({
      type: 'RESET_RTC_TOKEN',
    });
  };

  const resetInitailizeStatus = () => {
    dispatch({ type: 'RESET_INITAILIZE_STATUS' });
  };

  const getScreeningAppointment = async () => {
    const { data } = await axiosClient.get('appointment/today/waiting');

    const appointmentList = data.results.map((appointment) => ({
      id: appointment.id,
      dateOfBirth: appointment.date_of_birth,
      patientGender: appointment.patient_gender,
      appointmentDate: appointment.appointment_date,
      patientName: appointment.patient_name,
    }));

    dispatch({
      type: 'GET_SCREENING_APPOINTMENT_SUCCESS',
      payload: {
        appointmentList,
      },
    });
  };

  const getCompleteAppointment = async () => {
    const { data } = await axiosClient.get('appointment/today/done');

    const appointmentList = data.results.map((appointment) => ({
      id: appointment.id,
      dateOfBirth: appointment.date_of_birth,
      patientGender: appointment.patient_gender,
      appointmentDate: appointment.appointment_date,
      patientName: appointment.patient_name,
    }));

    dispatch({
      type: 'GET_COMPLETE_APPOINTMENT_SUCCESS',
      payload: {
        appointmentList,
      },
    });
  };

  const patchAppointmentStatus = async (appointmentId: number) => {
    await axiosClient.patch(`appointment/${appointmentId}/duration/`, {
      status: 2,
    });
  };

  const patchDoctorStatus = async (appointmentId: number, hasEntered: boolean) => {
    const startedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    await axiosClient.patch(`appointment/appointments/${appointmentId}/`, {
      has_doctor_entered: hasEntered,
      has_doctor_requested: true,
      started_at: hasEntered ? startedAt : '',
    });
  };

  useEffect(() => {
    const voidocToken = localStorage.getItem('token');
    if (!voidocToken) return;

    getUserInfo();
  }, []);

  // eslint-disable-next-line
  const authContext: ContextType = {
    ...initialContext,
    ...state,
    isDoctor: !!state.isDoctor,
    isPatient: !!state.isPatient,
    rtcToken: state.rtcToken,
    checkDuplicatePhone,
    findUsername,
    findPassword,
    getAgoraToken,
    getAppointmentList,
    getCompleteAppointment,
    getCMCDoctorInfo,
    getCMCTargetAppointment,
    getForeignerAppointment,
    getPatientStatus,
    getPatientVisitList,
    getRTCToken,
    getScreeningAppointment,
    getUserInfo,
    getVerificationCode,
    guardianSignup,
    guardianVerify,
    initialize,
    kakaoLogin,
    login,
    logout,
    verificationPhone,
    patchDoctorStatus,
    patientLogin,
    patchAppointmentStatus,
    patchChangePassword,
    patchPatientStatus,
    resetRTCToken,
    resetInitailizeStatus,
  };

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
