import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { SignupForm } from 'components/organisms/SignupForm';
import { Screen } from 'components/templates/Screen';

import { useAuth } from 'libs/AuthContext';

function SignupPage() {
  const history = useHistory();
  const { guardianSignupSuccess, guardianVerifySuccess } = useAuth();

  useEffect(() => {
    if (!guardianSignupSuccess) return;
    history.push('/login');
  }, [guardianSignupSuccess]);

  useEffect(() => {
    if (!guardianVerifySuccess) {
      history.push('/login');
    }
  }, [guardianVerifySuccess]);

  return (
    <Wrapper>
      <Content>
        <FormWrapper>
          <SignupForm />
        </FormWrapper>
      </Content>
    </Wrapper>
  );
}

export default SignupPage;

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  padding: 51px 24px 56px;
  color: var(--blue-100);
  background-color: var(--white);
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
