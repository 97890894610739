import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

// import { Overlay } from 'components/atoms/Overlay';
import { GroupCall } from 'components/organisms/GroupCall';
import { GroupCallWithShare } from 'components/organisms/GroupCallWithShare';
import { Intermisson } from 'components/organisms/Intermission';
import { MobileGroupCall } from 'components/organisms/MobileGroupCall';
import { FullScreen } from 'components/templates/Screen';

import { useAgora } from 'libs/AgoraContext';
import { useAuth } from 'libs/AuthContext';
import { useCall } from 'libs/CallContext';
import { useVisit } from 'libs/VisitContext';
import { axiosClient } from 'libs/axios';

import {
  // useNetworkCheck,
  useRTM,
  useMobile,
  useTimer,
} from 'hooks';
import { getUrlParameter } from 'utils';

import * as mixins from 'styles/mixins';

function GroupCallMain() {
  const {
    agoraJoinState,
    checkConnectionStatus,
    connectionStatus,
    initialize: initializeAgora,
    joinAgoraRTC,
    leaveAgoraRTC,
    leaveAgoraScreen,
    localScreenTrack,
    remoteUsers,
    startShareScreen,
    stopShareScreen,
    videoQuality,
  } = useAgora();

  const {
    agoraToken,
    foreignerAppointment,
    isDoctor,
    isGuardian,
    rtcToken,
    user,
    getAgoraToken,
    // getCMCDoctorInfo,
    getCMCTargetAppointment,
    getRTCToken,
    patchAppointmentStatus,
    patchDoctorStatus,
    isPatient,
    logout,
    patchPatientStatus,
  } = useAuth();

  const {
    audioUploaded,
    // channelId,
    resourceId,
    sid,
    isSharing,
    // addChannelId,
    getRecordingInfo,
    startRecording,
    stopRecording,
  } = useCall();

  // const hostIdRef = useRef(getParameter("host"));
  const history = useHistory();
  const isMobile = useMobile();
  // const { hasConnection } = useNetworkCheck();
  const { handlePatientJoin, joinRTM, leaveRTM } = useRTM();

  const rtcUsers = useMemo(
    () => remoteUsers.filter((remoteUser) => String(remoteUser.uid) !== '111111'),
    [remoteUsers],
  );

  const {
    getGuardianTurn,
    initialize: initializeVisit,
    startTime,
    turn,
    updateHasGuardian,
    updateRoundStatus,
  } = useVisit();

  const guardianTurnMessage = useMemo(() => {
    const now = dayjs();
    const startAt = dayjs(`${now.format('YYYY-MM-DD')} ${startTime}`);
    if (now.isBefore(startAt)) {
      return `${startAt.format('HH시mm분')} 부터 순서대로 면회가 진행됩니다`;
    }
    if (turn === 0) {
      return '의사가 곧 입장할 예정입니다';
    }

    return `${turn}건의 면회가 대기중입니다`;
  }, [turn]);

  const { result } = useTimer(300, rtcUsers.length > 0);

  const checkUrlRef = useRef(
    getUrlParameter('referrer') === 'visit' ||
      getUrlParameter('referrer') === 'guardian' ||
      getUrlParameter('referrer') === 'cmc' ||
      getUrlParameter('referrer') === 'login',
  );
  const roomIdRef = useRef(getUrlParameter('roomId'));

  const [showSelectMenu, setShowSelectMenu] = useState(false);
  // const [allowJoined, setAllowJoined] = useState(false);
  // const [waitingPatient, setWaitingPatient] = useState(true);

  const closeConnection = async () => {
    checkUrlRef.current = false;
    roomIdRef.current = '';
    await leaveAgoraScreen();
    stopRecording();
    leaveRTM();
    await leaveAgoraRTC();
    initializeAgora();
    initializeVisit();
  };

  const handleEndBtnClick = async () => {
    const lastIndex = roomIdRef.current.lastIndexOf('_');
    const roundId = parseInt(roomIdRef.current.slice(lastIndex + 1));
    try {
      await closeConnection();

      if ((!isGuardian && !isDoctor) || !user.doctorId) {
        try {
          if (foreignerAppointment.id) {
            await patchPatientStatus(foreignerAppointment.id, foreignerAppointment.doctorId, false);
            await logout();
            history.push('/ihcc');
          }
          window.close();
        } catch (e) {
          console.error('not a cmc doctor');
          history.goBack();
        }

        return;
      }
    } catch (e) {
      console.error('failed to leaven screen : ', e);
    }

    if (isDoctor) {
      if ([86, 111, 127].includes(user.doctorId)) {
        try {
          const appointmentId = getUrlParameter('roomId').match(/\d+/);
          await patchAppointmentStatus(parseInt(appointmentId[0]));
        } catch (e) {
          console.error('Status update error: ', e);
        }

        window.location.pathname = '/doctor';
        return;
      }

      try {
        await updateRoundStatus(roundId, 3);
      } catch (e) {
        console.error('state update error: ', e);
      }

      window.location.pathname = '/visit';
    }

    if (isGuardian) {
      try {
        await updateHasGuardian(roundId, false);
      } catch (e) {
        console.error('guardian state update error: ', e);
      }

      window.location.pathname = '/guardian';
    }
  };

  const handleSelectMenu = () => {
    setShowSelectMenu(!showSelectMenu);
  };

  const handleShareBtnClick = () => {
    if (isMobile) {
      alert('PC에서만 지원하는 기능입니다');
      return;
    }
    if (!isSharing) {
      startShareScreen(roomIdRef.current, rtcToken);
      return;
    }

    if (localScreenTrack) {
      stopShareScreen();
      return;
    }

    alert('다른 참가자가 공유 중입니다.');
  };

  useEffect(() => {
    if (!rtcToken || agoraJoinState || !agoraToken) return;
    const roomId = roomIdRef.current;

    if (!checkUrlRef.current && !connectionStatus) return;
    joinAgoraRTC(roomId, rtcToken, isDoctor ? user.doctorId : parseInt(user.uid));
    joinRTM();
    // if (allowJoined) {
    //   join(roomId, rtcToken, user.uid);
    // }

    // eslint-disable-next-line
  }, [agoraToken, rtcToken, agoraJoinState /* allowJoined */, connectionStatus, isDoctor]);

  useEffect(() => {
    getRTCToken(roomIdRef.current);
    getAgoraToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onbeforeunload = async () => {
      const cmcDoctorId = getUrlParameter('orddrid');

      if ((!checkUrlRef.current && !isPatient) || !!cmcDoctorId) {
        await axiosClient.put('visit/connection_status/', {
          doctor_id: cmcDoctorId,
        });
      }

      if (foreignerAppointment.id) {
        await patchPatientStatus(foreignerAppointment.id, foreignerAppointment.doctorId, false);
        await logout();
      }

      if (isDoctor && [86, 111, 127].includes(user.doctorId)) {
        const appointmentId = getUrlParameter('roomId').match(/\d+/);
        await patchDoctorStatus(parseInt(appointmentId[0]), false);
      }

      await leaveAgoraScreen();
      await leaveAgoraRTC();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isDoctor) return;

    const remoteCount = remoteUsers.filter((remoteUser) => String(remoteUser.uid) !== '111111').length;

    if (remoteCount) {
      if (sid) return;
      const roomId = getUrlParameter('roomId');
      startRecording(roomId, resourceId);
    }

    // eslint-disable-next-line
  }, [isDoctor, remoteUsers]);

  useEffect(() => {
    if (!isDoctor) return;

    if (audioUploaded) {
      // doctorLogout();
    }

    // eslint-disable-next-line
  }, [audioUploaded, isDoctor]);

  useEffect(() => {
    const roomId = getUrlParameter('roomId');
    // if (!isDoctor) return;

    getRecordingInfo(roomId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cmcDoctorId = parseInt(getUrlParameter('orddrid'));
    if (!cmcDoctorId) return;
    // getCMCDoctorInfo(cmcDoctorId);
    getCMCTargetAppointment(cmcDoctorId, parseInt(getUrlParameter('orddeptcd')));
    checkConnectionStatus(cmcDoctorId);
  }, []);

  useEffect(() => {
    if (isDoctor) return;
    const turnInterval = setInterval(() => {
      getGuardianTurn();
    }, 10 * 1000);

    if (remoteUsers.length) {
      clearInterval(turnInterval);
    }

    return () => clearInterval(turnInterval);
  }, [isDoctor, remoteUsers, turn]);

  useEffect(() => {}, [videoQuality]);

  useEffect(() => {
    const appointmentId = getUrlParameter('roomId').match(/\d+/);
    patchDoctorStatus(parseInt(appointmentId[0]), true);
  }, []);

  if ((!checkUrlRef.current && isDoctor && !connectionStatus) || !agoraJoinState) return <Intermisson />;

  return (
    <Wrapper>
      <TimerBox>
        <Timer>
          {isGuardian && isDoctor && rtcUsers.length ? (
            <>
              <span />
              <p>
                {'면회 시간 '}
                {result || '-:--'}
              </p>
            </>
          ) : (
            isGuardian && <p>{guardianTurnMessage}</p>
          )}
        </Timer>
      </TimerBox>

      {isSharing ? (
        <GroupCallWithShare
          handleEndBtnClick={handleEndBtnClick}
          handlePatientJoin={handlePatientJoin}
          handleSelectMenu={handleSelectMenu}
          handleShareBtnClick={handleShareBtnClick}
          showSelectMenu={showSelectMenu}
        />
      ) : (
        <>
          {isMobile ? (
            <MobileGroupCall
              handleEndBtnClick={handleEndBtnClick}
              // handlePatientJoin={handlePatientJoin}
              // joinMessage={joinMessage}
              // handleShareBtnClick={handleShareBtnClick}
            />
          ) : (
            <GroupCall
              handleEndBtnClick={handleEndBtnClick}
              handleSelectMenu={handleSelectMenu}
              handleShareBtnClick={handleShareBtnClick}
              showSelectMenu={showSelectMenu}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default GroupCallMain;

const Wrapper = styled(FullScreen)`
  background-color: var(--navy-900);

  @media only screen and (max-width: 599px) {
    background-color: #424242;
  }

  @media (orientation: landscape) {
    background-color: #424242;
  }
`;

const TimerBox = styled.div`
  ${mixins.flexCenter};
  position: fixed;
  top: 0;
  height: 48px;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.6);

  @media (orientation: landscape) {
    height: 38px;
  }
`;

const Timer = styled.div`
  ${mixins.flexCenter};
  width: 100%;
  margin: 0;
  z-index: 5;
  span {
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 3px;
    background-color: red;
  }
  p {
    margin: 0;
    font-size: 1em;
    color: white;
  }
`;
