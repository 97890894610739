import { useEffect, useState } from 'react';

const useDetectKeyboard = (minKeyboardHeight = 300) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const listener = () => {
      const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height;
      if (isKeyboardOpen !== newState) {
        setIsKeyboardOpen(newState);
      }
    };
    window.visualViewport.addEventListener('resize', listener);
    return () => {
      window.visualViewport.removeEventListener('resize', listener);
    };
  }, []);

  return isKeyboardOpen;
};

export default useDetectKeyboard;
