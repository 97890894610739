import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { flexRow } from 'styles/mixins';

interface ErrorMessageProps extends HTMLAttributes<HTMLDivElement> {}

function ErrorMessage({ children }: ErrorMessageProps) {
  return (
    <Container>
      <ErrorIcon alt="error" src="/icons/ic-error.svg" />
      {children}
    </Container>
  );
}

export default ErrorMessage;

const ErrorIcon = styled.img`
  margin-right: 6px;
`;

const Container = styled.div`
  ${flexRow}
  align-items: center;
  margin: 8px 0 11px;
  padding: 3px 0;
  background-color: var(--red-100);
  color: var(--red-350);
  font-family: NotoSansRegular;
  font-size: 12px;
  line-height: 17.76px;
  font-weight: 400;
`;
