import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { v4 as getUuid } from 'uuid';
import { axiosClient } from 'libs/axios';
import { getSession, setSession } from 'utils';

function SelfAuthentication() {
  const history = useHistory();
  const formRef = useRef(null);

  useEffect(() => {
    setSession('uuid', getUuid(), 10);

    const getNiceApiToken = async () => {
      try {
        const { data: accessToken } = await axiosClient.get('/user/niceapi/token/');
        const uuid = getSession('uuid');

        const { data: niceApiResult } = await axiosClient.post('/user/niceapi/encrypt-data/', {
          access_token: accessToken,
          device_id: uuid,
        });
        formRef.current.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb';
        formRef.current.token_version_id.value = niceApiResult.token_version_id;
        formRef.current.enc_data.value = niceApiResult.enc_data;
        formRef.current.integrity_value.value = niceApiResult.integrity_value;
        formRef.current.submit();
      } catch (e) {
        alert('다시 한 번 시도해주세요');
        history.push('/login');
        console.error('authentication error:', e);
      }
    };
    getNiceApiToken();
  }, []);

  return (
    <form id="form" name="form" ref={formRef}>
      <input id="m" name="m" type="hidden" value="service" />
      <input id="token_version_id" name="token_version_id" type="hidden" value="" />
      <input id="enc_data" name="enc_data" type="hidden" value="" />
      <input id="integrity_value" name="integrity_value" type="hidden" value="" />
    </form>
  );
}

export default SelfAuthentication;
