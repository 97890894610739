import React from 'react';
import styled, { css } from 'styled-components';

import * as mixins from 'styles/mixins';

interface ScreenOverlayStyleProps {
  fillBlack?: boolean;
  hasBorder?: boolean;
  isActive: boolean;
}

interface AvatarStyleProps {
  url?: string;
}

interface ScreenOverlayProps extends ScreenOverlayStyleProps, AvatarStyleProps {}

function ScreenOverlay({ fillBlack, hasBorder = true, url, isActive }: ScreenOverlayProps) {
  return (
    <ScreenOverlayView fillBlack={fillBlack} hasBorder={hasBorder} isActive={isActive}>
      <Avatar url={url} />
    </ScreenOverlayView>
  );
}

export default ScreenOverlay;

const ScreenOverlayView = styled.div<ScreenOverlayStyleProps>`
  ${mixins.flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
  z-index: 1;

  ${({ fillBlack }) =>
    fillBlack
      ? css`
          background-color: black;
        `
      : ''};

  ${({ hasBorder }) =>
    hasBorder
      ? css`
          border-radius: 10px;
        `
      : ''}

  ${({ isActive }) =>
    !isActive &&
    css`
      display: none;
    `}
`;

const Avatar = styled.div<AvatarStyleProps>`
  max-width: 126px;
  max-height: 126px;
  width: 100%;
  height: 100%;
  border-radius: 63px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${({ url }) => url || '/icons/icon-avatar.svg'});
`;
