import { IAgoraRTCRemoteUser, ICameraVideoTrack, ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';
import { produce } from 'immer';

import { FacingMode, initialState, State } from './state';
import { VirtualBackgroundMode } from './types';

export type Action =
  | { type: 'INITIALIZE' }
  | { type: 'SET_AGORA_JOIN_STATE'; payload: boolean }
  | {
      type: 'SET_LOCAL_TRACKS';
      payload: {
        localAudioTrack: ILocalAudioTrack;
        localVideoTrack: ICameraVideoTrack;
      };
    }
  | {
      type: 'SET_LOCAL_VIDEO_TRACK';
      payload: {
        facingMode: FacingMode;
        localVideoTrack: ICameraVideoTrack;
      };
    }
  | { type: 'SET_REMOTE_USERS'; payload: IAgoraRTCRemoteUser[] }
  | { type: 'SET_SCREEN_TRACK'; payload: ILocalVideoTrack | null }
  | { type: 'SET_SPEAKER'; payload: string }
  | {
      type: 'SET_VIRTUAL_BACKGROUND_ENABLED';
      payload: VirtualBackgroundMode;
    }
  | { type: 'TOGGLE_AUDIO'; payload: boolean }
  | { type: 'TOGGLE_VIDEO'; payload: boolean }
  | { type: 'SET_SOCKET_JOIN_STATE'; payload: boolean }
  | { type: 'SET_REMOTE_FACING_MODE'; payload: FacingMode }
  | { type: 'SET_CONNECTION_STATUS'; payload: boolean }
  | { type: 'SET_VIDEO_QUALITY'; payload: string };

export default function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case 'INITIALIZE':
      return initialState;

    case 'SET_AGORA_JOIN_STATE':
      return produce(prevState, (draft) => {
        draft.agoraJoinState = action.payload;
      });

    case 'SET_LOCAL_TRACKS':
      return produce(prevState, (draft) => {
        const { localAudioTrack, localVideoTrack } = action.payload;

        draft.localAudioTrack = localAudioTrack;
        draft.localVideoTrack = localVideoTrack;
      });

    case 'SET_LOCAL_VIDEO_TRACK':
      return produce(prevState, (draft) => {
        const { facingMode, localVideoTrack } = action.payload;

        draft.localVideoTrack = localVideoTrack;
        draft.facingMode = facingMode;
      });

    case 'SET_REMOTE_USERS':
      return produce(prevState, (draft) => {
        draft.remoteUsers = action.payload;
      });

    case 'SET_SCREEN_TRACK':
      return produce(prevState, (draft) => {
        draft.localScreenTrack = action.payload;
      });

    case 'SET_SPEAKER':
      return produce(prevState, (draft) => {
        draft.speaker = action.payload;
      });

    case 'SET_VIRTUAL_BACKGROUND_ENABLED':
      return produce(prevState, (draft) => {
        draft.virtualBackgroundMode = action.payload;
      });

    case 'TOGGLE_AUDIO':
      return produce(prevState, (draft) => {
        draft.audioMuted = action.payload;
      });

    case 'TOGGLE_VIDEO':
      return produce(prevState, (draft) => {
        draft.videoMuted = action.payload;
      });

    case 'SET_SOCKET_JOIN_STATE':
      return produce(prevState, (draft) => {
        draft.socketJoinState = action.payload;
      });

    case 'SET_REMOTE_FACING_MODE':
      return produce(prevState, (draft) => {
        draft.remoteFacingMode = action.payload;
      });

    case 'SET_CONNECTION_STATUS':
      return produce(prevState, (draft) => {
        draft.connectionStatus = action.payload;
      });

    case 'SET_VIDEO_QUALITY':
      return produce(prevState, (draft) => {
        draft.videoQuality = action.payload;
      });

    default:
      return prevState;
  }
}
