import ReactModal from 'react-modal';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input, useTextInput } from 'components/atoms/Input';

import * as fonts from 'styles/fonts';
import { media } from 'utils';

interface PatientIdModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEnter: (patientId: number) => void;
}

function PatientIdModal({ isOpen, onClose, onEnter }: PatientIdModalProps) {
  const [patientId, patientIdInput] = useTextInput({
    id: 'patientIdInput',
    placeholder: '환자번호를 입력해주세요',
  });

  const preventEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <ReactModal shouldCloseOnOverlayClick isOpen={isOpen} style={customStyles} onRequestClose={() => onClose()}>
      <FormContainer onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => preventEnter(e)}>
        <InputLabel htmlFor="usernameInput">환자번호</InputLabel>
        {patientIdInput}

        <EnterBtn primary size="mid" onClick={() => onEnter(patientId)}>
          면회실 입장
        </EnterBtn>
      </FormContainer>
    </ReactModal>
  );
}

export default PatientIdModal;

const FormContainer = styled.form`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: var(--white);

  ${Input} {
    margin-bottom: 24px;
    margin-top: 8px;
  }

  ${media.main} {
    max-width: 500px;
    border: solid 1px #dee2f2;
    border-radius: 4px;
    padding: 0 48px;
  }
`;

const InputLabel = styled.label`
  ${fonts.midBig};
  ${fonts.heavy};
  height: 12px;
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
  &:first-of-type {
    margin-top: 38px;
  }
`;

const EnterBtn = styled(Button)`
  ${fonts.normal};
  ${fonts.demi};
  color: var(--white);
  width: 100%;
  margin-bottom: 32px;
`;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
};
