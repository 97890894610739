/** Types */
export type State = {
  audioUploaded: boolean;
  channelId: string;
  error: boolean;
  isSharing: boolean;
  joinMessage: string;
  joinedUsers: string[];
  loading: boolean;
  resourceId: string;
  sid: string;
  registeredPatient: boolean;
};

/** State */
export const initialState: State = {
  audioUploaded: false,
  channelId: '',
  error: false,
  loading: false,
  isSharing: false,
  joinMessage: '',
  joinedUsers: [],
  resourceId: '',
  sid: '',
  registeredPatient: true,
};
