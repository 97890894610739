import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import { PrivateRoute } from 'components/atoms/PrivateRoute';
import { GroupCallMain } from '../GroupCallMain';

function GroupCallScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact component={GroupCallMain} path={path} />
    </Switch>
  );
}

export default GroupCallScreen;
