import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getUrlParameter } from 'utils';

const useSocket = () => {
  const [connected, setConnected] = useState<boolean>(false);
  const [socketError, setSocketError] = useState<string>('');

  const socketRef = useRef<Socket | undefined>(undefined);

  const emitSocketEvent = (eventName: string, eventParam?: any) => {
    const roomId = getUrlParameter('roomId');

    socketRef.current.emit(eventName, eventParam || roomId);
  };

  const listenSocketEvent = (eventName: string, listener: (...args: any[]) => void) => {
    socketRef.current.on(eventName, listener);
  };

  const disconnectSocket = () => {
    socketRef.current.disconnect();
  };

  useEffect(() => {
    if (connected) return;

    const token = localStorage.getItem('token');
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ['websocket'],
      auth: { token },
    });

    socketRef.current = socket;

    socket.on('connect', () => {
      setConnected(socket.connected);
    });

    socket.on('disconnect', () => {
      setConnected(socket.connected);
    });

    socket.on('error', (msg: { msg: string }) => {
      setSocketError(msg.msg);
      console.error(msg.msg);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  return { socket: socketRef.current, connected, socketError, emitSocketEvent, listenSocketEvent, disconnectSocket };
};

export default useSocket;
