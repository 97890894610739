export const validatePassword = (password: string) => {
  const passwordRegex = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@+.\-_]{8,}$/;
  const result = passwordRegex.test(password);
  return result;
};

export const validateUsername = (username: string) => {
  const usernameRegex = /^[a-z0-9]*$/;
  const result = usernameRegex.test(username);
  return result;
};

export const validatePhone = (phone: string) => {
  const phoneRegex = /^010[0-9]{3,4}[0-9]{4}$/;
  const result = phoneRegex.test(phone);
  return result;
};
