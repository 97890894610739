import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { mediaMax } from 'utils';
import { formatPages } from 'utils/format';
import * as mixins from 'styles/mixins';

interface PaginationProps {
  count: number;
  dataPerPage: number;
  displayedListLimit: number;
  getList: (page: number) => void;
}

const usePagination = ({ count, dataPerPage, displayedListLimit, getList }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedList, setDisplayedList] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const pages = useRef<number[]>([]);
  const lastPage = useRef(0);

  const getDisplayedList = () => {
    const distance = displayedListLimit / 2;
    pages.current = formatPages(count, dataPerPage);
    lastPage.current = pages.current.length;

    switch (true) {
      case lastPage.current <= displayedListLimit:
        setDisplayedList(pages.current);
        break;
      case currentPage <= distance:
        setDisplayedList(pages.current.slice(0, displayedListLimit));
        break;
      case lastPage.current - currentPage <= distance:
        setDisplayedList(pages.current.slice(lastPage.current - displayedListLimit, lastPage.current));
        break;
      default:
        setDisplayedList(pages.current.slice(currentPage - distance, currentPage + distance));
    }

    setLoading(false);
  };

  const handleGetPage = (page: number) => {
    if (page === currentPage) return;
    setLoading(true);
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage === lastPage.current) return;
    setLoading(true);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage === 1) return;
    setLoading(true);
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    getList(currentPage);
    getDisplayedList();
  }, [currentPage, count]);

  const pagination = (
    <Container>
      <PaginationBtn isImage active={false} onClick={() => handlePrevPage()}>
        <PaginationIcon>
          <img alt="prevPage" src="/icons/ic-page-arrow-prev.svg" />
        </PaginationIcon>
      </PaginationBtn>
      <DisplayedList>
        {displayedList.map((page: number, index: number) => (
          <PaginationBtn
            key={`pagination-${index}`}
            active={!loading && currentPage === page}
            isImage={false}
            onClick={() => handleGetPage(page)}
          >
            {page}
          </PaginationBtn>
        ))}
      </DisplayedList>
      <PaginationBtn isImage active={false} onClick={() => handleNextPage()}>
        <PaginationIcon>
          <img alt="nextPage" src="/icons/ic-page-arrow-next.svg" />
        </PaginationIcon>
      </PaginationBtn>
    </Container>
  );

  return { pagination };
};

export default usePagination;

const color = {
  activeText: '#399ba7',
  activeBorder: '#6bc7d3',
  inactiveText: '#909090',
  inactiveBorder: '#d8d8d8',
};

type PaginationBtnProps = {
  active: boolean;
  isImage?: boolean;
};

const Container = styled.div`
  ${mixins.flexCenter}
`;

const PaginationBtn = styled.button<PaginationBtnProps>`
  ${(props) => `
    ${mixins.flexCenter}
    width: 40px;
    height: 40px; 
    padding: 0;
    margin: ${props.isImage ? '0 20px' : '0 5px'};
    border: 1px solid ${props.active ? color.activeBorder : color.inactiveBorder};
    border-radius: 5px;
    background-color: white;
    color: ${props.active ? color.activeText : color.inactiveText};
    &:hover {
      color: ${color.activeText}
      border: 1px solid ${color.activeBorder}
    }
    cursor: pointer;
    `}
`;

const PaginationIcon = styled.div`
  width: 21.2px;
  height: 21.2px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const DisplayedList = styled.div`
  ${mixins.flexCenter}
  ${mediaMax.mobileBig} {
    display: none;
  }
`;
