import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface InputLabelProps extends HTMLAttributes<HTMLLabelElement> {
  error?: boolean;
  htmlFor: string;
}

function InputLabel({ children, error = false, htmlFor }: InputLabelProps) {
  return (
    <Label error={error} htmlFor={htmlFor}>
      {children}
    </Label>
  );
}

export default InputLabel;

const Label = styled.label<{ error: boolean }>`
  display: block;
  margin-bottom: 8px;
  color: #4d86bf;
  font-size: 14px;
  font-weight: 500;
  font-family: NotoSansMedium;
  line-height: 20.72px;

  ${({ error }) =>
    error &&
    css`
      color: var(--red-350);
    `}
`;
