import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { RegisteredPatient, useVisit } from 'libs/VisitContext';
import { formatCodeToNumber } from 'utils';
import { flexCenter } from 'styles/mixins';

export default function PatientTableRow() {
  const { patientList, selectedPatient, setSelectedPatient } = useVisit();
  const [displayList, setDisplayList] = useState<RegisteredPatient[]>(patientList);

  useEffect(() => {
    setDisplayList(patientList);
  }, [patientList]);

  return (
    <>
      {displayList.map((patient, idx) => {
        const { id, guardianName, guardianPhone, patientAge, patientGender, patientName, ssn, ward } = patient;
        const isEqual = selectedPatient.patientId === id;
        return (
          <TableRow
            key={`round-${idx}`}
            active={isEqual}
            onClick={() => setSelectedPatient({ patientId: id, patientName, ssn })}
          >
            <li>{ssn}</li>
            <li>
              {` ${patientName} (${patientAge}/${patientGender})
              ${ward ?? ''}`}
            </li>
            <GuardianInfoWrap>
              <span>{guardianName}</span>
              <span>{formatCodeToNumber(guardianPhone)}</span>
            </GuardianInfoWrap>
          </TableRow>
        );
      })}
    </>
  );
}

const TableRow = styled.ul<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 87px;
  padding: 13px 0;
  margin: 0 0 8px;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;

  li {
    ${flexCenter};
    position: relative;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-line;
    color: #3e3e3e;
    text-align: center;
  }

  li:nth-child(1) {
    flex: 1;
  }

  li:nth-child(2) {
    flex: 1.44;
  }

  li:nth-child(3) {
    flex: 1.5;
  }

  li:nth-child(1):after,
  li:nth-child(2):after {
    content: '';
    height: 34px;
    width: 1px;
    position: absolute;
    right: 0;
    background-color: #d1d1d1;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #d9ecff;

      li {
        color: #036ad1;
      }

      li:nth-child(1):after,
      li:nth-child(2):after {
        background-color: #036ad1;
        opacity: 0.3;
      }
    `}
`;

const GuardianInfoWrap = styled.li`
  display: flex;
  flex-direction: column;
`;
