import { produce } from 'immer';

import {
  CMCAppointment,
  ForeignerAppointment,
  GuardianAuthInfo,
  initialState,
  IScreeningAppointment,
  PatientStatus,
  PatientVisitList,
  State,
  VoidocUser,
} from './state';
import { LoginInfo } from './types';

export type Action =
  | { type: 'INITIALIZE' }
  | { type: 'RESET_INITAILIZE_STATUS' }
  | { type: 'CHANGE_PASSWORD_SUCCESS' }
  | { type: 'CHANGE_PASSWORD_FAIL' }
  | { type: 'DOCTOR_LOGIN_SUCCESS'; payload: VoidocUser }
  | { type: 'DUPLICATE_PHONE_ERROR' }
  | { type: 'FIND_USER_NAME_SUCCESS'; payload: string }
  | { type: 'FIND_PASSWORD_SUCCESS' }
  | { type: 'FIND_PASSWORD_FAIL'; payload: string }
  | { type: 'FOREIGNER_LOGIN_SUCCESS'; payload: VoidocUser }
  | { type: 'LOGIN'; payload: LoginInfo }
  | { type: 'LOGIN_FAIL'; payload: string }
  | { type: 'LOGOUT' }
  | { type: 'GET_AGORA_TOKEN'; payload: { agoraToken: string; uid: string } }
  | { type: 'GET_APPOINTMENT_LIST_SUCCESS'; payload: { appointmentList: CMCAppointment[] } }
  | { type: 'GET_CMC_DOCTOR_INFO_SUCCESS'; payload: { doctorName: string; patientName: string } }
  | { type: 'GET_COMPLETE_APPOINTMENT_SUCCESS'; payload: { appointmentList: IScreeningAppointment[] } }
  | { type: 'GET_FOREIGNER_APPOINTMENT_SUCCESS'; payload: { appointment: ForeignerAppointment } }
  | { type: 'GET_PATIENT_STATUS_SUCCESS'; payload: PatientStatus }
  | { type: 'GET_PATIENT_VISIT_LIST_SUCCESS'; payload: PatientVisitList[] }
  | { type: 'GET_RTC_TOKEN'; payload: string }
  | { type: 'GET_RTC_TOKEN_SUCCESS'; payload: string }
  | { type: 'GET_SCREENING_APPOINTMENT_SUCCESS'; payload: { appointmentList: IScreeningAppointment[] } }
  | { type: 'GUARDIAN_LOGIN_SUCCESS' }
  | { type: 'GUARDIAN_SIGNUP' }
  | { type: 'GET_VERIFICATION_CODE_SUCCESS' }
  | { type: 'PHONE_VERIFY_SUCCESS'; payload: string }
  | { type: 'PHONE_VERIFY_FAIL' }
  | { type: 'GUARDIAN_SIGNUP_FAIL'; payload: string }
  | { type: 'GUARDIAN_SIGNUP_SUCCESS' }
  | { type: 'GUARDIAN_VERIFY_SUCCESS'; payload: GuardianAuthInfo }
  | { type: 'PATIENT_LOGIN_SUCCESS'; payload: VoidocUser }
  | { type: 'RESET_RTC_TOKEN' }
  | { type: 'SET_LOADING' }
  | { type: 'SET_UNIQUE_PHONE' };

export default function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case 'INITIALIZE':
      return initialState;

    case 'CHANGE_PASSWORD_SUCCESS': {
      return produce(prevState, (draft) => {
        draft.changePasswordSuccess = true;
      });
    }

    case 'CHANGE_PASSWORD_FAIL': {
      return produce(prevState, (draft) => {
        draft.error = true;
      });
    }

    case 'DUPLICATE_PHONE_ERROR': {
      return produce(prevState, (draft) => {
        draft.error = true;
      });
    }

    case 'FOREIGNER_LOGIN_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.isAuthenticated = true;
        draft.isDoctor = false;
        draft.isGuardian = false;
        draft.isForeigner = true;
        draft.user = action.payload;
      });

    case 'RESET_INITAILIZE_STATUS': {
      return produce(prevState, (draft) => {
        draft.changePasswordSuccess = false;
        draft.error = false;
        draft.guardianSignupErrorMsg = '';
      });
    }

    case 'SET_LOADING':
      return produce(prevState, (draft) => {
        draft.loading = false;
      });

    case 'GET_COMPLETE_APPOINTMENT_SUCCESS':
      return produce(prevState, (draft) => {
        draft.completeAppointment = action.payload.appointmentList;
      });

    case 'GET_FOREIGNER_APPOINTMENT_SUCCESS':
      return produce(prevState, (draft) => {
        draft.foreignerAppointment = action.payload.appointment;
      });

    case 'GET_SCREENING_APPOINTMENT_SUCCESS':
      return produce(prevState, (draft) => {
        draft.screeningAppointment = action.payload.appointmentList;
      });

    case 'GET_VERIFICATION_CODE_SUCCESS': {
      return produce(prevState, (draft) => {
        draft.error = false;
        draft.findInfo.phoneVerified = false;
        draft.findInfo.codeSent = true;
        draft.loading = true;
      });
    }

    case 'PHONE_VERIFY_SUCCESS': {
      return produce(prevState, (draft) => {
        draft.error = false;
        draft.findInfo.phoneVerified = true;
        draft.findInfo.phoneNumber = action.payload;
      });
    }

    case 'PHONE_VERIFY_FAIL': {
      return produce(prevState, (draft) => {
        draft.error = true;
      });
    }

    case 'FIND_USER_NAME_SUCCESS': {
      return produce(prevState, (draft) => {
        draft.findInfo.username = action.payload;
      });
    }

    case 'FIND_PASSWORD_SUCCESS': {
      return produce(prevState, (draft) => {
        draft.findInfo.findPasswordSuccess = true;
      });
    }

    case 'FIND_PASSWORD_FAIL': {
      return produce(prevState, (draft) => {
        draft.findInfo.findPasswordFailMsg = action.payload;
      });
    }

    case 'DOCTOR_LOGIN_SUCCESS':
      return produce(prevState, (draft) => {
        const { doctorId, fullname, username } = action.payload;
        draft.loading = false;
        draft.isAuthenticated = true;
        draft.isDoctor = true;
        draft.user.doctorId = doctorId;
        draft.user.fullname = fullname;
        draft.user.username = username;
      });

    case 'GET_AGORA_TOKEN':
      return produce(prevState, (draft) => {
        draft.agoraToken = action.payload.agoraToken;
        draft.user.uid = action.payload.uid;
      });

    case 'GET_APPOINTMENT_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.appointmentList = action.payload.appointmentList;
      });

    case 'GET_CMC_DOCTOR_INFO_SUCCESS':
      return produce(prevState, (draft) => {
        draft.isDoctor = true;
        draft.isCMCDoctor = true;
        draft.user.fullname = action.payload.doctorName;
        draft.patientName = `${action.payload.patientName} 환자`;
      });

    case 'GET_PATIENT_STATUS_SUCCESS':
      return produce(prevState, (draft) => {
        draft.patientStatus = action.payload;
      });

    case 'GET_PATIENT_VISIT_LIST_SUCCESS':
      return produce(prevState, (draft) => {
        draft.patientVisitList = action.payload;
      });

    case 'GET_RTC_TOKEN_SUCCESS':
      return produce(prevState, (draft) => {
        draft.rtcToken = action.payload;
      });

    case 'GUARDIAN_LOGIN_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.isAuthenticated = true;
        draft.isDoctor = false;
        draft.isGuardian = true;
      });

    case 'GUARDIAN_SIGNUP':
      return produce(prevState, (draft) => {
        draft.loading = true;
        draft.error = false;
      });

    case 'GUARDIAN_SIGNUP_FAIL':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.error = true;
        draft.guardianSignupErrorMsg = action.payload;
      });

    case 'GUARDIAN_SIGNUP_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.guardianSignupSuccess = true;
      });

    case 'GUARDIAN_VERIFY_SUCCESS':
      return produce(prevState, (draft) => {
        const { firstName, gender, dateOfBirth, phone } = action.payload;
        draft.guardianAuthInfo.firstName = firstName;
        draft.guardianAuthInfo.gender = gender;
        draft.guardianAuthInfo.dateOfBirth = dateOfBirth;
        draft.guardianAuthInfo.phone = phone;
        draft.guardianVerifySuccess = true;
      });

    case 'LOGIN':
      return produce(prevState, (draft) => {
        draft.loading = true;
      });

    case 'LOGIN_FAIL':
      return produce(prevState, (draft) => {
        localStorage.removeItem('token');
        draft.loading = false;
        draft.error = true;
        draft.loginMsg = action.payload;
      });

    case 'LOGOUT':
      return { ...initialState, loading: false };

    case 'PATIENT_LOGIN_SUCCESS':
      return produce(prevState, (draft) => {
        draft.loading = false;
        draft.isPatient = true;
        draft.user = action.payload;
      });

    case 'RESET_RTC_TOKEN':
      return produce(prevState, (draft) => {
        draft.rtcToken = '';
      });

    case 'SET_UNIQUE_PHONE':
      return produce(prevState, (draft) => {
        draft.isUniquePhone = true;
      });

    default:
      return prevState;
  }
}
