import { MutableRefObject, useEffect } from 'react';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

const useVolumeControl = (remoteUsers: IAgoraRTCRemoteUser[], remoteVideoRef: MutableRefObject<string[]>) => {
  const connectVideoWithGain = (videoElement: HTMLVideoElement) => {
    const audioContext = new AudioContext();
    const videoSource = audioContext.createMediaElementSource(videoElement);
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 2;
    videoSource.connect(gainNode);
    gainNode.connect(audioContext.destination);
  };

  useEffect(() => {
    const remoteVideoList = document.getElementsByTagName('video');
    for (let i = 0; i < remoteVideoList.length; i++) {
      if (remoteVideoList[i].className === 'remoteVideo') {
        const remoteVideoElement = remoteVideoList[i];
        remoteVideoElement.volume = 1.0;
        if (remoteVideoRef.current.includes(remoteVideoElement.id)) {
          remoteVideoRef.current.push(remoteVideoElement.id);
          connectVideoWithGain(remoteVideoElement);
        }
      }
    }

    // eslint-disable-next-line
  }, [remoteUsers]);
};

export default useVolumeControl;
