import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { KakaoLoginBtn } from 'components/atoms/KakaoLoginBtn';
import { Screen } from 'components/templates/Screen';

import { media } from 'utils';
import { normal, demi } from 'styles/fonts';
import { flexCenter, flexColumn } from 'styles/mixins';

export default function EntryPage() {
  const history = useHistory();

  const handleLoginBtnClick = () => {
    history.push('/login');
  };

  return (
    <Container>
      <Logo alt="hospital-logo" src="/icons/eun-logo.png" />
      <Explanation>은평성모병원 비대면 면회 서비스</Explanation>
      <KakaoMessage>보호자는 면회를 위해 카카오로 인증해주세요</KakaoMessage>
      <KakaoLoginBtn />
      <LoginBtn primary size="mid" onClick={() => handleLoginBtnClick()}>
        로그인
      </LoginBtn>
    </Container>
  );
}

const LoginBtn = styled(Button)`
  ${normal};
  ${demi};
  font-size: 20px;
  width: 100%;
  height: 56px;
  background-color: #036ad1;
  color: var(--white);
  &:hover {
    background-color: rgba(3, 106, 209, 0.5);
  }
`;

const Container = styled(Screen)`
  ${flexColumn}
  ${flexCenter}
  ${media.main} {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  margin-bottom: 21.1px;
`;

const Explanation = styled.h1`
  margin: 0;
  margin-bottom: 80px;
  color: var(--gray-400);
  font-size: 17px;
`;

const KakaoMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
  font-size: 13px;
`;
