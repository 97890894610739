import { useEffect, useState } from 'react';

const useTimer = (timeValue: number, start: boolean) => {
  const [remainTime, setRemainTime] = useState<number>(timeValue);
  const [result, setResult] = useState<string>('');
  const [isNegative, setIsNegative] = useState<boolean>(false);

  useEffect(() => {
    if (!start) return;
    const min = timeValue < 600 ? parseInt(`${remainTime / 60}`) : `0${parseInt(`${remainTime / 60}`)}`;
    const sec = remainTime % 60 > 9 ? remainTime % 60 : `0${remainTime % 60}`;

    const timer = setInterval(() => {
      if (remainTime === 1) setIsNegative(true);

      if (!isNegative) {
        setRemainTime(remainTime - 1);
        setResult(`${min}:${sec}`);
      } else {
        setRemainTime(remainTime + 1);
        setResult(`-${min}:${sec}`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [remainTime, start]);

  return { result };
};

export default useTimer;
