import { Switch, useRouteMatch } from 'react-router-dom';

import { DoctorRoute } from 'components/atoms/DoctorRoute';
import { PatientList } from 'components/pages/PatientList';

export default function PatientListScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <DoctorRoute exact component={PatientList} path={path} />
    </Switch>
  );
}
