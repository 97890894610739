import styled from 'styled-components';

import { ScheduleTableHeader } from 'components/molecules/ScheduleTableHeader';
import { ScheduleTableRow } from 'components/molecules/ScheduleTableRow';

import { mediaMax } from 'utils';

export default function ScheduleTable() {
  return (
    <Container>
      <ScheduleTableHeader />
      <ScheduleTableRow />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 35px;

  ${mediaMax.mobileBig} {
    margin-bottom: 20px;
  }
`;
