import styled from 'styled-components';

import {
  CallButton,
  EndButton,
  MuteButton,
  RotateCameraButton,
  StartVideoButton,
  StopVideoButton,
  UnmuteButton,
} from 'components/atoms/CallButtons';

import { useAgora } from 'libs/AgoraContext';
import { font } from 'styles/fonts';
import { flex } from 'styles/flex';

type UtilityButtonsProps = {
  onEndBtnClick: Function;
};

function MobileUtilityButtons({ onEndBtnClick }: UtilityButtonsProps) {
  const {
    audioMuted,
    rotateCamera: handleRotateBtnClick,
    toggleAudio: handleAudioBtnClick,
    toggleVideo: handleVideoBtnClick,
    videoMuted,
  } = useAgora();

  return (
    <Wrapper>
      <BtnWrap>
        <RotateCameraButton onClick={() => handleRotateBtnClick()} />
        <BtnText>카메라 전환</BtnText>
      </BtnWrap>
      {videoMuted ? (
        <BtnWrap>
          <StartVideoButton onClick={() => handleVideoBtnClick()} />
          <BtnText>비디오 시작</BtnText>
        </BtnWrap>
      ) : (
        <BtnWrap>
          <StopVideoButton onClick={() => handleVideoBtnClick()} />
          <BtnText>비디오 중지</BtnText>
        </BtnWrap>
      )}
      {audioMuted ? (
        <BtnWrap>
          <UnmuteButton onClick={() => handleAudioBtnClick()} />
          <BtnText>음소거 해제</BtnText>
        </BtnWrap>
      ) : (
        <BtnWrap>
          <MuteButton audioMuted={audioMuted} onClick={() => handleAudioBtnClick()} />
          <BtnText>음소거</BtnText>
        </BtnWrap>
      )}
      <BtnWrap>
        <EndButton onClick={() => onEndBtnClick()} />
        <BtnText>연결 종료</BtnText>
      </BtnWrap>
    </Wrapper>
  );
}

export default MobileUtilityButtons;

const Wrapper = styled.div`
  ${flex('center', '')};
  ${font('NotoSansRegular', 16, 400, 22)};
  gap: 40px;

  ${CallButton} {
    width: 74px;
    height: 74px;

    @media (orientation: landscape) {
      width: 48px;
      height: 48px;
      background-size: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    ${CallButton} {
      width: 66px;
      height: 66px;

      @media (orientation: landscape) {
        width: 48px;
        height: 48px;
      }
    }
  }

  @media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: 13px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 13px;
    gap: 25px;

    ${CallButton} {
      width: 48px;
      height: 48px;
    }
  }
`;

const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BtnText = styled.div`
  margin-top: 10px;
  text-align: center;
  color: var(--white);
`;
