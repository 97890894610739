import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Spinner } from 'components/atoms/Spinner';
import { useTextInput } from 'components/atoms/Input';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { InputLabel } from 'components/atoms/InputLabel';

import { useAuth } from 'libs/AuthContext';
import { formatNumberToCode } from 'utils';
import { flexCenter } from 'styles/mixins';

function PhoneVerification() {
  const {
    getVerificationCode,
    verificationPhone,
    findInfo: { codeSent, phoneVerified },
    error,
    loading,
  } = useAuth();

  const [phoneFormatError, setPhoneFormatError] = useState(false);

  const [phoneNumber, phoneNumberInput] = useTextInput({
    id: 'phoneInput',
    placeholder: '전화번호 입력',
  });

  const [verificationCode, verificationCodeInput] = useTextInput({
    id: 'verificationCodeInput',
    placeholder: '인증번호를 입력해주세요',
  });

  const handleCodeBtnClick = () => {
    const regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

    if (!regExp.test(phoneNumber)) return setPhoneFormatError(true);

    if (phoneFormatError) setPhoneFormatError(false);

    getVerificationCode(formatNumberToCode(82, phoneNumber));
  };

  const handleVerityBtnClick = () => {
    verificationPhone(verificationCode, formatNumberToCode(82, phoneNumber));
  };

  const errorMessage = useMemo(() => {
    if (phoneFormatError) {
      return <ErrorMessage>전화번호를 다시 확인해주세요.</ErrorMessage>;
    }

    if (codeSent) {
      return <AlertMessage>인증번호가 발송 되었습니다.</AlertMessage>;
    }
  }, [phoneFormatError, codeSent]);

  return (
    <>
      {!loading && (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      )}
      <InputWrap error={codeSent || phoneFormatError}>
        <InputLabel htmlFor="phoneInput">전화번호</InputLabel>
        <BtnWrap>
          {phoneNumberInput}
          <GetCodeBtn disabled={!phoneNumber} type="button" onClick={() => handleCodeBtnClick()}>
            {codeSent ? '번호 다시받기' : '인증 번호받기'}
          </GetCodeBtn>
        </BtnWrap>
      </InputWrap>
      {errorMessage}
      <InputWrap error={error}>
        <InputLabel htmlFor="verificationCodeInput">인증번호</InputLabel>
        <BtnWrap>
          {verificationCodeInput}
          <VerifyBtn active={phoneVerified} type="button" onClick={() => handleVerityBtnClick()}>
            {phoneVerified ? '인증완료' : '인증하기'}
          </VerifyBtn>
        </BtnWrap>
      </InputWrap>
      {error && <ErrorMessage>인증 번호를 다시 확인해주세요</ErrorMessage>}
    </>
  );
}

export default PhoneVerification;

const InputWrap = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 8px;
    `}
`;

const BtnWrap = styled.div`
  display: flex;
`;

const VerifyBtnActive = css`
  background-color: var(--green-200);
  border: 1px solid var(--green-200);
  color: var(--white);
`;

const VerifyBtn = styled.button<{ active?: boolean }>`
  color: var(--green-350);
  background-color: var(--green-80);
  border: 1px solid var(--green-200);
  margin-left: 8px;
  width: 120px;
  font-family: NotoSansRegular;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  ${({ active }) => active && VerifyBtnActive}
`;

const GetCodeBtn = styled(VerifyBtn)``;

const AlertMessage = styled.div`
  color: var(--green-350);
  background-color: var(--green-80);
  font-family: NotoSansRegular;
  padding: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 8px 0;
`;

const SpinnerWrap = styled.div`
  ${flexCenter}
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
