import { Switch, useRouteMatch } from 'react-router-dom';

import { DoctorRoute } from 'components/atoms/DoctorRoute';
import { VisitMobile } from 'components/pages/VisitMobile';

function DoctorRoomScreen() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <DoctorRoute exact component={VisitMobile} path={path} />
    </Switch>
  );
}

export default DoctorRoomScreen;
