import { useEffect } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';

import { useAuth } from 'libs/AuthContext';
import { flexCenter, fitImg } from 'styles/mixins';

interface MobileHeaderProps {
  onHamburgerClick: React.MouseEventHandler<HTMLButtonElement>;
}

function MobileHeader({ onHamburgerClick }: MobileHeaderProps) {
  const { logoutSuccess } = useAuth();

  useEffect(() => {
    if (logoutSuccess) {
      window.location.reload();
    }
  }, [logoutSuccess]);

  return (
    <Header>
      <Logo />
      <Hamburger onClick={(e: React.MouseEvent<HTMLButtonElement>) => onHamburgerClick(e)}>
        <img alt="list" src="/icons/ic-hamburger.svg" />
      </Hamburger>
    </Header>
  );
}

export default MobileHeader;

const Header = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 25px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.071);
  z-index: 1;
`;

const Logo = styled.div`
  ${flexCenter};
  ${fitImg};
  width: 50%;
  height: 50%;
  background-image: url(/icons/ic-logo-small.png);
  background-repeat: no-repeat;
  background-size: contain;
`;

const Hamburger = styled(Button)`
  width: 42px;
  height: 42px;
`;
