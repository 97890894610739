import { useMemo, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ScreenOverlay } from 'components/molecules/ScreenOverlay';
import { MediaPlayer } from 'components/atoms/MediaPlayer';

import { useMobile, useVolumeControl } from 'hooks';
import { useAgora } from 'libs/AgoraContext';
import { useAuth } from 'libs/AuthContext';
import { useVisit } from 'libs/VisitContext';

import * as mixins from 'styles/mixins';
import { font } from 'styles/fonts';
import { flex } from 'styles/flex';

type ParticipantViewTypes = {
  count: number;
  isLocal?: boolean;
  isMobile: boolean;
};

function MediaContent() {
  const isMobile = useMobile();
  const { audioMuted, videoMuted, localAudioTrack, localVideoTrack, remoteUsers } = useAgora();
  const { foreignerAppointment, isDoctor, isForeigner, isGuardian, user: localUser } = useAuth();
  const { departmentName: visitDeptName, doctorName: visitDoctorName, getDoctorName } = useVisit();

  const remoteVideoRef = useRef<Array<string>>([]);
  useVolumeControl(remoteUsers, remoteVideoRef);

  const [localUserName, setLocalUserName] = useState('');
  const [remoteUserName, setRemoteUserName] = useState('');

  const { rtcUsers, count } = useMemo(
    () => ({
      rtcUsers: remoteUsers.filter((user) => String(user.uid) !== '111111'),
      count: remoteUsers.length,
    }),
    [remoteUsers],
  );

  const { departmentName: foreignerDeptName, doctorName: foreignerDoctorName } = foreignerAppointment;

  const getRemoteUserName = () => {
    switch (true) {
      case isDoctor:
        return '환자';
      case isForeigner:
        return `${foreignerDeptName} ${foreignerDoctorName}`;
      case isGuardian:
        return `${visitDeptName} ${visitDoctorName}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isDoctor || !rtcUsers.length) return;
    const doctorId = rtcUsers[0].uid;
    getDoctorName(`${doctorId}`);
  }, [isDoctor, rtcUsers]);

  useEffect(() => {
    setRemoteUserName(getRemoteUserName());
  }, [visitDeptName]);

  useEffect(() => {
    setLocalUserName(isDoctor || isForeigner ? localUser.fullname : '보호자');
  }, []);

  return (
    <Wrapper count={count} isMobile={isMobile}>
      <ParticipantView isLocal count={count} isMobile={isMobile}>
        <ScreenOverlay isActive={videoMuted} />
        <MediaPlayer audioTrack={localAudioTrack} id="myVideo" videoTrack={localVideoTrack} />
        {(localUserName || audioMuted) && (
          <ParticipantInfo>
            {localUserName}
            {localAudioTrack.muted && <ParticipantMutedIcon />}
          </ParticipantInfo>
        )}
      </ParticipantView>

      {rtcUsers.map((user) => {
        const { uid, audioTrack, videoTrack, hasAudio, hasVideo } = user;
        return (
          <ParticipantView key={uid} count={count} isLocal={false} isMobile={isMobile}>
            <MediaPlayer audioTrack={audioTrack} id={`${uid}`} videoTrack={videoTrack} />
            <ScreenOverlay isActive={!hasVideo} />
            {(remoteUserName.trim() || !hasAudio) && (
              <ParticipantInfo>
                {remoteUserName}
                {!hasAudio && <ParticipantMutedIcon />}
              </ParticipantInfo>
            )}
          </ParticipantView>
        );
      })}
    </Wrapper>
  );
}
export default MediaContent;

const Wrapper = styled.div<{ isMobile: boolean; count: number }>`
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 360px));
  grid-template-rows: repeat(6, minmax(auto, 268px));
  grid-row-gap: 2%;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ParticipantView = styled.div<ParticipantViewTypes>`
  position: relative;
  width: 100%;
  max-height: 268px;

  :last-child {
    margin-bottom: 0;
  }

  video {
    ${mixins.fitVideo}
    border-radius: 10px;

    ${({ isLocal }) => `
      transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -webkit-transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -moz-transform:${isLocal ? 'rotateY(180deg)' : ''};
  `}
  }
`;

const ParticipantInfo = styled.div`
  ${flex('center', 'center')}
  position: absolute;
  left: 10px;
  bottom: 10px;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 12px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  ${font('NotoSansRegular', 15, 400, 22)};
  color: var(--white);
  z-index: 2;
`;

const ParticipantMutedIcon = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
`;
