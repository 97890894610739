import { useEffect, useRef } from 'react';
import { useCall } from 'libs/CallContext';
import useMobile from 'hooks/useMobile';
import { getParameter, RtmClient } from 'utils';
import { useAuth } from 'libs/AuthContext';

type RTMMessage = {
  text: string;
  messageType: string;
};

const useRTM = () => {
  const { agoraToken, isDoctor, user } = useAuth();

  const { joinedUsers, isSharing, updateJoinedUser, deleteJoinedUser, updateSharing, setJoinMessage } = useCall();
  const isMobile = useMobile();

  const rtmRef = useRef(new RtmClient());
  const channelRef = useRef('');

  const leaveRTM = () => {
    if (isMobile) return;
    const rtm = rtmRef.current;
    rtm.logout();
  };

  const handlePatientJoin = async (allow: boolean) => {
    if (isMobile) return;
    const rtm = rtmRef.current;
    const recentJoinedUser = joinedUsers.length - 1;
    setJoinMessage('');

    if (allow) {
      await rtm.sendPeerMessage(`${JSON.stringify({ join: true, isSharing })}`, joinedUsers[recentJoinedUser]);
      // setAllowJoined(true);
    } else {
      await rtm.sendPeerMessage(`${JSON.stringify({ join: false, isSharing })}`, joinedUsers[recentJoinedUser]);
      // setAllowJoined(false);
    }
  };

  useEffect(() => {
    if (isMobile) return;
    const rtm = rtmRef.current;

    if (isSharing) {
      rtm.sendChannelMessage(`${JSON.stringify({ isSharing: true })}`, `${channelRef.current}`);
    } else {
      rtm.sendChannelMessage(`${JSON.stringify({ isSharing: false })}`, `${channelRef.current}`);
    }

    // eslint-disable-next-line
  }, [isSharing]);

  const joinRTM = async () => {
    if (isMobile) return;
    const rtm = rtmRef.current;
    const rtmChannel = getParameter('roomId');
    channelRef.current = rtmChannel;
    rtm.init();
    try {
      await rtm.login(`${user.uid}`, agoraToken);
    } catch (e) {
      console.error('rtm login error: ', e);
    }

    await rtm.joinChannel(`${rtmChannel}`);

    if (isDoctor) {
      rtm.channels[rtmChannel].channel.on('MemberJoined', (args) => {
        updateJoinedUser(args);
        rtm.emit('MemberJoined', { rtmChannel, args });
      });

      rtm.channels[rtmChannel].channel.on('MemberLeft', (args) => {
        deleteJoinedUser(args);
        rtm.emit('MemberLeft', { rtmChannel, args });
      });

      rtm.on('MessageFromPeer', (message: RTMMessage) => {
        setJoinMessage(message.text);
      });
    } else if (!isDoctor) {
      // const sendUser = user.username;
      // rtm.sendPeerMessage(sendUser, hostIdRef.current);

      rtm.on('MessageFromPeer', (message: RTMMessage) => {
        const parseMessage: { join: boolean; isSharing: boolean } = JSON.parse(message.text);

        updateSharing(parseMessage.isSharing);
        if (parseMessage.join) {
          // setAllowJoined(true);
          // updateSharing(parseMessage.isSharing);
        } else {
          // alert("입장이 거절되었습니다.");
          // setWaitingPatient(false);
          // leaveRtm();
        }
      });
    }

    rtm.channels[rtmChannel].channel.on('ChannelMessage', (args) => {
      const sharingMessage: { isSharing: boolean } = JSON.parse(args.text);
      updateSharing(sharingMessage.isSharing);

      rtm.emit('ChannelMessage', { rtmChannel, args });
    });

    rtm.channels[rtmChannel].channel.on('AttributesUpdated', (args) => {
      rtm.emit('AttributesUpdated', { rtmChannel, args });
    });
  };

  return { handlePatientJoin, joinRTM, leaveRTM };
};

export default useRTM;
