import styled from 'styled-components';

import { MobileSideMenuProfile } from 'components/molecules/MobileSideMenuProfile';
import { MobileSideMenuList } from 'components/molecules/MobileSideMenuList';

import { flexCenter, fitImg } from 'styles/mixins';
import { Button } from 'components/atoms/Button';

interface MobileSideMenuProps {
  onClose: () => void;
}

function MobileSideMenu({ onClose }: MobileSideMenuProps) {
  return (
    <SideMenuWrapper>
      <Dummy />
      <SideMenu>
        <SideHeader>
          <CloseBtn onClick={onClose}>
            <img alt="close" src="/icons/ic-close.svg" />
          </CloseBtn>
        </SideHeader>
        <MobileSideMenuProfile />
        <Divider />
        <MobileSideMenuList onClose={onClose} />
      </SideMenu>
    </SideMenuWrapper>
  );
}

export default MobileSideMenu;

const SideMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const Dummy = styled.div`
  flex: 1;
`;

const SideMenu = styled.div`
  width: 285px;
  height: 100%;
  padding: 24px 29px 0 49.7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  opacity: 1;

  animation: slideIn 0.7s;
  @keyframes slideIn {
    0% {
      padding: 0;
      padding-top: 24px;
      width: 0;
    }
    100% {
      width: 285px;
    }
  }
`;

const SideHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  margin-bottom: 23px;
`;

const CloseBtn = styled(Button)`
  ${flexCenter};
  ${fitImg};
  width: 42px;
  height: 42px;
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 48px;
  background-color: #ededed;
`;
