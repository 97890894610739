import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { flexCenter } from 'styles/mixins';
import { RoundList, useVisit } from 'libs/VisitContext';

type WaitingTime = {
  patientName: string;
  time: number;
};

function VisitTableRow() {
  const { category, completeRoundList, waitingRoundList } = useVisit();
  const [displayArray, setDisplayArray] = useState<RoundList[]>(waitingRoundList);
  const [waitingTimeList, setWaitingTimeList] = useState<WaitingTime[]>([]);

  const getWaitingTime = (patientName: string) => {
    const waitingTime = waitingTimeList.find((waiting) => waiting.patientName === patientName);
    if (!waitingTime) return;
    const timeDiff = waitingTime.time;

    const diffMinutes = Math.floor(timeDiff / 60);
    const diffSeconds = timeDiff % 60;
    const timeFormat = `${diffMinutes}:${diffSeconds < 10 ? `0${diffSeconds}` : diffSeconds}`;
    return timeFormat;
  };

  const formatTime = () => {
    const now = dayjs();
    const timeList = displayArray.map((visit) => ({
      patientName: visit.patientName,
      time: now.diff(dayjs(visit.updatedAt), 'seconds'),
    }));

    setWaitingTimeList(timeList);
  };

  const handleEnterBtnClick = (roundId: number) => {
    window.location.replace(
      `group-call/?roomId=${
        process.env.REACT_APP_AGORA_PREFIX
      }_round_${roundId}&referrer=${window.location.pathname.substring(1)}`,
    );
  };

  useEffect(() => {
    if (category === 'waiting') {
      setDisplayArray(waitingRoundList);

      return;
    }
    setDisplayArray(completeRoundList);
  }, [category, waitingRoundList]);

  useEffect(() => {
    if (!displayArray.length) return;
    const waitingTimeInterval = setInterval(() => {
      formatTime();
    }, 1000);

    return () => clearInterval(waitingTimeInterval);
  }, [displayArray]);

  return (
    <>
      {displayArray.map((round, idx) => {
        const { hasGuardian, patientAge, patientGender, patientName, patientWard } = round;
        return (
          <TableRow key={`round-${idx}`}>
            <div>{category === 'waiting' && <>{hasGuardian && getWaitingTime(patientName)}</>}</div>

            <div>
              {`${patientName} (${patientAge}/${patientGender})
              ${patientWard ?? ''}`}
            </div>
            <div>
              {category === 'waiting' && (
                <EnterBtn disabled={!hasGuardian} onClick={() => handleEnterBtnClick(round.id)}>
                  면회실 입장
                </EnterBtn>
              )}
            </div>
          </TableRow>
        );
      })}
    </>
  );
}

export default VisitTableRow;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 13px 0;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #fff;

  div {
    ${flexCenter};
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-line;
    color: #3e3e3e;
    text-align: center;
  }

  div:nth-child(1) {
    flex: 1;
    border-right: 1px solid #d1d1d1;
  }

  div:nth-child(2) {
    flex: 1.23;
    border-right: 1px solid #d1d1d1;
  }

  div:nth-child(3) {
    flex: 1.39;
  }
`;

const Disabled = css`
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  color: var(--gray-600);
`;

const Enabled = css`
  background-color: #d9ecff;
  border: 1px solid #7cbdff;
  color: #3a72aa;
  cursor: pointer;
`;

const EnterBtn = styled.button<{ disabled: boolean }>`
  height: 30px;
  min-height: 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;

  ${(props) => (props.disabled ? Disabled : Enabled)};
`;
