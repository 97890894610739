import styled, { css } from 'styled-components';

import {
  CallButton,
  MenuButton,
  EndButton,
  MuteButton,
  StartShareButton,
  StopShareButton,
  StartVideoButton,
  StopVideoButton,
  UnmuteButton,
} from 'components/atoms/CallButtons';
import { Dropdown } from 'components/organisms/Dropdown';

import { useMobile } from 'hooks';
import { useAgora } from 'libs/AgoraContext';
import { useCall } from 'libs/CallContext';
import { flexCenter } from 'styles/mixins';

type UtilityButtonsProps = {
  showSelectMenu?: boolean;
  onEndBtnClick: Function;
  onMenuBtnClick?: Function;
  onShareBtnClick: Function;
};

function UtilityButtons({ showSelectMenu, onEndBtnClick, onMenuBtnClick, onShareBtnClick }: UtilityButtonsProps) {
  const isMobile = useMobile();
  const {
    audioMuted,
    videoMuted,
    virtualBackgroundMode,
    removeBackground,
    setBackgroundBlurring,
    setBackgroundImage,
    toggleAudio: handleAudioBtnClick,
    toggleVideo: handleVideoBtnClick,
  } = useAgora();
  const { isSharing, getFlippedBackgroundImage } = useCall();

  const handleBackgroundMode = (e: React.MouseEvent<HTMLUListElement>) => {
    const element = e.target as HTMLLIElement;
    const option = element.innerText;

    switch (option) {
      case '배경효과 제거':
        onMenuBtnClick();
        removeBackground();
        break;
      case '배경 흐리게':
        onMenuBtnClick();
        setBackgroundBlurring();
        break;
      case '배경 이미지 선택':
        break;
      default:
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    try {
      const filePath = await getFlippedBackgroundImage(formData);
      setBackgroundImage(filePath);
    } catch (error) {
      alert('이미지 업로드 실패. 다시 한 번 시도해주세요');
      console.error('fail to upload image: ', error);
    }
  };

  const handleImageMenu = (e: React.ChangeEvent<HTMLInputElement>) => {
    onMenuBtnClick();
    handleFileChange(e);
  };

  return (
    <Wrapper>
      <BtnWrap>
        {isSharing ? (
          <>
            <StopShareButton onClick={() => onShareBtnClick()} />
            <BtnText>화면공유 중지</BtnText>
          </>
        ) : (
          <>
            <StartShareButton onClick={() => onShareBtnClick()} />
            <BtnText>화면공유</BtnText>
          </>
        )}
      </BtnWrap>
      {audioMuted ? (
        <BtnWrap>
          <UnmuteButton onClick={() => handleAudioBtnClick()} />
          <BtnText>음소거 해제</BtnText>
        </BtnWrap>
      ) : (
        <BtnWrap>
          <MuteButton audioMuted={audioMuted} onClick={() => handleAudioBtnClick()} />
          <BtnText>음소거</BtnText>
        </BtnWrap>
      )}
      {videoMuted ? (
        <BtnWrap>
          <StartVideoButton onClick={() => handleVideoBtnClick()} />
          <BtnText>비디오 시작</BtnText>
        </BtnWrap>
      ) : (
        <BtnWrap>
          <StopVideoButton onClick={() => handleVideoBtnClick()} />
          <BtnText>비디오 중지</BtnText>
        </BtnWrap>
      )}
      <BtnWrap>
        <EndButton onClick={() => onEndBtnClick()} />
        <BtnText>연결 종료</BtnText>
      </BtnWrap>
      {!isMobile ? (
        <BtnWrap>
          <DropdownWrap mode={virtualBackgroundMode}>
            <Dropdown
              label="backgroundImage"
              labelTriggerList={['배경 이미지 선택']}
              open={showSelectMenu}
              optionIcon={<OptionIcon />}
              optionList={['배경효과 제거', '배경 흐리게', '배경 이미지 선택']}
              trigger={<MenuButton onClick={() => onMenuBtnClick()} />}
              onClick={(e: React.MouseEvent<HTMLUListElement>) => handleBackgroundMode(e)}
            />
            <FileInput
              accept="image/png, image/jpeg, image/jpg"
              id="backgroundImage"
              type="file"
              onChange={(e) => handleImageMenu(e)}
            />
          </DropdownWrap>
          <BtnText>더보기</BtnText>
        </BtnWrap>
      ) : null}
    </Wrapper>
  );
}

export default UtilityButtons;

const Wrapper = styled.div`
  font-size: 15px;
  ${flexCenter}

  ${CallButton} {
    width: 74px;
    height: 74px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;

    ${CallButton} {
      width: 66px;
      height: 66px;
    }
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    font-size: 13px;

    ${CallButton} {
      width: 46px;
      height: 46px;
    }
  }
`;

const OptionIcon = styled.div`
  width: 16px;
  height: 16px;
  border-color: transparent;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-main-arrow-off.svg);
`;

const Active = css`
  background-color: #d9ecff;
  color: #036ad1;

  ${OptionIcon} {
    background-image: url(/icons/ic-main-arrow-on.svg);
  }
`;

const DropdownWrap = styled.div<{ mode: string }>`
  position: relative;

  ul {
    top: -60px;
    right: -240px;
    width: 220px;
    margin-top: 0;
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 13.8px 12px 20px;
    color: rgb(119, 119, 119);

    &:hover {
      ${Active}
    }

    &#drop-1 {
      ${({ mode }) => mode === 'blur' && Active}
    }

    &#drop-2 {
      ${({ mode }) => mode === 'img' && Active}
    }
  }
`;

const FileInput = styled.input`
  display: none;
`;

const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 87px;
  margin: 0 12px;

  @media only screen and (max-width: 768px) {
    width: 82px;
    margin: 0 10px;
  }

  @media only screen and (max-width: 420px) {
    width: 72px;
    margin: 0;
  }
`;

const BtnText = styled.div`
  margin-top: 10px;
  color: var(--white);
`;
