import styled from 'styled-components';

import { FindUsernameForm } from 'components/organisms/FindUsernameForm';
import { Screen } from 'components/templates/Screen';

function FindUsernamePage() {
  return (
    <Wrapper>
      <Content>
        <FormWrapper>
          <FindUsernameForm />
        </FormWrapper>
      </Content>
    </Wrapper>
  );
}

export default FindUsernamePage;

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  padding: 51px 24px 56px;
  color: var(--blue-100);
  background-color: var(--white);
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
