import styled from 'styled-components';
import * as mixins from 'styles/mixins';
import { media } from 'utils';

import { LoginForm } from 'components/organisms/LoginForm';
import { Screen } from 'components/templates/Screen';

function LoginPage() {
  return (
    <Wrapper>
      <Content>
        <Logo alt="hospital-logo" src="/icons/ic-catholic.jpeg" />
        {/* <Logo size="big" /> */}
        {/* <Explanation>은평성모병원 비대면 면회 서비스</Explanation> */}
        <LoginForm />
      </Content>
    </Wrapper>
  );
}

export default LoginPage;

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  overflow: auto;

  ${media.main} {
    background-color: var(--white);
  }
`;

const Content = styled.div`
  ${mixins.flexCenter};
  flex-direction: column;
  height: 100%;

  > div {
    margin-bottom: 40px;
  }

  ${media.main} {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  margin-bottom: 21.1px;
  width: 150px;
  height: 120px;
`;

// const Explanation = styled.h1`
//   margin: 0;
//   margin-bottom: 45.76px;
//   color: var(--gray-400);
//   font-size: 17px;
// `;
