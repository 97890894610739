import styled from 'styled-components';

const StyledLogoMid = styled.div`
  width: 22.9px;
  height: 26.3px;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: var(--white);
  background-image: url(/icons/voidoc-logo-small.svg);
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledLogoBig = styled.div`
  width: 170px;
  height: 34.5px;
  display: block;
  background: url(/icons/voidoc-logo-big.svg);
  background-repeat: no-repeat;
  margin-bottom: 24px;
`;

interface LogoProps {
  size: 'mid' | 'big';
}

function Logo({ size }: LogoProps) {
  switch (size) {
    case 'big':
      return <StyledLogoBig />;
    case 'mid':
    default:
      return <StyledLogoMid />;
  }
}

export default Logo;
