import { useRef, useMemo, useEffect, useState } from 'react';

// import { MobileAudience } from 'components/molecules/MobileAudience';
// import { MobileSpeaker } from 'components/molecules/MobileSpeaker';

import { useVolumeControl } from 'hooks';
import { useAgora } from 'libs/AgoraContext';
import { MobileFrontCameraView } from '../MobileFrontCameraView';
import { MobileRearCameraView } from '../MobileRearCameraView';

export default function MediaContent() {
  const { remoteUsers, remoteFacingMode, facingMode } = useAgora();

  const [screenOrientation, setScreenOrientation] = useState<'portrait' | 'landscape'>('portrait');

  const remoteVideoRef = useRef<Array<string>>([]);
  useVolumeControl(remoteUsers, remoteVideoRef);

  const rtcUsers = useMemo(() => remoteUsers.filter((user) => String(user.uid) !== '111111'), [remoteUsers]);

  const count = useMemo(() => rtcUsers.length + 1, [rtcUsers]);

  // const handleSpeaker = () => {
  //   const remoteSpeaker = rtcUsers.filter((user) => String(user.uid) === speaker);

  //   if (remoteSpeaker.length) {
  //     const { uid: remoteId, audioTrack, videoTrack, hasAudio, hasVideo } = remoteSpeaker[0];

  //     return (
  //       <MobileSpeaker
  //         isLocal
  //         audioMuted={!hasAudio}
  //         audioTrack={audioTrack}
  //         count={count}
  //         id={`${remoteId}`}
  //         videoMuted={!hasVideo}
  //         videoTrack={videoTrack}
  //       />
  //     );
  //   }

  //   return (
  //     <MobileSpeaker
  //       isLocal
  //       audioMuted={audioMuted}
  //       audioTrack={localAudioTrack}
  //       count={count}
  //       id="myVideo"
  //       videoMuted={videoMuted}
  //       videoTrack={localVideoTrack}
  //     />
  //   );
  // };

  // const handleAudience = () => (
  //   <>
  //     <MobileAudience
  //       isLocal
  //       audioTrack={localAudioTrack}
  //       hasAudio={!audioMuted}
  //       hasVideo={!videoMuted}
  //       speaker={speaker}
  //       uid={`${uid}`}
  //       videoTrack={localVideoTrack}
  //     />
  //     {rtcUsers.map((user) => {
  //       const { uid: userId, audioTrack, videoTrack, hasAudio, hasVideo } = user;

  //       return (
  //         <Fragment key={userId}>
  //           <MobileAudience
  //             audioTrack={audioTrack}
  //             hasAudio={hasAudio}
  //             hasVideo={hasVideo}
  //             speaker={speaker}
  //             uid={`${userId}`}
  //             videoTrack={videoTrack}
  //           />
  //         </Fragment>
  //       );
  //     })}
  //   </>
  // );

  useEffect(() => {
    const handleScreenOrientation = () => {
      if (window.innerHeight < window.innerWidth) {
        return setScreenOrientation('landscape');
      }

      return setScreenOrientation('portrait');
    };

    window.addEventListener('resize', handleScreenOrientation);

    return () => {
      window.removeEventListener('resize', handleScreenOrientation);
    };
  }, [screenOrientation]);

  return (
    <>
      {screenOrientation === 'portrait' && (facingMode === 'environment' || remoteFacingMode === 'environment') ? (
        <MobileRearCameraView rtcUsers={rtcUsers} />
      ) : (
        <MobileFrontCameraView count={count} rtcUsers={rtcUsers} />
      )}
    </>
  );
}
