import styled from 'styled-components';

import { MediaPlayer } from 'components/atoms/MediaPlayer';
import { ScreenOverlay } from 'components/molecules/ScreenOverlay';

import { useVisit } from 'libs/VisitContext';
import { useAuth } from 'libs/AuthContext';
import { useAgora } from 'libs/AgoraContext';

import { normal } from 'styles/fonts';
import * as mixins from 'styles/mixins';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

interface FrontScreenPropsType {
  rtcUsers?: IAgoraRTCRemoteUser[];
}
function FrontScreen({ rtcUsers }: FrontScreenPropsType) {
  const { audioMuted, localAudioTrack, localVideoTrack, videoMuted } = useAgora();
  const { departmentName, doctorName } = useVisit();
  const { isDoctor, user: doctor } = useAuth();

  if (!rtcUsers) {
    return (
      <FrontScreenView isLocal>
        <ScreenOverlay hasBorder={false} isActive={videoMuted} />
        {!videoMuted && <MediaPlayer audioTrack={localAudioTrack} id="frontVideo" videoTrack={localVideoTrack} />}
        <FrontScreenInfo>
          {isDoctor ? `${doctor.fullname}` : '보호자'}
          {audioMuted && <MutedIcon />}
        </FrontScreenInfo>
      </FrontScreenView>
    );
  }

  const [{ audioTrack, videoTrack, hasAudio, hasVideo }] = rtcUsers;
  return (
    <FrontScreenView>
      {!rtcUsers.length ? (
        <>
          <ScreenOverlay hasBorder={false} isActive={false} />
          <FrontScreenInfo>{isDoctor ? '보호자' : `${departmentName} ${doctorName}`}</FrontScreenInfo>
        </>
      ) : (
        <>
          <ScreenOverlay hasBorder={false} isActive={!hasVideo} />
          {hasVideo && <MediaPlayer audioTrack={audioTrack} id="frontVideo" videoTrack={videoTrack} />}
          <FrontScreenInfo>
            {isDoctor ? '보호자' : `${departmentName} ${doctorName}`}
            {!hasAudio && <MutedIcon />}
          </FrontScreenInfo>
        </>
      )}
    </FrontScreenView>
  );
}

export default FrontScreen;

interface ScreenViewTypes {
  isLocal?: boolean;
}

const FrontScreenView = styled.div<ScreenViewTypes>`
  position: absolute;
  width: 30%;
  height: 24%;
  right: 20px;
  bottom: 40px;
  z-index: 1;

  video {
    ${mixins.fitVideo}
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);

    ${({ isLocal }) => `
      transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -webkit-transform: ${isLocal ? 'rotateY(180deg)' : ''};
      -moz-transform:${isLocal ? 'rotateY(180deg)' : ''};
  `}
  }

  div:first-child {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);

    div {
      width: 70%;
      height: 70%;
      box-shadow: none;
    }
  }
`;

const FrontScreenInfo = styled.div`
  ${mixins.flexCenter}
  ${normal};
  position: absolute;
  right: 16px;
  left: 16px;
  bottom: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 13px;
  z-index: 5;
  background-color: #1f1f1f;
  opacity: 0.5;
  color: var(--white);
  word-break: keep-all;
  text-align: center;
`;

const MutedIcon = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-callkit-audio-off-white.svg);
`;
